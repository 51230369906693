import * as Environment from "../base/Environment.js";

import {RenderContext, AnimatableSprite, SolidSprite} from "../base/Display2D.js";
import {VideoSprite} from "../base/Display2DExtensions.js";

import {Site, SiteSection} from "./Site.js";

//
// VideoOverlay extends SiteSection
//

export const VideoOverlay = function (context) {
	SiteSection.apply (this, arguments);
	
	VideoOverlay.sharedInstance = this;
	
};

window.VideoOverlay = VideoOverlay;

VideoOverlay.prototype = Object.create (SiteSection.prototype);

VideoOverlay.prototype.takeElement = function (element) {
	SiteSection.prototype.takeElement.apply (this, arguments);
	
	element.addEventListener ("click", function (event) {
		this.open ();
		
	}.bind (this));
	
	if (Environment.FAST_PASS) {
		/*
		window.setTimeout (function () {
			this.open ();
			
		}.bind (this), 250);
		*/
		
	}
	
};

VideoOverlay.prototype.open = function () {
	Site.sharedInstance.openVideoLayerFromSourceElement (this.element);
	
};

//
// OverlayVideoPlayer extends AnimatableSprite
//

export const OverlayVideoPlayer = function (context) {
	AnimatableSprite.apply (this, arguments);
	
	var element = this.element;
	element.classList.add ("overlay-video-player");
	
	var keyDown = this.keyDown = this.keyDown.bind (this);
	
};

window.OverlayVideoPlayer = OverlayVideoPlayer;

OverlayVideoPlayer.prototype = Object.create (AnimatableSprite.prototype);

window.onYouTubePlayerAPIReady = function () {
	OverlayVideoPlayer.isYouTubeIframeAPIReady = true;
	
};

OverlayVideoPlayer.prototype.startVideoWithSourceElement = function (sourceElement) {
	if (!OverlayVideoPlayer.isYouTubeIframeAPIReady)
		return;
	
	this.sourceElement = sourceElement;
	
	var background = this.background;
	if (background)
		this.removeChild (background);
	
	background = this.background = this.attachSprite (SolidSprite);
	background.element.classList.add ("overlay-video-player__background");
	
	var closeButton = this.closeButton = background.attachSprite (SolidSprite);
	closeButton.element.classList.add ("overlay-video-player__close-button");
	closeButton.addListener ("click", this.close, this);
	
	var vimeoVideoId = sourceElement.getAttribute ("data-vimeo-video-id");
	var youTubeVideoId =
		sourceElement.getAttribute ("data-you-tube-video-id") ||
		sourceElement.getAttribute ("data-video-id");
	
	if (vimeoVideoId) {
		var video = this.video = background.attachSprite (SolidSprite);
		video.element.classList.add ("video-player-wrapper");
		
		var playerElement = document.createElement ("div");
		video.element.appendChild (playerElement);
		
		var player = this.player = document.createElement ("iframe");
		
		player.classList.add ("vimeo-video-player");
		
		player.setAttribute ("frameborder", "0");
		player.setAttribute ("allow", "autoplay; fullscreen");
		player.setAttribute ("allowfullscreen", "");
		
		player.setAttribute ("src",
			"https://player.vimeo.com/video/" + vimeoVideoId + "?autoplay=1&loop=1&portrait=0"
			
		);
		
		playerElement.appendChild (player);
		
	} else if (youTubeVideoId) {
		var video = this.video = background.attachSprite (SolidSprite);
		video.element.classList.add ("video-player-wrapper");
		
		var playerElement = document.createElement ("div");
		video.element.appendChild (playerElement);
		
		const startSeconds = sourceElement.getAttribute ("data-video-start-seconds");
		
		var player = this.player = new YT.Player (
			playerElement, {
				videoId: youTubeVideoId,
				
				width: "100%",
				height: "100%",
				
				playerVars: {
					autoplay: 1,
					start: parseFloat (startSeconds) || 0,
					
					controls: 0,
					info: 0,
					rel: 0,
					wmode: "transparent",
					playsinline: 1,
					showinfo: 0
					
				},
				events: {
					/* onStateChange: goToVS */
					onReady: function () {
						if (Environment.FAST_PASS)
							player.mute ();
						
					}
					
				}
				
			}
			
		);
		
	} else {
		var videoNames = new Array ();
		videoNames.push (
			sourceElement.getAttribute ("data-video-path-mobile"),
			sourceElement.getAttribute ("data-video-path-desktop")
			
		);
		
		if (!(videoNames [0] || videoNames [1])) {
			videoNames [0] = sourceElement.getAttribute ("data-video-path");
			if (!videoNames [0])
				return;
			
		}
	 	
		function pushVideoSize (attributeName) {
			var videoSize = sourceElement.getAttribute (attributeName);
			if (videoSize)
				videoSizes.push (parseTuple (videoSize));
			else
				videoSizes.push (undefined);
			
		}
		
		var videoSizes = new Array ();
		
		pushVideoSize ("data-video-size-mobile");
		pushVideoSize ("data-video-size-desktop");
		pushVideoSize ("data-video-size");
		
		var screenWidth = window.screen.width * (window.devicePixelRatio || 1);
		var minSizeIndex = window.screen.width < 1024 ? 0 : 1;
		
		videoSize = videoSizes [minSizeIndex] || videoSizes [1 - minSizeIndex];
		var videoName =
			(videoNames [minSizeIndex] || videoNames [1 - minSizeIndex]).replace (/\.mp4$/g, "");
		
	 	var video = this.video = new VideoSprite (this.context, videoSize);
	 	video.element.classList.add ("video-player-wrapper");
	 	video.setPosition (-1, 0);
	 	background.addChild (video);
	 	
		video.playsInline = true;
		video.forceUnmute = true;
		
		video.texture.load ("");
		video.texture.style.objectFit = "contain";
		
		window.setTimeout (function () {
			video.loadVideo (
				videoName,
				true || IS_TOUCH_DEVICE ? undefined : videoName + ".jpg"
				
			);
			video.fadeIn (.2, 30);
			
		}, 350);
		
		video.setAlpha (.01);
		
	}
	
	var viewSize = this.getStage ().size;
	video.setSize (viewSize);
	
	this.startVideo ();
	
};

OverlayVideoPlayer.prototype.startVideo = function () {
	OverlayVideoPlayer.isPlayingMovie = true;
	
	this.startAnimation ("Zoom", {direction: 1, rate: .03});
	
	document.body.addEventListener ("keydown", this.keyDown);
	
};

OverlayVideoPlayer.prototype.close = function () {
	OverlayVideoPlayer.isPlayingMovie = false;
	
	var video = this.video;
	
	if (video instanceof VideoSprite) {
		video.fadeOut (2);
		
	} else {
		video.setSize (this.getStage ().size);
		
	}
	
	this.startAnimation ("Zoom", {direction: 0, rate: .04});
	
	document.body.removeEventListener ("keydown", this.keyDown);
	
};

OverlayVideoPlayer.prototype.animateZoom = function () {
	var state = this.updatedState ("Zoom");
	var t = state.phase;
	if (state.direction) {
		t = 1 - t;
		t = 1 - t * t * t;
		
	} else {
		t = t * t * t;
		
	}
	
	t = .5 - Math.cos (t * Math.PI) / 2;
	
	this.drawZoomForT (t);
	
};

OverlayVideoPlayer.prototype.drawZoomForT = function (t) {
	var viewSize = RenderContext.getViewportSize ();
	var scrollOffset = RenderContext.getScrollOffset ();
	
	var margins = [0, 0];
	
	this.lastT = t;
	var t_ = 1 - t;
	
	var video = this.video;
	var background = this.background;
	
	if (!t) {
		this.setAlpha (0);
		
		var player = this.player;
		// player.destroy && player.destroy ();
		
		if (video.element.parentNode)
			video.element.parentNode.removeChild (video.element);
		
	} else if (t < 1) {
		var sourceElement = this.sourceElement;
		var sourceFrameBounds = sourceElement.getBoundingClientRect ();
		
		var selfBounds = (document.documentElement || document.body).getBoundingClientRect ();
		
		var rectLeft = t_ * sourceFrameBounds.left;
		var rectTop = t_ * sourceFrameBounds.top;
		var rectRight = rectLeft + t_ * (sourceFrameBounds.right - sourceFrameBounds.left) + t * viewSize [0];
		var rectBottom = t_ * (sourceFrameBounds.bottom) + t * viewSize [1];
		
		background.setPosition (
			Math.round (rectLeft), Math.round (rectTop)
			
		);
		
		background.setSize (
			Math.round (rectRight - background.position [0]),
			Math.round (rectBottom - background.position [1])
			
		);
		
		var videoScale = background.size [0] / viewSize [0];
		video.element.style.transform = "scale(" + videoScale + ")";
		
		this.setAlpha (t * 4);
		
	} else {
		background.setPosition (0, 0);
		background.setSize ("100%", "100vh");
		
		if (video instanceof VideoSprite)
			video.setSize ("calc(100vw + 2px)", "100vh");
		else
			video.setSize ("100%", "100vh");
		
		// video.element.style.transform = "";
		
		this.setAlpha (1);
		
	}
	
};

OverlayVideoPlayer.prototype.keyDown = function (event) {
	var keyCode = event.keyCode;
	switch (keyCode) {
		case 27:
			Navigation.sharedInstance.leaveMode ();
			break;
			
	}
	
};
