import * as Environment from "../base/Environment.js";

import {RenderContext, AnimatableSprite} from "../base/Display2D.js";

import {SiteSection} from "./SiteSection.js";

//
// VignetteSection extends SiteSection
//

export const VignetteSection = function (context) {
	SiteSection.apply (this, arguments);
	
};

window.VignetteSection = VignetteSection;

VignetteSection.prototype = Object.create (SiteSection.prototype);

VignetteSection.prototype.takeElement = function (element) {
	SiteSection.prototype.takeElement.apply (this, arguments);
	
	const picture = element.querySelector ("picture");
	// picture.classList.add ("lazyload--started");
	
	const image = this.image = element.querySelector ("img");
	image.style.visibility = "hidden";
	
	this.imageSize = [
		parseInt (image.getAttribute ("width")),
		parseInt (image.getAttribute ("height"))
		
	];
	
	let alignment = element.getAttribute ("data-alignment");
	if (alignment) {
		alignment = alignment.split (";");
		alignment = this.alignment = [
			parseFloat (alignment [0]),
			parseFloat (alignment [1])
			
		];
		
	}
	
	const updateImageSize = function () {
		this.imageSize = [
			image.naturalWidth,
			image.naturalHeight
			
		];
		
		const viewSize = this.viewSize;
		if (viewSize)
			this.setViewSize (viewSize);
		
	}.bind (this);
	
	if (image.src && image.complete) {
		this.isImageComplete = true;
		updateImageSize ();
		
	} else {
		image.addEventListener ("load", function (event) {
			this.isImageComplete = true;
			
			updateImageSize ();
			
			if (this.didPopIn)
				this.popUp ();
			
		}.bind (this));
		
	}
	
	const textureContainer = this.textureContainer = document.createElement ("div");
	
	const texture = this.texture = document.createElementNS ("http://www.w3.org/2000/svg", "svg");
	texture.setAttributeNS ("http://www.w3.org/2000/xmlns/", "xmlns:xlink", "http://www.w3.org/1999/xlink");
	
	const vignetteBackground = this.vignetteBackground = document.createElementNS ("http://www.w3.org/2000/svg", "path");
	vignetteBackground.setAttributeNS (null, "fill", "#404040");
	
	const vignetteMask = this.vignetteMask = document.createElementNS ("http://www.w3.org/2000/svg", "path");
	vignetteMask.setAttributeNS (null, "fill-rule", "evenodd");
	vignetteMask.setAttributeNS (null, "fill", "#f10d08");
	
	texture.appendChild (vignetteBackground);
	texture.appendChild (vignetteMask);
	
	textureContainer.appendChild (texture);
	element.appendChild (textureContainer);
	
	this.startAnimation ("PopIn", {direction: 1, rate: .02, delay: 15});
	
};

VignetteSection.prototype.alignment = [.5, .05];

VignetteSection.prototype.setViewSize = function (viewSize) {
	var element = this.element;
	viewSize = [
		element.offsetWidth,
		element.offsetHeight
		
	];
	
	SiteSection.prototype.setViewSize.call (this, viewSize);
	
	const imageSize = this.imageSize;
	const imageScale = Math.max (
		viewSize [0] / imageSize [0],
		viewSize [1] / imageSize [1]
		
	);
	const image = this.image;
	image.style.width = imageSize [0] * imageScale + "px";
	image.style.height = imageSize [1] * imageScale + "px";
	
	const alignment = this.alignment;
	image.style.transform = "translate(" +
		(viewSize [0] - imageSize [0] * imageScale) * alignment [0] + "px, " +
		(viewSize [1] - imageSize [1] * imageScale) * alignment [1] + "px)";
	
	if (this.popUpDrawPhase || 0 < 1)
		this.redrawVignette ();
	
	let scrollDownAnimation = this.getStage ().scrollDownAnimation;
	if (scrollDownAnimation) {
		scrollDownAnimation.element.style.bottom = "0";
		// scrollDownAnimation.element.style.top = Math.round (imageSize [1] * imageScale) + "px";
		
	}
	
};

VignetteSection.prototype.animatePopIn = function () {
	let state = this.states ["PopIn"];
	let delay = state.delay = Math.max (0, state.delay - this.context.animationTimer.framesDelta);
	if (delay)
		return;
	
	state = this.updatedState ("PopIn");
	let t = state.phase;
	
	this.popInDrawPhase = t;
	if (!this.popUpDrawPhase)
		this.redrawVignette ();
	
	if (t > .125 * .5) {
		this.didPopIn = true;
		
		if (this.isImageComplete)
			this.popUp ();
		
	}
	
};

VignetteSection.prototype.popUp = function () {
	if (this.popUpDrawPhase)
		return;
	
	const image = this.image;
	image.style.visibility = "";
	
	this.startAnimation ("PopUp", {direction: 1, rate: .00667});
	
};

VignetteSection.prototype.animatePopUp = function () {
	let state = this.updatedState ("PopUp");
	let t = state.phase;
	
	this.popUpDrawPhase = t;
	this.redrawVignette ();
	
};

VignetteSection.prototype.getSmallVignetteRadius = function () {
	const viewSize = this.viewSize;
	
	return Math.min (
		Math.min (viewSize [0], viewSize [1]) * .125 * .5,
		114 * .35
		
	);
	
};

VignetteSection.prototype.redrawVignette = function () {
	const popInDrawPhase = this.popInDrawPhase || 0;
	const popUpDrawPhase = this.popUpDrawPhase || 0;
	
	let smallVignetteRadius = this.getSmallVignetteRadius ();
	
	const vignetteBackground = this.vignetteBackground;
	const vignetteMask = this.vignetteMask;
	
	const texture = this.texture;
	if (!texture)
		return;
	
	const textureContainer = this.textureContainer;
	
	if (popUpDrawPhase < 1) {
		let radius;
		let opacity;
		
		const viewSize = this.viewSize;
		
		let t = popInDrawPhase;
		{
			const t_ = 1 - t;
			
			const ht = (t - .5) * 2;
			const amp = 1 - ht * ht * ht;
			
			radius = smallVignetteRadius * ((amp * 5 * t_ * t_ * t_ * t_) * t + (1 - t_ * t_ * t_));
			opacity = 1;
			
		} 
		
		t = popUpDrawPhase;
		{
			t = t * t * t * t;
			t = .5 - Math.cos (Math.PI * t) * .5;
			
			opacity = Math.max (0, Math.min (1, 1 - t * 4));
			const t_ = 1 - t;
			
			const largeVignetteRadius = Math.sqrt (
				viewSize [0] * viewSize [0] * .25 +
				viewSize [1] * viewSize [1] * .25
				
			);
			
			radius += largeVignetteRadius * t;
			
		}
		
		const fullRectPath = "M 0 0 L " + viewSize [0] + " 0 " +
			"L " + viewSize [0] + " " + viewSize [1] + " " +
			"L 0 " + viewSize [1];
		
		vignetteBackground.setAttribute (
			"d",
			fullRectPath
			
		);
		vignetteBackground.setAttributeNS (null, "opacity", opacity);
		
		vignetteMask.setAttribute (
			"d",
			fullRectPath + " " +
			"M " + (viewSize [0] * .5 - radius) + ", " + (viewSize [1] * .5) + " " +
				"a " + radius + ", " + radius + " 0 1,1 " + (radius * 2) + ", 0 " +
				"a " + radius + ", " + radius + " 0 1, 1 " + (-radius * 2) + ", 0"
			
		);
		
		if (popUpDrawPhase > .4 && !textureContainer.classList.contains ("shape"))
			textureContainer.classList.add ("shape");
		
	} else {
		if (!vignetteBackground.style.display) {
			vignetteBackground.style.display = "none";
			vignetteMask.style.display = "none";
			
			textureContainer.parentNode.removeChild (textureContainer);
			
		}
		
	}
	
};
