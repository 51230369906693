import {Dispatcher} from "../base/Base.js";

//
// ImageModel extends Dispatcher
//

export const ImageModel = function () {
	Dispatcher.apply (this, arguments);
	
};

ImageModel.list = new Array ();

ImageModel.prototype = Object.create (Dispatcher.prototype);

ImageModel.prototype.toString = function () {
	return "[ImageModel " + this.title + "]";
	
};

ImageModel.prototype.parseCollectionItem = function (element) {
	const id = this.id = element.getAttribute ("data-image-id");
	
	const title = this.title =
		element.querySelector (".portfolio-collection-item-decription-title").textContent.replace(/[\n\r]+|[\s]{2,}/g, " ").trim ();
	
	const picture = element.querySelector ("picture");
	
	const resolutions = this.resolutions = new Array ();
	
	if (picture) {
		const sources = picture.querySelectorAll ("source");
		for (let i = 0; i < sources.length; i++) {
			const source = sources [i];
			const sourceSet = source.getAttribute ("data-srcset").split (" ");
			
			resolutions.push ({
				path: sourceSet [0],
				width: parseInt (sourceSet [1])
				
			});
			
		}
		
		resolutions.sort (function (a, b) {
			return a.width > b.width ? -1 : 1;
			
		});
		
	}
	
	const image = element.querySelector ("img");
	this.src = image.getAttribute ("data-src");
	
	const pictureContainer = element.querySelector (".portfolio-collection-item-image");
	
	this.size = [
		parseInt (image.getAttribute ("width") || pictureContainer.getAttribute ("data-width")),
		parseInt (image.getAttribute ("height") || pictureContainer.getAttribute ("data-height"))
		
	];
	
	ImageModel.list.push (this);
	
};

ImageModel.prototype.getImageForResolution = function (width, fallbackExtension) {
	const resolutions = this.resolutions;
	
	if (resolutions.length) {
		for (let i = resolutions.length; i--;) {
			const resolution = resolutions [i];
			if (resolution.width >= width || !i)
				return resolution.path;
			
		}
		
	} else {
		return this.src.replace (".jpg", "-" + fallbackExtension + ".jpg");
		
	}
	
};

ImageModel.prototype.getThumbImagePath = function () {
	return this.getImageForResolution (480, "thumb");
	 
};

ImageModel.prototype.getPreviewImagePath = function () {
	return this.getImageForResolution (960, "preview");
	
};

ImageModel.prototype.isSelected = false;

ImageModel.prototype.setIsSelected = function (isSelected) {
	if (this.isSelected == isSelected)
		return;
	
	this.isSelected = isSelected;
	this.dispatchEvent ("changeIsSelected");
	
};
