import {AnimatableSprite} from "./Display2D.js";

//
// VideoSprite extends AnimatableSprite
//

export const VideoSprite = function (context, textureSize, playerVariant) {
	AnimatableSprite.apply (this, arguments);
	
	if (textureSize)
		this.textureSize = textureSize;
	else
		textureSize = this.textureSize = [1024, 576];
	
	var image = this.image = this.attachSprite (TexturedSprite);
	
	var videoElement = document.createElement ("video");
	
	var typeDescriptions = [
		{type: "video/mp4; codecs=\"avc1.42E01E\"",
			typeName: "mp4"} /* ,
		{type: "video/ogg; codecs=\"theora\"",
			typeName: "ogv"} */
		
	];
	
	var videoFileType;
	var videoType;
	for (var i = 0; i < typeDescriptions.length; i++) {
		var typeDescription = typeDescriptions [i];
		
		var playsType = videoElement.canPlayType (typeDescription.type);
		if (playsType == "probably" || playsType == "maybe") {
			videoType = typeDescription.type;
			videoFileType = typeDescription.typeName;
			
			break;
			
		}
		
	}
	
	if (videoFileType) {
		// trace ("HTML VIDEO SUPPORT for file type", videoFileType);
		
		this.texture = videoElement;
		this.USE_HTML_PLAYER = true;
		this.VIDEO_FILE_TYPE = videoFileType;
		this.VIDEO_TYPE = videoType;
		this.setUpVideoTexture ();
		
	}
	
	videoElement.load ("");
	
};

VideoSprite.prototype = Object.create (AnimatableSprite.prototype);

VideoSprite.prototype.setUpVideoTexture = function () {
	var textureSize = this.textureSize;
	
	this.isComplete = true;
	
	var texture = this.texture;
	texture.className = "sprite";
	
};

VideoSprite.prototype.renderSelfInContext = function (context) {
	TexturedSprite.prototype.renderSelfInContext.apply (this, arguments);
	
};

VideoSprite.prototype.loadVideo = function (videoPath, stillImagePath) {
	this.videoPath = videoPath;
	this.stillImagePath = stillImagePath;
	
	if (stillImagePath) {
		var image = this.image;
		image.addListener ("complete", this.completeImage, this);
		image.isOpaque = true;
		image.loadTexture (stillImagePath);
		
	} else {
		this.completeShowImage ();
		
	}
	
};

VideoSprite.prototype.completeImage = function (image) {
	image.removeListener ("complete", this.completeImage, this);
	image.stopAnimation ("Fade");
	image.setAlpha (1);
	
	if (!this.videoPlayBackDelay)
		this.videoPlayBackDelay = 1;
	
	this.addRunLoopHandler ("completeShowImage");
	
};

VideoSprite.prototype.completeShowImage = function () {
	if (this.videoPlayBackDelay) {
		this.videoPlayBackDelay--;
		return;
		
	}
	this.removeRunLoopHandler ("completeShowImage");
	
	if (!this.texture)
		this.setUpVideoTexture ();
	
	if (this.texture && this.texture !== this.element.firstChild)
		this.element.insertBefore (this.texture, this.element.firstChild);
	
	var lastVideoElement = this.texture;
	lastVideoElement.pause ();
	this.element.removeChild (lastVideoElement);
	
	var videoElement = document.createElement ("video");
	videoElement.className = "sprite";
	videoElement.style.objectFit = "fill";
	
	this.texture = videoElement;
	
	TexturedSprite.copyStyles (lastVideoElement.style, videoElement.style);
	videoElement.style.display = "block";
	
	var videoElement = this.texture;
	
	var sourceElement = document.createElement ("source");
	var videoPath = this.videoPath + "." + this.VIDEO_FILE_TYPE +
		(this.bypassCache ? "?ck=" + new Date ().getTime () : "");
	
	sourceElement.setAttribute ("type", this.VIDEO_TYPE);
	sourceElement.setAttribute ("src", videoPath);
	
	while (videoElement.childNodes.length)
		videoElement.removeChild (videoElement.firstChild);
	
	if (this.playsInline)
		videoElement.setAttribute ("playsinline", "");
	
	// if (this.autoPlay)
		videoElement.setAttribute ("autoplay", "");
	
	if (this.playsLooped)
		videoElement.setAttribute ("loop", "");
	
	if (this.playsInline && !this.forceUnmute)
		videoElement.setAttribute ("muted", "");
	
	if (this.poster)
		videoElement.setAttribute ("poster", this.poster);
	
	if (IS_TOUCH_DEVICE) {
		videoElement.setAttribute ("src", videoPath);
		
		var image = this.image;
		this.clickImageOnIPad (image);
		
	} else {
		videoElement.appendChild (sourceElement);
		
	}
	
	this.setVolume (this.volume);
	
	this.element.insertBefore (videoElement, this.image.element);
	
	var readyState = videoElement.readyState;
	
	var that = this;
	if (!this.videoCanPlayThroughHandler)
		this.videoCanPlayThroughHandler = function (event) {
			that.videoCanPlayThrough ();
			
		};
	
	if (readyState == videoElement.HAVE_ENOUGH_DATA) {
		this.videoCanPlayThroughHandler ();
		
	} else {
		videoElement.addEventListener ("canplay", this.videoCanPlayThroughHandler, false);
		
	}
	
	
};

VideoSprite.prototype.clickImageOnIPad = function (image) {
	var videoElement = this.texture;
	// videoElement.setAttribute ("controls", "")
	videoElement.addEventListener ("canplay", this.videoCanPlayThroughHandler, false);
	
	this.callVideoPlayFunction ();
	
};

// HTML video handlers

VideoSprite.prototype.videoCanPlayThrough = function () {
	var videoElement = this.texture;
	videoElement.removeEventListener ("canplay", this.videoCanPlayThroughHandler, false);
	// videoElement.removeEventListener ("canplaythrough", this.videoCanPlayThroughHandler, false);
	
	var that = this;
	if (!this.videoPlayHandler) {
		this.videoPlayHandler = function () {
			that.videoPlay ();
			
		};
		
	}
	videoElement.addEventListener ("playing", this.videoPlayHandler, false);
	
	if (Environment.MUTED)
		videoElement.volume = 0;

	var clickBox = this.clickBox;
	if (clickBox) {
		clickBox.stopAnimation ("Fade");
		clickBox.setAlpha (0);
		clickBox.element.style.display = "none";
		
	}
	
	if (videoElement.duration)
		this.videoDuration = videoElement.duration;
	
	if (videoElement.videoWidth) {
		var textureSize = this.textureSize;
		
		textureSize [0] = videoElement.videoWidth;
		textureSize [1] = videoElement.videoHeight;
		
		this.dispatchEvent ("updateLayout");
		
	}
	
	this.callVideoPlayFunction ();
	
	if (IS_IPAD)
		this.setNeedsRedraw ();
	
};

VideoSprite.prototype.callVideoPlayFunction = function () {
	var videoElement = this.texture;
	
	var promise = videoElement.play ();
	
	if (promise) {
		promise.then (function () {
			// trace ("autoplay started");
			this.dispatchEvent ("autoplay-allowed");
			
		}.bind (this), function (error) {
			// trace ("autoplay prevented", error);
			this.dispatchEvent ("autoplay-prevented");
			
		}.bind (this));
		
	}
	
};

VideoSprite.prototype.videoPlay = function () {
	this.fadeOutImage ();
	
	var videoElement = this.texture;
	videoElement.removeEventListener ("playing", this.videoPlayHandler, false);
	
	if (Environment.MUTED)
		videoElement.volume = 0;
	
	var that = this;
	if (!this.videoEndedHandler) {
		this.videoEndedHandler = function (event) {
			that.videoEnded ();
			
		};
		
	}
	videoElement.addEventListener ("ended", this.videoEndedHandler, false);
	
	if (videoElement.duration)
		this.videoDuration = videoElement.duration;
	
	if (videoElement.videoWidth) {
		var textureSize = this.textureSize;
		
		textureSize [0] = videoElement.videoWidth;
		textureSize [1] = videoElement.videoHeight;
		
		this.dispatchEvent ("updateLayout");
		
	}
	
	if (!this.videoTimeUpdateHandler) {
		this.videoTimeUpdateHandler = function (event) {
			that.timeUpdate (event);
			
		};
		
	}
	
	videoElement.addEventListener ("timeupdate", this.videoTimeUpdateHandler, false);
	
	this.dispatchEvent ("start");
	
};

VideoSprite.prototype.currentTime = 0;
VideoSprite.prototype.videoDuration = 0;

VideoSprite.prototype.hangDelay = 5;

VideoSprite.prototype.timeUpdate = function (event) {
	var videoElement = this.texture;
	var currentTime = this.currentTime = videoElement.currentTime;
	
	var duration = videoElement.duration;
	if (duration && Math.abs (currentTime - duration) < .05) {
		if (!this.hangDelay--) {
			this.videoEndedHandler ();
			this.hangDelay = 5;
			
		}
		
	}
	
	this.dispatchEvent ("progress");
	
};

VideoSprite.prototype.videoEnded = function () {
	var videoElement = this.texture;
	videoElement.removeEventListener ("ended", this.videoEndedHandler, false);
	videoElement.removeEventListener ("timeupdate", this.videoTimeUpdateHandler, false);
	videoElement.pause ();
	
	this.isPaused = true;
	this.dispatchEvent ("stop");
	
};

//

VideoSprite.prototype.fadeOutImage = function () {
	this.removeListener ("start", this.fadeOutImage, this);
	
	var image = this.image;
	if (image.alpha == 1) {
		image.startAnimation ("Fade",
			{direction: 0, rate: .5, phase: 1, delay: this.USE_HTML_PLAYER ? 3 : 0}
			
		);
		
	}
	
};

VideoSprite.prototype.volume = 1;

VideoSprite.prototype.setVolume = function (volume) {
//	if (Environment.MUTED)
//		return;
	volume = this.volume = Math.max (0, Math.min (1, volume));
	
	if (this.USE_HTML_PLAYER) {
		var videoElement = this.texture;
		if (videoElement)
			videoElement.volume = volume;
		
	} else {
		try {
			var flashObject = this.getFlashObject ();
			if (flashObject && flashObject.setVolume)
				flashObject.setVolume (volume);
			
		} catch (exception) {
			
		}
		
	}
	
};

VideoSprite.prototype.cueVideo = function (time) {
	var videoElement = this.texture;
	videoElement.currentTime = time;
	
};

VideoSprite.prototype.pauseVideo = function () {
	this.isPaused = true;
	
	var video = this.texture;
	if (video.readyState)
		video.pause ();
	
};

VideoSprite.prototype.resumeVideo = function () {
	this.isPaused = false;
	
	var video = this.texture;
	
	video.addEventListener ("playing", this.videoPlayHandler, false);
	video.addEventListener ("timeupdate", this.videoTimeUpdateHandler, false);
	video.addEventListener ("ended", this.videoEndedHandler, false);
	
	this.callVideoPlayFunction ();
	
};

VideoSprite.prototype.stopVideo = function () {	
	this.isPaused = true;
	
	var video = this.texture;
	
	if (video.readyState)
		video.pause ();
	
};

VideoSprite.prototype.rewindVideo = function () {
	this.isPaused = true;
	
	var video = this.texture;
	
	if (video.readyState) {
		video.pause ();
		video.currentTime = 0;
		
	}
	
};

VideoSprite.prototype.restartVideo = function () {
	this.isPaused = false;
	
	var image = this.image;
	image.stopAnimation ("Fade");
	image.setAlpha (0); // 1);
	this.addListener ("start", this.fadeOutImage, this);
	
	var video = this.texture;
	
	if (video.readyState) {
		video.pause ();
		
		if (!video.hasEnded)
			video.currentTime = 0;
		
		video.addEventListener ("playing", this.videoPlayHandler, false);
		video.addEventListener ("timeupdate", this.videoTimeUpdateHandler, false);
		video.addEventListener ("ended", this.videoEndedHandler, false);
		
		this.callVideoPlayFunction ();
		
	}
	
};

//

VideoSprite.prototype.dispose = function () {
	var videoElement = this.texture;
	
	videoElement.src = "";
	
	videoElement.removeEventListener ("canplay", this.videoCanPlayThroughHandler, false);
	videoElement.removeEventListener ("timeupdate", this.videoTimeUpdateHandler, false);
	videoElement.removeEventListener ("playing", this.videoPlayHandler, false);
	videoElement.removeEventListener ("ended", this.videoEndedHandler, false);
	
};
