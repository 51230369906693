import * as Environment from "../base/Environment.js";

import {AnimatableSprite} from "../base/Display2D.js";
import {SlidingSpritePrototype} from "../components/Control.js";

import {SiteSection} from "./SiteSection.js";

import {ImageModel} from "./Model.js";

//
// Lightbox extends AnimatableSprite, SlidingSpritePrototype
//

export const Lightbox = function (context) {
	AnimatableSprite.apply (this, arguments);
	
	if (Lightbox.sharedInstance)
		throw new Error ("cannot instantiate. Lightbox is singleton.");
	
};

Lightbox.prototype = Object.create (AnimatableSprite.prototype);
Lightbox.extendPrototype (SlidingSpritePrototype);

Lightbox.prototype.takeElement = function (element) {
	this.element.parentNode.removeChild (this.element);
	this.element = element;
	
	SlidingSpritePrototype.call (this, this.context);
	
	element.classList.add ("unselectable");
	element.style.visibility = "hidden";
	
	const downloadButtonElement = element.querySelector (".lightbox-download-button");;
	const downloadButton = this.downloadButton = this.attachSprite (LightboxDownloadButton);
	downloadButton.takeElement (downloadButtonElement);
	downloadButton.addListener (Environment.IS_TOUCH_DEVICE ? "touchend" : "click", this.clickDownloadButton, this);
	
	const imageContainer = this.imageContainer = document.createElement ("div");
	imageContainer.classList.add ("lighbox-image-container");
	
	imageContainer.appendChild (downloadButtonElement);
	element.appendChild (imageContainer);
	
	const images = this.images = new Array ();
	
	var scrollingController = this.scrollingController;
	
	scrollingController.getMouseListenerTarget = function () {
		return element;
		
	};
	
	scrollingController.awake ();
	scrollingController.addWheelListener ();
	scrollingController.cancelHorizontalScroll = true;
	
	scrollingController.addListener ("beginDrag", this.beginDrag, this);
	scrollingController.addListener ("dragHorizontal", this.dragHorizontal, this);
	scrollingController.addListener ("cancelDragHorizontal", this.endDragHorizontal, this);
	scrollingController.addListener ("endDrag", this.endDragHorizontal, this);
	
	scrollingController.addListener ("scrollHorizontal", this.scrollHorizontal, this);
	scrollingController.addListener ("cancelScrollHorizontal", this.cancelScrollHorizontal, this);
	
	if (!this.IS_TOUCH_DEVICE) {
		this.addListener ("mouseover", this.mouseOver, this);
		this.addListener ("mouseout", this.mouseOut, this);
		
	}
	
	if (Environment.FAST_PASS) {
		/*
		this.appendImage (ImageModel.list [0], true);
		 this.appendImage (ImageModel.list [1], true);
		this.appendImage (ImageModel.list [2], true);
		this.appendImage (ImageModel.list [3], true);
		this.appendImage (ImageModel.list [4], true);
		this.appendImage (ImageModel.list [5], true);
		*/
		
		/*
		window.setTimeout (function () {
			// this.insertImage (ImageModel.list [8], 2);
			this.appendImage (ImageModel.list [8]);
			
		}.bind (this), 1000);
		
		window.setTimeout (function () {
			this.images [3].imageModel.setIsSelected (false);
			
		}.bind (this), 2000);
		*/
		
	}
	
};

Lightbox.prototype.mouseOver = function (event) {
	this.isMouseOver = true;
	this.getStage ().addListener ("mousemove", this.mouseMove, this);
	
	this.currentMouse = this.touchDescriptionForObject (event);
	
};

Lightbox.prototype.mouseOut = function (event) {
	this.setHoveredItem ();
	
	this.isMouseOver = false;
	this.getStage ().removeListener ("mousemove", this.mouseMove, this);
	
};

Lightbox.prototype.mouseMove = function (stage) {
	if (this.isDragging)
		return;
	
	const event = stage.currentEvent;
	const mouse = this.touchDescriptionForObject (event);
	
	this.currentMouse = this.touchDescriptionForObject (event);
	
	this.updateMouseOver ();
	
};

Lightbox.prototype.show = function (dontAnimate) {
	if (this.isShown)
		return;
	this.isShown = true;
	
	if (dontAnimate) {
		this.stopAnimation ("Slide");
		this.states ["Slide"] = {phase: 1};
		this.animateSlide ();
		
	} else {
		this.startAnimation ("Slide", {direction: 1, rate: .04});
		
	}
	
};

Lightbox.prototype.hide = function (dontAnimate) {
	if (!this.isShown)
		return;
	this.isShown = false;
	
	if (dontAnimate) {
		this.stopAnimation ("Slide");
		this.states ["Slide"] = {phase: 0};
		this.animateSlide ();
		
	} else {
		this.startAnimation ("Slide", {direction: 0, rate: .045});
		
	}
	
};

Lightbox.prototype.offsetBottom = 0;
Lightbox.prototype.slidePhase = 0;

Lightbox.prototype.animateSlide = function () {
	const state = this.updatedState ("Slide");
	// this.slidePhase = state.phase;
	
	let t = state.phase;
	
	if (state.direction > 0) {
		t = 1 - t;
		t = 1 - t * t;
		t = .5 - Math.cos (Math.PI * t) * .5;
		
	} else {
		t = .5 - Math.cos (Math.PI * t) * .5;
		
	}
	
	this.slidePhase = t;
	
	const t_ = 1 - t;
	
	const element = this.element;
	const elementHeight = element.offsetHeight;
	const offsetBottom = this.offsetBottom = elementHeight * t_;
	
	if (t) {
		element.style.visibility = "";
		
		element.style.bottom = t == 1 ?
			"" :
			Math.round (-offsetBottom) + "px";
		
	} else {
		element.style.visibility = "hidden";
		
	}
	
	
	this.dispatchEvent ("animateSlide");
	
};

Lightbox.prototype.getOffsetBottom = function (offsetHeightOnly) {
	if (this.slidePhase) {
		const element = this.element;
		const elementHeight = element.offsetHeight;
		
		return this.offsetBottom - elementHeight;
		
	} else {
		return 0;
		
	}
	
};

Lightbox.prototype.setIsDarkeningEnabled = function (isDarkeningEnabled) {
	if (this.isDarkeningEnabled == isDarkeningEnabled)
		return;
	
	this.isDarkeningEnabled = isDarkeningEnabled;
	
	const element = this.element;
	if (isDarkeningEnabled)
		element.classList.add ("darkened");
	else
		element.classList.remove ("darkened");
	
};

Lightbox.prototype.appendImage = function (imageModel, dontAnimate) {
	const images = this.images;
	this.insertImage (imageModel, images.length, dontAnimate);
	
};

Lightbox.prototype.insertImage = function (imageModel, index, dontAnimate) {
	const images = this.images;
	if (images.indexOf (imageModel.lightboxImage) >= 0)
		return;
	
	if (!images.length)
		dontAnimate = true;
	
	const imageContainer = this.imageContainer;
	
	const image = this.attachSprite (LightboxImage);
	imageContainer.appendChild (image.element);
	image.takeModel (imageModel);
	
	if (!dontAnimate)
		image.element.style.visibility = "hidden";
	
	if (!dontAnimate)
		image.startAnimation ("ScaleOut", {direction: 1, phase: 0, rate: .08});
	
	this.insertImage_ (image, index);
	
	if (image.targetPosition) {
		const viewOffset = this.viewOffset;
		const imageLeft = image.targetPosition [0] - viewOffset;
		const imageRight = imageLeft + image.viewSize [0];
		
		const viewSize = this.viewSize;
		
		const spaceRight = 2 * this.spacing + this.downloadButton.element.offsetWidth;
		
		if (imageRight > viewSize [0] - spaceRight) {
			const paddingLeft = SiteSection.paddingLeft;
			this.viewOffset = this.boundedViewOffset (
				image.targetPosition [0] + image.viewSize [0] - viewSize [0] +
					spaceRight
				
			);
			
			this.slidingInertia = .75;
			this.addRunLoopHandler ("processSliding");
			
		}
		
	}
	
};

Lightbox.prototype.insertImage_ = function (image, index) {
	const images = this.images;
	const imageModel = image.imageModel;
	
	images.splice (index, 0, image);
	
	if (this.viewSize) {
		const lastMaxScrollOffset = this.getMaxScrollOffset ();
		this.updateImageSizes ();
		const maxScrollOffset = this.getMaxScrollOffset ();
		
		const maxScrollDelta = Math.max (0, lastMaxScrollOffset - maxScrollOffset + (image.viewSize [0] + this.spacing));
		if (maxScrollOffset) {
			const leftShift = Math.floor ((image.viewSize [0] + this.spacing) * .5) - maxScrollDelta * .5;
			
			for (let i = 0; i < images.length; i++) {
				const image = images [i];
				
				if (i != index)
					image.position [0] += leftShift;
				
			}
			
			this.downloadButton.position [0] += leftShift;
			
			this.updateLayout ();
			
			this.viewOffset = this.currentViewOffset =
				this.boundedViewOffset (this.currentViewOffset + leftShift);
			
		} else {
			this.updateLayout ();
			
		}
		
		this.updateViewOffset ();
		
	}
	
	image.addListener ("enableDrag", this.enableDragImage, this);
	
	imageModel.lightboxImage = image;
	imageModel.setIsSelected (true);
	
	imageModel.addListener ("changeIsSelected", this.changeIsSelected, this);
	
	this.show ();
	
};

Lightbox.prototype.changeIsSelected = function (imageModel) {
	if (imageModel.isSelected)
		return;
	
	imageModel.removeListener ("changeIsSelected", this.changeIsSelected, this);
	
	const image = imageModel.lightboxImage;
	this.removeImage (this.images.indexOf (image));
	
};

Lightbox.prototype.removeImage = function (index) {
	if (index < 0)
		return;
	
	const images = this.images;
	const image = images.splice (index, 1) [0];
	
	image.addListener ("completeScaleOut", this.removeChild, this);
	image.startAnimation ("ScaleOut", {direction: 0, phase: 1, rate: .15});
	
	this.updateLayoutAfterRemovingImage (image);
	
	this.updateViewOffset ();
	

	if (image.targetPosition) {
		const viewOffset = this.viewOffset;
		const imageLeft = image.targetPosition [0] - viewOffset;
		const imageRight = imageLeft + image.viewSize [0];
		
		const viewSize = this.viewSize;
		
		const paddingLeft = SiteSection.paddingLeft;
		
		const spaceLeft = this.spacing;
		const spaceRight = this.spacing;
		
		if (imageLeft < spaceLeft) {
			this.viewOffset = this.boundedViewOffset (
				image.targetPosition [0] - spaceLeft
				
			);
			
			this.slidingInertia = .8;
			this.addRunLoopHandler ("processSliding");
			
		} else if (imageRight > viewSize [0] - spaceRight) {
			this.viewOffset = this.boundedViewOffset (
				image.targetPosition [0] + image.viewSize [0] - viewSize [0] + spaceRight
				
			);
			
			this.slidingInertia = .8;
			this.addRunLoopHandler ("processSliding");
			
		}
		
	}
	
	if (!images.length)
		this.hide ();
	
};

Lightbox.prototype.updateLayoutAfterRemovingImage = function (image) {
	const images = this.images;
	const downloadButton = this.downloadButton;
	
	if (this.viewSize) {
		this.updateImageSizes ();
		const maxScrollOffset = this.getMaxScrollOffset ();
		
		if (maxScrollOffset) {
			let leftShift = Math.floor ((image.viewSize [0] + this.spacing) * .5);
			
			const lastViewOffset = this.currentViewOffset;
			const viewOffset = this.viewOffset = this.currentViewOffset =
				this.boundedViewOffset (this.currentViewOffset - leftShift);
			
			const delta = lastViewOffset - viewOffset - leftShift;
			leftShift += delta;
			
			const draggingImage = this.draggingImage;
			
			for (let i = 0; i < images.length; i++) {
				const image = images [i];
				if (image == draggingImage)
					continue;
				image.position [0] -= leftShift;
				
			}
			
			image.setPosition ([
				image.position [0] - leftShift,
				image.position [1]
				
			]);
			
			downloadButton.position [0] -= leftShift;
			
			this.updateLayout ();
			
		} else {
			const lastViewOffset = this.viewOffset;
			const viewOffset = this.viewOffset = this.currentViewOffset =
				this.boundedViewOffset (lastViewOffset);
			var viewOffsetDelta = Math.max (0, lastViewOffset - viewOffset);
			
			if (viewOffsetDelta) {
				for (let i = 0; i < images.length; i++) {
					const image = images [i];
					image.position [0] -= viewOffsetDelta;
					
				}

				image.setPosition ([
					image.position [0] - viewOffsetDelta,
					image.position [1]
					
				]);
				
				downloadButton.position [0] -= viewOffsetDelta;
				
			}
			
			this.updateLayout ();
			
		}
		
	}
	
};

Lightbox.prototype.setViewSize = function (viewSize) {
	this.viewSize = [
		viewSize [0],
		this.element.offsetHeight
		
	];
	
	const isMobile = viewSize [0] <= 767;
	this.spacing = isMobile ? 15 : 20;
	
	const downloadButton = this.downloadButton;
	downloadButton.updateLayout ();
	
	this.updateImageSizes ();
	this.updateLayout (true);
	
	this.viewOffset = this.currentViewOffset = this.boundedViewOffset (this.viewOffset);
	this.updateViewOffset ();
	
};

Lightbox.prototype.spacing = 20;

Lightbox.prototype.updateImageSizes = function () {
	const images = this.images;
	const viewSize = this.viewSize;
	
	const imageSpacing = this.spacing;
	let cursor = 0;
	
	for (let i = 0; i < images.length; i++) {
		const image = images [i];
		if (image.aboutToDelete)
			continue;
		
		if (cursor)
			cursor += imageSpacing;
		
		let imageSize = image.imageModel.size;
		const imageScale = (viewSize [1] - imageSpacing * 2) / imageSize [1];
		imageSize = [
			Math.round (imageSize [0] * imageScale),
			Math.round (imageSize [1] * imageScale)
			
		];
		
		image.setViewSize (imageSize);
		cursor += imageSize [0];
		
	}
	
	const downloadButton = this.downloadButton;
	if (images.length) {
		cursor += imageSpacing;
		cursor += downloadButton.size [0];
		
	}
	
	this.width = cursor;
	
	const paddingLeft = SiteSection.paddingLeft;
	this.maxScrollOffset = Math.max (0, cursor - (viewSize [0] - 2 * paddingLeft));
	
	/*
	if (this.maxScrollOffset == 240 && this.draggingImage)
		debugger;
	*/
	
};

Lightbox.prototype.updateLayout = function (dontAnimate) {
	const images = this.images;
	const viewSize = this.viewSize;
	
	const imageSpacing = this.spacing;
	let cursor = 0;

	const downloadButton = this.downloadButton;
	const buttonSize = downloadButton.size;
	
	if (images.length) {
		downloadButton.fadeIn (4);
		
		downloadButton.layoutPosition = [
			cursor,
			Math.floor (
				(viewSize [1] - buttonSize [1]) * .5
				
			)
			
		];
		
		cursor += buttonSize [0];
		
	} else {
		downloadButton.fadeOut (4);
		
	}
	
	let imagesWidth = 0;
	for (let i = 0; i < images.length; i++) {
		const image = images [i];
		if (image.aboutToDelete)
			continue;
		
		if (cursor)
			cursor += imageSpacing;
		
		if (imagesWidth)
			imagesWidth += imageSpacing;
		
		const imageSize = image.viewSize;
		
		image.layoutPosition = [
			cursor,
			Math.floor (
				(viewSize [1] - imageSize [1]) * .5
				
			)
			
		];
		
		cursor += imageSize [0];
		imagesWidth += imageSize [0];
		
	}
	
	const paddingLeft = SiteSection.paddingLeft;
	
	const offsetLeft = paddingLeft; // Math.max (0, Math.floor ((viewSize [0] - 2 * paddingLeft - cursor) * .5)) + paddingLeft;
	const layoutPadding = Math.max (0, viewSize [0] - buttonSize [0] - imagesWidth - 2 * paddingLeft - imageSpacing); // 0
	
	if (images.length) {
		const targetPosition = [
			downloadButton.layoutPosition [0] + offsetLeft,
			downloadButton.layoutPosition [1]
			
		];
		
		if (dontAnimate) {
			downloadButton.stopAnimation ("Slide");
			downloadButton.targetPosition = undefined;
			
		}
		downloadButton.slideTo (targetPosition);
		
	}

	
	const draggingImage = this.draggingImage;
	
	for (let i = 0; i < images.length; i++) {
		const image = images [i];
		if (image == draggingImage)
			continue;
		
		const layoutPosition = image.layoutPosition;
		const targetPosition = [
			image.layoutPosition [0] + offsetLeft + layoutPadding,
			image.layoutPosition [1]
			
		];
		if (dontAnimate) {
			image.stopAnimation ("Slide");
			image.targetPosition = undefined;
			
		}
		image.slideTo (targetPosition);
		
	}
	
	
};

Lightbox.prototype.beginDrag = function (scrollingController) {
	SlidingSpritePrototype.beginDrag.apply (this, arguments);
	
	scrollingController.currentEvent.preventDefault ();
	this.isDragging = true;
	
};

Lightbox.prototype.getMaxScrollOffset = function () {
	return this.maxScrollOffset;
	
};

Lightbox.prototype.boundedViewOffset = function (viewOffset) {
	return Math.max (0, Math.min (this.getMaxScrollOffset (), viewOffset));
	
};

Lightbox.prototype.dragHorizontal = function (scrollingController) {
	var deltaX = scrollingController.delta.x;
	this.removeRunLoopHandler ("processSliding");
	
	this.viewOffset = this.currentViewOffset = this.startViewOffset + deltaX;
	this.updateViewOffset ();
	
};

Lightbox.prototype.releaseDrag = function () {
	SlidingSpritePrototype.releaseDrag.apply (this, arguments);

	this.isDragging = false;
	this.updateMouseOver ();
	
};

Lightbox.prototype.endDragHorizontal = function (scrollingController) {
	this.isDragging = false;
	
	var didFlick = scrollingController.didFlickHorizontal;
	
	if (didFlick) {
		var targetViewOffset = this.viewOffset = this.viewOffset + scrollingController.flickDeltaX * 1.4;
		
		this.slidingInertia = .9;
		this.addRunLoopHandler ("processSliding");
		
	} else {
		this.snapToBounds ();
		
	}
	
	this.updateMouseOver ();
	
};

Lightbox.prototype.scrollHorizontal = function (scrollingController) {
	if (this.isDragging)
		return;
	
	if (this.isWheelLocked)
		return;
	
	var delta = scrollingController.delta.x;
	var targetViewOffset = this.viewOffset + delta * 1.5;
	
	if (!this.isBumping) {
		if (!(this.bumpLockDirection * scrollingController.delta.x > 0)) {
			this.bumpLockDirection = 0;
			
			this.viewOffset = targetViewOffset;
			this.slidingInertia = .7;
			
			this.addRunLoopHandler ("processSliding");
			
			this.updateViewOffset ();
			
		}
		
	}
	
};

Lightbox.prototype.cancelScrollHorizontal = function (scrollingController) {
	
};

Lightbox.prototype.updateViewOffset = function (dontHandleDraggingImage) {
	if (isNaN (this.maxScrollOffset))
		return;
	
	let viewOffset = this.currentViewOffset;
	// trace ("uvo", viewOffset, this.getMaxScrollOffset ());
	
	viewOffset = (viewOffset + 1 * this.boundedViewOffset (viewOffset)) / 2;
	
	const delta = viewOffset - this.lastViewOffset;
	const draggingImage = this.draggingImage;
	if (draggingImage && delta) {
		this.imageStartPos [0] += delta;
		draggingImage.setPosition (
			draggingImage.position [0] + delta,
			draggingImage.position [1]
			
		);
		
		if (!dontHandleDraggingImage)
			this.doDragImage ();
		
	}
	this.lastViewOffset = viewOffset;
	
	var imageContainer = this.imageContainer;
	imageContainer.style.transform = "translateX(" + -viewOffset + "px)";
	
	this.updateMouseOver ();
	
	this.renderInContext ();
	
};

Lightbox.prototype.updateMouseOver = function () {
	if (!this.isMouseOver || this.isDragging || this.draggingImage)
		return;
	
	const mouse = this.currentMouse;
	
	var imageContainer = this.imageContainer;
	const children = imageContainer.children;
	
	for (var i = children.length; i--;) {
		const child = children [i];
		const childBounds = child.getBoundingClientRect ();
		
		if (mouse [0] >= childBounds.left && mouse [0] < childBounds.right) {
			this.setHoveredItem (child);
			break;
			
		}
		
	}
	
	if (i < 0)
		this.setHoveredItem ();
	
};

Lightbox.prototype.setHoveredItem = function (hoveredItem) {
	const lastHoveredItem = this.hoveredItem;
	if (lastHoveredItem == hoveredItem)
		return;
	
	if (lastHoveredItem)
		lastHoveredItem.classList.remove ("hovered");
	
	this.hoveredItem = hoveredItem;
	
	if (hoveredItem)
		hoveredItem.classList.add ("hovered");
	
};

Lightbox.zIndexDepth = 1;

Lightbox.prototype.enableDragImage = function (draggingImage) {
	this.draggingImage = draggingImage;
	draggingImage.element.classList.add ("dragging");
	draggingImage.element.style.zIndex = Lightbox.zIndexDepth++;
	
	this.releaseDrag ();
	this.snapToBounds ();
	
	var stage = this.getStage ();
	stage.addListener ("mousemove", this.doDragImage, this);
	stage.addListener ("mouseup", this.endDragImage, this);
	
	const imageStartMouse = this.imageStartMouse = this.lastMouse = draggingImage.currentMouse;
	this.imageStartPos = draggingImage.position.concat ();
	
	this.addRunLoopHandler ("trackDragScroll");
	
	// this.setHoveredItem ();
	
	this.renderInContext ();
	
};

Lightbox.prototype.doDragImage = function (sender) {
	const imageStartMouse = this.imageStartMouse;
	const mouse = sender ? this.touchDescriptionForObject (sender.currentEvent) : this.lastMouse;
	this.lastMouse = mouse;
	
	const delta = [
		mouse [0] - imageStartMouse [0],
		mouse [1] - imageStartMouse [1]
		
	];
	
	const draggingImage = this.draggingImage;
	const imageStartPos = this.imageStartPos;
	
	draggingImage.setPosition (
		imageStartPos [0] + delta [0],
		imageStartPos [1] + delta [1]
		
	);
	
	const images = this.images;
	const sourceIndex = images.indexOf (draggingImage);
	
	if (draggingImage.position [1] > -draggingImage.viewSize [1] - 15) {
		const wasAboutToDelete = draggingImage.aboutToDelete;
		draggingImage.aboutToDelete = false;
		
		const xOffset = draggingImage.position [0];
		
		let i;
		let targetIndex;
		
		for (i = images.length; i--;) {
			const image = images [i];
			if (image == draggingImage)
				continue;
			
			const imageX = image.targetPosition [0] + image.viewSize [0] * .5;
			
			if (wasAboutToDelete) {
				if (imageX < xOffset + draggingImage.viewSize [0] * .5) {
					targetIndex = i + 2;
					break;
					
				}
				
			} else {
				if (imageX < xOffset + (sourceIndex < i ? draggingImage.viewSize [0] : 0)) {
					targetIndex = i + 1;
					break;
					
				}
				
			}
			
		}
		
		if (wasAboutToDelete) {
			images.splice (sourceIndex, 1);
			this.insertImage_ (draggingImage, targetIndex + (sourceIndex > targetIndex ? 0 : -1));
			
			this.updateLayout ();
			
		} else {
			if (sourceIndex != targetIndex) {
				images.splice (sourceIndex, 1);
				images.splice (targetIndex + (sourceIndex > targetIndex ? 0 : -1), 0, draggingImage);
				
				this.updateLayout ();
				
			}
			
		}
		
	} else {
		if (!draggingImage.aboutToDelete) {
			draggingImage.aboutToDelete = true;
			draggingImage.stopAnimation ("Slide");
			
			this.updateLayoutAfterRemovingImage (draggingImage);
			this.updateViewOffset (true);
			
			draggingImage.setPosition (
				imageStartPos [0] + delta [0],
				imageStartPos [1] + delta [1]
				
			);
			
		}
		
	}
	
	draggingImage.setWillDelete (draggingImage.aboutToDelete);
	
	this.renderInContext ();
	
};

Lightbox.prototype.trackDragScroll = function () {
	const draggingImage = this.draggingImage;
	const viewSize = this.viewSize;
	
	const xOff = draggingImage.position [0] + draggingImage.viewSize [0] * .5 - this.currentViewOffset;
	
	const dragSpeed = xOff < viewSize [0] * .5 ?
		-Math.max (0, 7.5 - xOff * .1) :
		Math.max (0, 7.5 - (viewSize [0] - xOff) * .1);
	
	if (dragSpeed) {
		this.viewOffset = this.boundedViewOffset (this.viewOffset + dragSpeed * 2);
		
		this.slidingInertia = .9;
		this.addRunLoopHandler ("processSliding");
		
	}
	
};

Lightbox.prototype.endDragImage = function () {
	var stage = this.getStage ();
	stage.removeListener ("mousemove", this.doDragImage, this);
	stage.removeListener ("mouseup", this.endDragImage, this);
	
	const draggingImage = this.draggingImage;
	draggingImage.element.classList.remove ("dragging");
	
	if (draggingImage.aboutToDelete) {
		const images = this.images;
		const sourceIndex = images.indexOf (draggingImage);
		
		this.removeImage (sourceIndex);
		draggingImage.imageModel.setIsSelected (false);
		
	}
	
	this.draggingImage = undefined;
	this.updateLayout ();
	
	this.removeRunLoopHandler ("trackDragScroll");
	
	this.updateMouseOver ();
	window.setTimeout (function () {
		this.updateMouseOver ();
		
	}.bind (this), 250);
	
};

Lightbox.prototype.clickDownloadButton = function (event) {
	if (this.scrollingController.didDrag)
		return;
	
	// trace ("--- click download ---");
	
	const parameters = new Object ();
	const imageList = parameters.images = new Array ();
	
	const images = this.images;
	for (let i = 0; i < images.length; i++) {
		const image = images [i];
		imageList.push (image.imageModel.id);
		
	}
	
	const downloadURL = this.element.getAttribute ("data-pdf-download-url");
	
	if (downloadURL) {
		const serial = JSON.stringify (imageList);
		window.open (downloadURL + "?images=" + encodeURIComponent (JSON.stringify (imageList)), Environment.IS_IE ? "_blank" : "_self");
		
	} else {
		const serial = JSON.stringify (parameters);
		window.open ("data:application/json," + serial);
		
	}
	
};

//
// LightboxImage extends AnimatableSprite
//

const LightboxImage = function (context) {
	AnimatableSprite.apply (this, arguments);
	
	const element = this.element;
	element.classList.add ("lightbox-image");
	
	const selectionDot = this.selectionDot = document.createElement ("div");
	selectionDot.classList.add ("selection-dot");
	
	selectionDot.addEventListener (Environment.IS_TOUCH_DEVICE ? "touchstart" : "mousedown", function (event) {
		if (event.type != "touchstart")
			event.preventDefault ();
		
		event.stopPropagation ();
		
	}.bind (this));
	
	selectionDot.addEventListener ("click", function (event) {
		const parent = this.parent;
		const images = parent.images;
		
		parent.removeImage (images.indexOf (this));
		this.imageModel.setIsSelected (false);
		
	}.bind (this));
	
	element.appendChild (selectionDot);
	
};

LightboxImage.prototype = Object.create (AnimatableSprite.prototype);

LightboxImage.prototype.takeModel = function (imageModel) {
	this.imageModel = imageModel;
	
	const image = this.image = document.createElement ("img");
	this.element.appendChild (image);
	
	image.src = imageModel.getThumbImagePath ();
	
	if (image.complete)
		this.completeImage ();
	else
		image.addEventListener ("load", this.completeImage.bind (this));
	
	this.addListener ("mousedown", this.mouseDown, this);
	
};

LightboxImage.prototype.completeImage = function () {
	const image = this.image;
	
};

LightboxImage.prototype.setViewSize = function (viewSize) {
	this.viewSize = viewSize;
	
	const element = this.element;
	element.style.width = viewSize [0] + "px";
	element.style.height = viewSize [1] + "px";
	
};

LightboxImage.prototype.animateScaleOut = function () {
	const state = this.updatedState ("ScaleOut");
	let t = 1 - state.phase;
	t = 1 - t * t;
	
	const scale = t;
	const element = this.element;
	
	element.style.transform = t == 1 ? "" : "scale(" + t + ")";
	if (element.style.visibility) {
		window.setTimeout (function () {
			element.style.visibility = "";
			
		}, 16 * 2);
		
	}
	
};

LightboxImage.prototype.mouseDown = function (sender) {
	const event = sender.currentEvent;
	if (event.button)
		return;
	
	var stage = this.getStage ();
	stage.addListener ("mousemove", this.doDrag, this);
	stage.addListener ("mouseup", this.mouseUp, this);
	
	const startMouse = this.startMouse = this.parent.touchDescriptionForObject (event);
	this.currentMouse = startMouse;
	this.startPosition = this.position.concat ();
	this.mouseDelta = undefined;
	
	this.mouseDownTimeout = window.setTimeout (function () {
		this.enableDrag ();
		
	}.bind (this), 500);
	
	this.element.classList.add ("will-drag");
	
};

LightboxImage.prototype.doDrag = function (sender) {
	const event = sender.currentEvent;
	
	const startMouse = this.startMouse;
	const mouse = this.parent.touchDescriptionForObject (event);
	this.currentMouse = mouse;
	
	const delta = this.mouseDelta = [
		mouse [0] - startMouse [0],
		mouse [1] - startMouse [1]
		
	];
	
	const position = this.position;
	const startPosition = this.startPosition;
	this.setPosition (
		position [0],
		startPosition [1] + (Math.abs (delta [1]) > 4 ? delta [1] : 0)
		
	);
	
	if (Math.abs (delta [0]) > 20 &&
		Math.abs (delta [1] * 2) < Math.abs (delta [0])) {
		this.mouseUp (sender);
		
	} else if (Math.abs (delta [1]) > 20) {
		this.enableDrag ();
		
	}
	
};

LightboxImage.prototype.mouseUp = function (sender) {
	this.element.classList.remove ("will-drag");
	
	const stage = this.getStage ();
	
	stage.removeListener ("mousemove", this.doDrag, this);
	stage.removeListener ("mouseup", this.mouseUp, this);
	
	window.clearTimeout (this.mouseDownTimeout);
	this.mouseDownTimeout = undefined;
	
	if (sender)
		this.parent.updateLayout ();
	
};

LightboxImage.prototype.enableDrag = function () {
	// trace ("-- enable drag --");
	
	this.mouseUp ();
	
	const delta = this.mouseDelta;
	if (delta) {
		const position = this.position;
		const startPosition = this.startPosition;
		this.setPosition (
			position [0] + delta [0],
			startPosition [1] + delta [1]
			
		);
		
	}
	
	this.dispatchEvent ("enableDrag");
	
};

LightboxImage.prototype.setWillDelete = function (willDelete) {
	if (this.willDelete == willDelete)
		return;
	
	this.willDelete = willDelete;
	
	const element = this.element;
	if (willDelete)
		element.classList.add ("will-delete");
	else
		element.classList.remove ("will-delete");
	
};

//
// LightboxDownloadButton extends AnimatableSprite
//

const LightboxDownloadButton = function (context) {
	AnimatableSprite.apply (this, arguments);
	
	this.setAlpha (0);
	
};

LightboxDownloadButton.prototype = Object.create (AnimatableSprite.prototype);

LightboxDownloadButton.prototype.takeElement = function (element) {
	this.element.parentNode.removeChild (this.element);
	this.element = element;
	
};

LightboxDownloadButton.prototype.updateLayout = function () {
	const element = this.element;
	
	const displayStyle = element.style.display;
	
	if (displayStyle)
		element.style.display = "";
	
	this.size = [
		element.offsetWidth,
		element.offsetHeight
		
	];
	
	if (displayStyle)
		element.style.display = displayStyle;
	
};
