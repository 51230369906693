import * as Environment from "../base/Environment.js";

import {LoaderSystem, JSONLoaderJob} from "../base/Loader.js";

import {RenderContext, AnimatableSprite} from "../base/Display2D.js";
import {ScrollingController, SlidingSpritePrototype} from "../components/Control.js";

import {SiteSection} from "./SiteSection.js";

import {ImageModel} from "./Model.js";
import {Lightbox} from "./Lightbox.js";

//
// PortfolioSection extends SiteSection
//

export const PortfolioSection = function (context) {
	SiteSection.apply (this, arguments);
	
};

window.PortfolioSection = PortfolioSection;

PortfolioSection.IS_EDITABLE = /* Environment.FAST_PASS || */ Environment.IS_IN_NEOS_EDITOR;

PortfolioSection.prototype = Object.create (SiteSection.prototype);

PortfolioSection.prototype.takeElement = function (element) {
	SiteSection.prototype.takeElement.apply (this, arguments);
	
	const mainElement = this.mainElement = this.getStage ().element;
	mainElement.parentNode.insertBefore (element, mainElement.nextElementSibling);
	
	this.setAlpha (0);
	
	const portfolio = this.portfolio = this.attachSprite (Portfolio);
	portfolio.takeElement (element);
	
	let lightbox = this.lightbox = Lightbox.sharedInstance;
	if (!lightbox) {
		lightbox = this.lightbox = this.attachSprite (Lightbox);
		lightbox.takeElement (document.querySelector (".lightbox"));
		
	}
	
	if (Environment.FAST_PASS) {
		const collection = portfolio.collections [0];
		
		/*
		window.setTimeout (function () {
			portfolio.expandCollection (collection);
			
		}.bind (this), 50);
		*/
		
		/*
		window.setTimeout (function () {
			// this.insertImage (ImageModel.list [8], 2);
			lightbox.appendImage (ImageModel.list [8]);
			
		}.bind (this), 500);
		*/
		
		/*
		window.setTimeout (function () {
			// Portfolio.TRANSITION_SPEED = .03 * .1;
			collection.clickItem (
				collection.items [1]
				
			);
			
		}.bind (this), 850);
		*/
		
		/*
		window.setTimeout (function () {
			// Portfolio.TRANSITION_SPEED = .03 * .5;
			collection.clickItem (
				collection.items [0]
				
			);
			
		}.bind (this), 850 * 2);
		*/
		
		/*
		window.setTimeout (function () {
			// Portfolio.TRANSITION_SPEED = .03 * .1;
			
			// portfolio.setMode (Portfolio.MODE_ZOOMED_OUT);
			portfolio.collapse ();
			
		}.bind (this), 850);
		*/
		
		/*
		window.setTimeout (function () {
			// Portfolio.TRANSITION_SPEED = .03 * .1;
			portfolio.advanceCollection (1);
			
		}.bind (this), 850);
		*/
		
		/*
		window.setTimeout (function () {
			// Portfolio.TRANSITION_SPEED = .03 * .1;
			portfolio.collapse ();
			
		}.bind (this), 850 * 2);
		*/
		
	}
	
};

PortfolioSection.prototype.setViewSize = function (viewSize) {
	SiteSection.prototype.setViewSize.apply (this, arguments);
	
	const portfolio = this.portfolio;
	portfolio.setViewSize (viewSize);
	
	const lightbox = this.lightbox;
	lightbox.setViewSize (viewSize);
	
	this.renderInContext ();
	
};

//
// Portfolio extends AnimatableSprite
//

const Portfolio = function (context) {
	AnimatableSprite.apply (this, arguments);
	
};

Portfolio.prototype = Object.create (AnimatableSprite.prototype);

Portfolio.TRANSITION_SPEED = .03;

Portfolio.prototype.takeElement = function (element) {
	SiteSection.prototype.takeElement.apply (this, arguments);
	
	this.setAlpha (0);
	
	this.containerElement = element.firstElementChild;
	
	const collections = this.collections = new Array ();
	const collectionElements = element.querySelectorAll (".portfolio-collection");
	
	const menuElement = this.menuElement = document.querySelector (".portfolio-menu");
	const menuItems = this.menuItems = menuElement.querySelectorAll ("a");
	
	const fragment = window.location.hash.substr (1).toLowerCase ();
	let fragmentIndex = -1;
	
	const applyTransformToMenuItems = Environment.IS_WEBKIT && !Environment.IS_SAFARI;
	
	for (let i = 0; i < collectionElements.length; i++) {
		const collectionElement = collectionElements [i];
		
		const collection = this.attachSprite (PortfolioCollection);
		const menuItem = menuItems [i];
		
		if (applyTransformToMenuItems)
			menuItem.classList.add ("chrome-fix");
		
		collection.takeElement (collectionElement, menuItem.textContent);
		
		let anchor = menuItem.getAttribute ("href").substr (1).toLowerCase ();
		anchor = anchor.replace (/[^a-z0-9]+/gi, "-");
		menuItem.setAttribute ("href", "#" + anchor);
		
		if (fragment == anchor)
			fragmentIndex = i;
		
		collections.push (collection);
		
	}
	
	for (let i = menuItems.length; i--;) {
		const menuItem = menuItems [i];
		menuItem.index = i;
		
		const collection = collections [Environment.FAST_PASS ? i % collections.length : i];
		if (collection && (collection.items.length || Environment.FAST_PASS)) {
			menuItem.addEventListener ("click", function (event) {
				event.preventDefault ();
				
				const menuItem = event.currentTarget;
				this.expandCollection (collections [menuItem.index % collections.length]);
				
			}.bind (this));
			
		} else {
			menuItem.parentNode.removeChild (menuItem);
			
		}
		
	}
	
	const navigation = this.navigation = this.attachSprite (PortfolioNavigation);
	navigation.takeElement (document.querySelector ("main .modal-navigation, .portfolio-section .modal-navigation"));
	
	const titleBar = this.titleBar = this.attachSprite (PortfolioTitleBar);
	titleBar.takeElement (document.querySelector ("main .modal-title-bar, .portfolio-section .modal-title-bar"));
	
	element.appendChild (navigation.element);
	element.appendChild (titleBar.element);
	
	window.setTimeout (function () {
		const lightbox = this.parent.lightbox;
		lightbox.addListener ("animateSlide", this.animateSlideLightbox, this);
		
	}.bind (this), 0);
	
	if (fragmentIndex >= 0) {
		window.setTimeout (function () {
			const collection = collections [fragmentIndex];
			this.expandCollection (collection);
			
		}.bind (this), 0);
		
	}
	
};

Portfolio.prototype.expandCollection = function (collection) {
	const viewSize = this.viewSize;
	
	if (this.element.style.display) {
		this.element.style.display = "";
		this.setViewSize (viewSize);
		
	}
	
	const collections = this.collections;
	
	{
		const offsets = this.getCollectionOffsets ();
		
		for (let i = 0; i < collections.length; i++) {
			const collection = collections [i];
			collection.startOffset = offsets [i];
			
		}
		
	}
	
	const expandedCollection = this.expandedCollection;
	
	this.expandedCollection = collection;
	
	{
		const offsets = this.getCollectionOffsets ();
		
		for (let i = 0; i < collections.length; i++) {
			const collection = collections [i];
			collection.targetOffset = offsets [i];
			
		}
		
	}
	
	if (this.isExpanded) {
		this.startOffset = expandedCollection.startOffset;
		this.targetOffset = collection.targetOffset;
		
		this.startAnimation ("Scrolling", {phase: 0, direction: 1, rate: Portfolio.TRANSITION_SPEED});
		
	} else {
		this.viewOffset = collection.targetOffset;
		this.updateViewOffset ();
		
		this.expand ();
		
	}
	
	collection.updateViewOffset ();
	
	var navigation = this.navigation;
	navigation.setTitle (collection.title);
	
	var currentIndex = collections.indexOf (collection);
	
	if (currentIndex > 0)
		navigation.upButton.classList.remove ("disabled");
	else
		navigation.upButton.classList.add ("disabled");
	
	if (currentIndex < collections.length - 1)
		navigation.downButton.classList.remove ("disabled");
	else
		navigation.downButton.classList.add ("disabled");
	
	window.location.hash = this.menuItems [
		collections.indexOf (collection)
		
	].getAttribute ("href").toLowerCase ();
	
};

Portfolio.prototype.getCollectionOffsets = function () {
	const collections = this.collections;
	const viewSize = this.collectionViewSize || this.viewSize;
	
	const offsets = new Array ();
	let cursor = 0;
	
	for (let i = 0; i < collections.length; i++) {
		const collection = collections [i];
		const height = (/* collection.viewSize ||*/ viewSize) [1];
		
		offsets.push (cursor);
		cursor += height;
		
	}
	
	return offsets;
	
};

Portfolio.prototype.animateScrolling = function () {
	const state = this.updatedState ("Scrolling");
	
	let t = 1 - state.phase;
	t = 1 - t * t;
	t = .5 - Math.cos (Math.PI * t) * .5;
	const t_ = 1 - t;
	
	this.viewOffset = this.startOffset * t_ + this.targetOffset * t;
	
	this.updateViewOffset ();
	
};

Portfolio.prototype.advanceCollection = function (delta) {
	const collections = this.collections;
	const expandedCollection = this.expandedCollection;

	this.expandCollection (
		collections [collections.indexOf (expandedCollection) + delta]
		
	);
	
};

Portfolio.prototype.animateSlideLightbox = function (lightbox) {
	this.updateViewOffset (true);
	
};

Portfolio.prototype.setViewSize = function (viewSize) {
	this.viewSize = viewSize;
	
	if ((this.slidePhase || 0) < 1)
		this.updateMenuSize ();
	
	if (this.element.style.display)
		return;
	
	this.updateViewOffset (true);
	
};

Portfolio.prototype.updateMenuSize = function () {
	const viewSize = this.viewSize;
	const menuElement = this.menuElement;
	
	if (menuElement.style.transform)
		menuElement.style.transform = "";
	
	const menuWidth = menuElement.offsetWidth;

	menuElement.style.overflow = "hidden";
	const layoutSpace = menuElement.offsetWidth;
	menuElement.style.overflow = "";
	
	// trace ("layout space", layoutSpace, menuWidth);
	
	if (menuWidth > layoutSpace) {
		const scaleFactor = layoutSpace / menuWidth;
		menuElement.style.transform = "scale(" + scaleFactor + ")";
		
	}
	
};

Portfolio.prototype.updateViewOffset = function (trackExpandedCollection) {
	const collections = this.collections;
	
	const viewSize = this.viewSize;
	
	const layoutSize = this.collectionViewSize = [
		viewSize [0],
		viewSize [1] + this.parent.lightbox.getOffsetBottom (this.slidePhase < 1)
		
	];
	
	const layoutHeight = layoutSize [1];
	const shouldPutToSleep = false;
	
	if (trackExpandedCollection) {
		const index = collections.indexOf (this.expandedCollection);
		if (index >= 0)
			this.viewOffset = index * layoutSize [1];
		
	}
	
	let cursor = -this.viewOffset;
	if (isNaN (cursor))
		return;
	
	for (let i = 0; i < collections.length; i++) {
		const collection = collections [i];
		
		const top = cursor;
		const bottom = top + layoutHeight;
		
		if (bottom <= 0 || top >= layoutSize [1]) {
			if (collection.isAwake !== false)
				collection.sleep ();
			
		} else {
			collection.element.style.transform = "translateY(" + top + "px)";
			collection.element.style.height = layoutHeight + 20 + "px";
			
			const progressBar = collection.progressBar;
			progressBar.element.style.transform = "translateY(" + -top * .9 + "px)";
			const delta = 1 - Math.abs (
				(top + bottom) / layoutHeight * 5
				
			);
			
			if (!collection.isInFullScreen)
				progressBar.setAlpha (delta + 5);
			
			collection.setViewSize (layoutSize);
			
			if (!collection.isAwake)
				collection.awake ();
			
		}
		
		cursor += layoutHeight;
		
	}
	
	this.fullHeight = cursor;
	
};

Portfolio.prototype.expand = function () {
	if (this.isExpanded)
		return;
	
	this.isExpanded = true;
	
	document.body.classList.add ("scroll-locked");
	const scrollingElement = document.scrollingElement;
	if (scrollingElement) {
		scrollingElement.style.overflow = "hidden";
		scrollingElement.style.touchAction = "none";
		
	}
	
	const navigation = this.navigation;
	const lightbox = this.parent.lightbox;
	
	// if (lightbox.images.length)
	//	lightbox.show (true);
	
	this.setAlpha (1);
	this.element.style.display = "";
	
	this.startAnimation ("Slide", {direction: 1, rate: Portfolio.TRANSITION_SPEED});
	
	this.renderInContext ();
	
};

Portfolio.prototype.collapse = function () {
	if (!this.isExpanded)
		return;
	
	this.isExpanded = false;
	
	document.body.classList.remove ("scroll-locked");
	const scrollingElement = document.scrollingElement;
	if (scrollingElement) {
		scrollingElement.style.overflow = "";
		scrollingElement.style.touchAction = "";
		
	}
	
	const navigation = this.navigation;
	const lightbox = this.parent.lightbox;
	
	// lightbox.hide ();
	
	this.updateMenuSize ();
	
	this.startAnimation ("Slide", {direction: 0, rate: Portfolio.TRANSITION_SPEED});
	
	window.location.hash = "";
	
};

Portfolio.prototype.animateSlide = function () {
	const state = this.updatedState ("Slide");
	this.slidePhase = state.phase;
	
	let t = 1 - state.phase;
	t = 1 - t * t;
	t = .5 - Math.cos (Math.PI * t) * .5;
	const t_ = 1 - t;
	
	const element = this.element;
	const mainElement = this.parent.mainElement;
	
	const lightbox = this.parent.lightbox;
	
	if (t == 0) {
		this.setAlpha (0);
		element.style.display = "none";
		lightbox.element.style.opacity = 0;
		
	} else if (t < 1) {
		const viewSize = this.viewSize;
		
		element.style.transform = "translateX(" +
			Math.round (viewSize [0] * t_) + "px)";
		
		this.parent.lightbox.element.style.transform = "translateX(" +
		   Math.round (viewSize [0] * t_) + "px)";
		
	} else {
		element.style.transform = "";
		
		mainElement.style.visibility = "hidden";
		
	}
	
	if (t > 0) {
		if (element.style.display)
			element.style.display = "";
		
		if (lightbox.element.style.opacity)
			lightbox.element.style.opacity = "";
		
	}
	
	if (t < 1) {
		if (mainElement.style.visibility)
			mainElement.style.visibility = "";
		
	} else {
		mainElement.style.visibility = "hidden";
		
	}
	
};

Portfolio.prototype.setIsInFullscreen = function (isInFullScreen) {
	if (this.isInFullScreen == isInFullScreen)
		return;
	
	this.isInFullScreen = isInFullScreen;
	
	const lightbox = this.parent.lightbox;
	lightbox.setIsDarkeningEnabled (isInFullScreen);
	
	const navigation = this.navigation;
	const titleBar = this.titleBar;
	
	if (isInFullScreen) {
		navigation.element.classList.add ("shape");
		navigation.fadeOut ();
		
		titleBar.element.classList.remove ("shape");
		titleBar.fadeIn ();
		
	} else {
		navigation.element.classList.remove ("shape");
		navigation.fadeIn ();
		
		titleBar.element.classList.add ("shape");
		titleBar.fadeOut ();
		
	}
	
};

//
// PortfolioNavigation extends AnimatableSprite
//

export const PortfolioNavigation = function (context) {
	AnimatableSprite.apply (this, arguments);
	
};

PortfolioNavigation.prototype = Object.create (AnimatableSprite.prototype);

PortfolioNavigation.prototype.takeElement = function (element) {
	this.element.parentNode.removeChild (this.element);
	this.element = element;
	
	element.style.display = "";
	
	const backButton = this.backButton = element.querySelector (".modal-back-button");
	backButton.addEventListener ("click", function (event) {
		this.parent.collapse ();
		
	}.bind (this));
	
	const upButton = this.upButton = element.querySelector (".modal-up-button");
	upButton.addEventListener ("click", function (event) {
		this.parent.advanceCollection (-1);
		
	}.bind (this));
	
	const downButton = this.downButton = element.querySelector (".modal-down-button");
	downButton.addEventListener ("click", function (event) {
		this.parent.advanceCollection (1);
		
	}.bind (this));
	
	const titleElement = this.titleElement = element.querySelector (".modal-title");
	
};

PortfolioNavigation.prototype.setTitle = function (title) {
	if (this.title == title)
		return;
	
	this.title = title;
	
	const titleElement = this.titleElement;
	
	titleElement.innerHTML = "";
	titleElement.appendChild (document.createTextNode (title));
	
};

//
// PortfolioProgressBar extends AnimatableSprite
//

const PortfolioProgressBar = function (context) {
	AnimatableSprite.apply (this, arguments);
	
	const element = this.element;
	element.classList.add ("portfolio-progress-bar");
	
	const completeBarElement = this.completeBarElement = document.createElement ("div");
	completeBarElement.classList.add ("complete-bar");
	
	element.appendChild (completeBarElement);
	
	this.addListener ("mousedown", this.beginDrag, this);
	
};

PortfolioProgressBar.prototype = Object.create (AnimatableSprite.prototype);

PortfolioProgressBar.prototype.setViewSize = function (viewSize) {
	this.viewSize = viewSize;
	
	const element = this.element;
	element.style.width = viewSize [0] + "px";
	
	const percentage = this.percentage;
	if (!isNaN (percentage))
		this.setPercentage (percentage, true);
	
};

PortfolioProgressBar.prototype.setPercentage = function (percentage, forceSet) {
	if (this.percentage == percentage && !forceSet)
		return;
	
	this.percentage = percentage;
	
	const viewSize = this.viewSize;
	const completeBarElement = this.completeBarElement;
	
	completeBarElement.style.width = Math.round (percentage * viewSize [0]) + "px";
	
};

PortfolioProgressBar.prototype.beginDrag = function (sender) {
	const event = sender.currentEvent;
	event.preventDefault ();
	event.stopPropagation ();
	
	const stage = this.getStage ();
	stage.addListener ("mousemove", this.doDrag, this);
	stage.addListener ("mouseup", this.endDrag, this);
	
	this.doDrag (sender);
	
};

PortfolioProgressBar.prototype.doDrag = function (sender) {
	const mouse = this.touchDescriptionForObject (sender.currentEvent);
	const viewSize = this.viewSize;
	
	const percentage = this.draggedPercentage = Math.max (0, Math.min (1,
		mouse [0] / viewSize [0]
		
	));
	
	// this.setPercentage (percentage);
	this.dispatchEvent ("change");
	
};

PortfolioProgressBar.prototype.endDrag = function (stage) {
	stage.removeListener ("mousemove", this.doDrag, this);
	stage.removeListener ("mouseup", this.endDrag, this);
	
};

//
// PortfolioTitleBar extends AnimatableSprite
//

const PortfolioTitleBar = function (context) {
	AnimatableSprite.apply (this, arguments);
	
};

PortfolioTitleBar.prototype = Object.create (AnimatableSprite.prototype);

PortfolioTitleBar.prototype.takeElement = function (element) {
	this.element.parentNode.removeChild (this.element);
	this.element = element;
	
	this.setAlpha (0);
	
	const closeButton = this.closeButton = element.querySelector (".modal-close-button");
	closeButton.addEventListener ("click", function (event) {
		this.parent.expandedCollection.leaveFullScreen ();
		
	}.bind (this));
	
	const descriptionElement = this.descriptionElement = element.querySelector (".modal-description");
	
};

PortfolioTitleBar.prototype.setDescription = function (description) {
	if (this.description == description)
		return;
	
	this.description = description;
	
	const descriptionElement = this.descriptionElement;
	
	descriptionElement.innerHTML = "";
	descriptionElement.appendChild (document.createTextNode (description));
	
};

//
// PortfolioCollection extends AnimatableSprite, SlidingSpritePrototype
//

const PortfolioCollection = function (context) {
	AnimatableSprite.apply (this, arguments);
	
};

PortfolioCollection.prototype = Object.create (AnimatableSprite.prototype);
PortfolioCollection.extendPrototype (SlidingSpritePrototype);

PortfolioCollection.prototype.toString = function () {
	return "[PortfolioCollection " + this.title + "]";
	
};

PortfolioCollection.prototype.renderSelfInContext = function () {};

PortfolioCollection.prototype.takeElement = function (element, titleOverride) {
	this.element.parentNode.removeChild (this.element);
	this.element = element;
	
	this.setAlpha (0);
	element.style.display = "none";
	
	this.id = element.getAttribute ("data-collection-id");
	
	const titleElement = this.titleElement = element.querySelector (".portfolio-collection-title");
	const title = this.title = (titleOverride || titleElement.textContent).trim ();
	
	const progressBar = this.progressBar = this.attachSprite (PortfolioProgressBar);
	progressBar.addListener ("change", this.changeProgressBar, this);
	
	const scrollContainer = this.scrollContainer = element.querySelector (".portfolio-collection-items");
	
	const items = this.items = new Array ();
	const itemMap = new Object ();
	const itemElements = element.querySelectorAll (".portfolio-collection-item");
	
	let maxItemHeight = 0;
	
	for (let i = 0; i < itemElements.length; i++) {
		const itemElement = itemElements [i];
		scrollContainer.appendChild (itemElement);
		
		const item = this.attachSprite (PortfolioItem);
		item.takeElement (itemElement);
		
		var itemSize = item.imageSize;
		var itemScaleFactor = item.scaleFactor;
		
		maxItemHeight = Math.max (maxItemHeight, itemSize [1]);
		
		item.addListener (Environment.IS_TOUCH_DEVICE ? "touchend" : "click", this.clickItem, this);
		
		item.order = -1;
		items.push (item);
		itemMap [item.imageModel.id] = item;
		
		if (PortfolioSection.IS_EDITABLE)
			item.addListener ("enableDrag", this.enableDragItem, this);
		
	}
	
	let imageIds = element.getAttribute ("data-image-list");
	if (imageIds) {
		imageIds = imageIds.split (",");
		
		for (let i = 0; i < imageIds.length; i++) {
			const imageId = imageIds [i];
			const item = itemMap [imageId];
			
			if (item)
				item.order = i;
			
		}
		
		items.sort (function (a, b) {
			return a.order > b.order ? 1 :
				a.order < b.order ? -1 : 0;
			
		});
		
	}
	
	const navigationElement = this.navigationElement = document.createElement ("div");
	navigationElement.classList.add ("portfolio-collection-navigation");
	navigationElement.style.display = "none";
	
	function newArrow (className) {
		const arrow = document.createElement ("div");
		arrow.classList.add ("portfolio-collection-navigation-arrow");
		arrow.classList.add ("portfolio-collection-navigation-arrow-" + className);
		
		navigationElement.appendChild (arrow);
		
		return arrow;
		
	}
	
	const arrowLeft = newArrow ("left");
	const arrowRight = newArrow ("right");
	
	const navigationArrows = this.navigationArrows = Array.prototype.slice.call (navigationElement.children);
	
	element.appendChild (navigationElement);
	
	this.maxItemHeight = maxItemHeight;
	
	this.setUpScrolling ();
	
};

PortfolioCollection.prototype.renderSelfInContext = function () {};

PortfolioCollection.prototype.awake = function () {
	this.isAwake = true;
	
	// trace (this, "awakening");
	const element = this.element;
	element.style.display = "";
	this.setAlpha (1);
	
	if (this.needsUpdateViewOffset)
		this.updateViewOffset ();
	
};

PortfolioCollection.prototype.sleep = function () {
	this.isAwake = false;
	
	// trace (this, "going to sleep");
	const element = this.element;
	element.style.display = "none";
	this.setAlpha (0);
	
};

PortfolioCollection.prototype.clickItem = function (item) {
	const scrollingController = this.scrollingController;
	if (scrollingController.didDrag)
		return;
	
	if (this.isInFullScreen) {
		if (this.clickedItem == item) {
			this.leaveFullScreen ();
			
		} else {
			this.scrollToItem (item, true);
			
		}
		
	} else if (!PortfolioSection.IS_EDITABLE) {
		this.clickedItem = item;
		
		this.enterFullScreen ();
		
	}
	
};

PortfolioCollection.prototype.getListenerTargetForScrollingController = function () {
	return this.element;
	
};

PortfolioCollection.prototype.setUpScrolling = function () { 
	SlidingSpritePrototype.call (this, this.context);
	
	var element = this.element;
	var scrollingController = this.scrollingController;
	
	scrollingController.getMouseListenerTarget = function () {
		return element;
		
	};
	
	scrollingController.defaultTouchDirectionLock = ScrollingController.TOUCH_MOVE_DIRECTION_HORIZONTAL

	scrollingController.awake ();
	scrollingController.addWheelListener ();
	scrollingController.cancelHorizontalScroll = true;
	
	scrollingController.addListener ("beginDrag", this.beginDrag, this);
	scrollingController.addListener ("dragHorizontal", this.dragHorizontal, this);
	scrollingController.addListener ("cancelDragHorizontal", this.endDragHorizontal, this);
	scrollingController.addListener ("endDrag", this.endDragHorizontal, this);
	
	scrollingController.addListener ("scrollHorizontal", this.scrollHorizontal, this);
	scrollingController.addListener ("cancelScrollHorizontal", this.cancelScrollHorizontal, this);
	
	const navigationArrows = this.navigationArrows;
	
	var arrowDownHandler = function (event) {
		event.stopPropagation ();
		event.preventDefault ();
		
		if (this.isInFullScreen)
			return;
		
		var navigationArrow = event.currentTarget;
		var direction = navigationArrow.direction;
		
		this.beginHoldArrowInDirection (direction);
		
	}.bind (this);
	
	var arrowClickHandler = function (event) {
		if (!this.isInFullScreen)
			return;
		
		event.stopPropagation ();
		event.preventDefault ();
		
		var navigationArrow = event.currentTarget;
		var direction = navigationArrow.direction;
		
		this.advanceInDirection (direction, true);
		
	}.bind (this);
	
	for (var i = navigationArrows.length; i--;) {
		var navigationArrow = navigationArrows [i];
		navigationArrow.direction = (i - .5) * 2;
		
		navigationArrow.addEventListener ("touchstart", arrowDownHandler);
		navigationArrow.addEventListener ("mousedown", arrowDownHandler);
		
		navigationArrow.addEventListener ("click", arrowClickHandler);
		
	}
	
};

PortfolioCollection.prototype.beginDrag = function (scrollingController) {
	SlidingSpritePrototype.beginDrag.apply (this, arguments);
	
	scrollingController.currentEvent.preventDefault ();
	
};

PortfolioCollection.prototype.getMaxScrollOffset = function () {
	return this.maxScrollOffset || 0;
	
};

PortfolioCollection.prototype.boundedViewOffset = function (viewOffset) {
	const items = this.items;
	
	const viewSize = this.viewSize;
	const paddingLeft = SiteSection.paddingLeft;
	
	const minVO = Math.round ((items [0].viewSize [0] - viewSize [0] + 2 * paddingLeft) * .5);
	
	return Math.max (
		minVO,
			Math.min (
				minVO + this.getMaxScrollOffset (),
				viewOffset
				
			)
			
		);
	
};

PortfolioCollection.prototype.dragHorizontal = function (scrollingController) {
	var deltaX = scrollingController.delta.x;
	this.removeRunLoopHandler ("processSliding");
	
	this.didReleaseDrag = true;
	this.trackCurrentViewOffset = true;
	
	if (!this.checkIfShouldAdvance ()) {
		this.didReleaseDrag = false;
		
		this.viewOffset = this.currentViewOffset = this.startViewOffset + deltaX;
		this.updateViewOffset ();
		
	}
	
};

PortfolioCollection.prototype.endDragHorizontal = function (scrollingController) {
	const didFlick = scrollingController.didFlickHorizontal;
	if (this.isInFullScreen) {
		if (!this.didReleaseDrag) {
			if (didFlick) {
				if (Math.abs (scrollingController.flickDeltaX * 1.4) > 20) {
					if (!this.advanceInDirection (scrollingController.flickDeltaX > 0 ? 1 : -1))
						this.snapToCurrentItem ();
					
				} else {
					this.snapToCurrentItem ();
					
				}
				
			} else {
				this.snapToCurrentItem ();
				
			}
			
		}
		
	} else {
		if (didFlick) {
			const targetViewOffset = this.viewOffset = this.viewOffset + scrollingController.flickDeltaX * 1.4;
			
			this.slidingInertia = .9;
			this.addRunLoopHandler ("processSliding");
			
		} else {
			this.snapToBounds ();
			
		}
		
	}
	
};

PortfolioCollection.prototype.scrollHorizontal = function (scrollingController) {
	if (scrollingController.isDragging)
		return;
	
	if (this.isWheelLocked)
		return;
	
	if (new Date ().getTime () < this.scrollTimeOut)
		return;
	
	this.trackCurrentViewOffset = true;
	
	var delta = scrollingController.delta.x;
	const targetViewOffset = this.viewOffset + delta * 1.5;
	
	if (!this.isBumping) {
		if (!(this.bumpLockDirection * scrollingController.delta.x > 0)) {
			this.bumpLockDirection = 0;
			
			this.viewOffset = targetViewOffset;
			this.slidingInertia = .7;
			
			this.addRunLoopHandler ("processSliding");
			
			this.updateViewOffset ();
			
		}
		
	}
	
	this.checkIfShouldAdvance ();
	
};

PortfolioCollection.prototype.cancelScrollHorizontal = function (scrollingController) {
	if (this.isInFullScreen && !scrollingController.isDragging)
		this.snapToCurrentItem ();
	
};

PortfolioCollection.prototype.beginHoldArrowInDirection = function (direction) {
	this.getStage ().addListener ("mouseup", this.endHoldArrow, this);
	
	this.holdArrowSpeed = 2;
	this.holdArrowDirection = direction;
	
	this.addRunLoopHandler ("processHoldArrow");
	this.processHoldArrow ();
	
};

PortfolioCollection.prototype.processHoldArrow = function () {
	var framesDelta = this.context.animationTimer.framesDelta;
	
	var direction = this.holdArrowDirection;
	var scrollSpeed = this.holdArrowSpeed = Math.min (10, this.holdArrowSpeed + .6 * framesDelta);
	
	var viewOffset = this.viewOffset + scrollSpeed * framesDelta * direction;
	
	if (viewOffset == this.boundedViewOffset (viewOffset)) {
		this.viewOffset = viewOffset;
		
		this.slidingInertia = .9;
		this.addRunLoopHandler ("processSliding");
		
	} else {
		this.viewOffset = (viewOffset + this.boundedViewOffset (viewOffset)) * .5 + scrollSpeed * 6 * this.context.animationTimer.framesDelta * direction;
		
		this.slidingInertia = .95;
		this.addRunLoopHandler ("processSliding");
		
		this.endHoldArrow ();
		
	}
	
	this.checkIfShouldAdvance ();
	
};

PortfolioCollection.prototype.endHoldArrow = function (stage) {
	this.getStage ().removeListener ("mouseup", this.endHoldArrow, this);
	
	this.removeRunLoopHandler ("processHoldArrow");
	
};

PortfolioCollection.prototype.updateViewOffset = function () {
	if (!this.isAwake) {
		this.needsUpdateViewOffset = true;
		return;
		
	}
	
	if (isNaN (this.maxScrollOffset))
		return;
	
	this.needsUpdateViewOffset = false;
	
	let viewOffset = this.currentViewOffset;
	viewOffset = (viewOffset + 1 * this.boundedViewOffset (viewOffset)) / 2;
	
	if (isNaN (viewOffset))
		debugger;
	
	
	const delta = viewOffset - this.lastViewOffset;
	const draggingItem = this.draggingItem;
	
	if (draggingItem && delta) {
		this.itemStartPos [0] += delta;
		
		this.doDragItem ();
		
	}
	this.lastViewOffset = viewOffset;
	
	
	const effectiveShift = this.effectiveShift = -Math.round (viewOffset);
	
	const scrollContainer = this.scrollContainer;
	scrollContainer.style.transform = "translateX(" + effectiveShift + "px)";
	
	function enableArrow (arrow, doEnable) {
		if (doEnable) {
			if (arrow.classList.contains ("disabled"))
				arrow.classList.remove ("disabled");
			
		} else {
			if (!arrow.classList.contains ("disabled"))
				arrow.classList.add ("disabled");
			
		}
		
	}
	
	const navigationArrows = this.navigationArrows;
	viewOffset = this.viewOffset;
	
	const bounceBounds = 5;
	
	enableArrow (navigationArrows [0], viewOffset > this.boundedViewOffset (Number.NEGATIVE_INFINITY) + bounceBounds);
	enableArrow (navigationArrows [1], viewOffset < this.boundedViewOffset (Number.POSITIVE_INFINITY) - bounceBounds);
	
	if (!this.mayAdvance && Math.abs (this.viewOffset - this.currentViewOffset) < 100) {
		const items = this.items;
		const currentIndex = this.getCurrentItemIndex ();
		const currentItem = items [currentIndex];
		
		const viewSize = this.viewSize;
		
		const delta = currentItem.position [0] + currentItem.viewSize [0] * .5 - viewOffset - viewSize [0] * .5 + SiteSection.paddingLeft;
		
		if (Math.abs (delta) <= 100)
			this.mayAdvance = true;
		
	}
	
	// if (!this.draggingItem)
		this.applyLayout (undefined, false, !!this.draggingItem);
	
	if (this.isDarkeningEnabled)
		this.updateEnabledItemOverlay ();
	
	const parent = this.parent;
	
	if (this.isInFullScreen) {
		const currentItem = this.clickedItem;
		currentItem.setResolution (PortfolioItem.RESOLUTION_HIGH_RES);
		
		parent.titleBar.setDescription (currentItem.imageModel.title);
		
	}
	
	if (this.fullScreenPhase == 0 && parent.expandedCollection == this) {
		const minVO = this.boundedViewOffset (Number.NEGATIVE_INFINITY);
		const maxVO = this.boundedViewOffset (Number.POSITIVE_INFINITY);
		
		this.progressBar.setPercentage (Math.max (0, Math.min (1,
			((this.trackCurrentViewOffset ? this.currentViewOffset : this.viewOffset) - minVO) /
				(maxVO - minVO)
			
		)));
		
	}
	
};

PortfolioCollection.prototype.checkIfShouldAdvance = function () {
	if (!this.isInFullScreen)
		return;
	
	if (!this.mayAdvance)
		return;
	
	const items = this.items;
	const currentIndex = this.getCurrentItemIndex ();
	const currentItem = items [currentIndex];
	
	const viewSize = this.viewSize;
	const viewOffset = this.currentViewOffset;
	
	const delta = currentItem.position [0] + currentItem.viewSize [0] * .5 - viewOffset - viewSize [0] * .5 + SiteSection.paddingLeft;
	
	if (Math.abs (delta) > 100)
		return this.advanceInDirection (delta > 0 ? -1 : 1);
	
};

PortfolioCollection.prototype.getCurrentItemIndex = function () {
	const viewSize = this.viewSize;
	const paddingLeft = SiteSection.paddingLeft;
	const viewOffset = this.currentViewOffset;
	
	const items = this.items;
	
	for (var i = 0; i < items.length; i++) {
		const item = items [i];
		if (item.position [0] + item.viewSize [0] * 0 - viewOffset >= viewSize [0] * .5 - paddingLeft)
			break;
		
	}
	
	return Math.max (0, Math.min (items.length - 1, i - 1));
	
};

PortfolioCollection.prototype.scrollToItem = function (targetItem, softScroll) {
	this.clickedItem = targetItem;
	
	const viewSize = this.viewSize;
	const paddingLeft = SiteSection.paddingLeft;
	const targetPosition = this.viewOffset = targetItem.position [0] + targetItem.viewSize [0] * .5 - viewSize [0] * .5 + paddingLeft;
	
	if (softScroll) {
		this.slidingInertia = .85;
		this.maxScrollSpeed = 4;
		this.accelerationDescription = undefined;
		
	} else {
		this.slidingInertia = .9;
		
	}
	
	this.addRunLoopHandler ("processSliding");
	
};

PortfolioCollection.prototype.advanceInDirection = function (delta, softScroll) {
	const items = this.items;
	const currentIndex = this.getCurrentItemIndex ();
	
	if (delta * (this.currentViewOffset - this.viewOffset) > 0)
		return;
	
	const targetIndex = currentIndex + delta;
	if (targetIndex < 0 || targetIndex >= items.length)
		return;
	
	if (delta) {
		this.releaseDrag ();
		this.scrollTimeOut = new Date ().getTime () + 500;
		// this.isBumping = true;
		// this.bumpLockDirection = delta;
		
		this.mayAdvance = false;
		
	}
	
	const targetItem = items [targetIndex];
	this.scrollToItem (targetItem, softScroll);
	
	return true;
	
};

PortfolioCollection.prototype.snapToCurrentItem = function () {
	this.advanceInDirection (0);
	
};

PortfolioCollection.prototype.setViewSize = function (viewSize) {
	const lastViewSize = this.viewSize;
	
	if (lastViewSize &&
		lastViewSize [0] == viewSize [0] &&
		lastViewSize [1] == viewSize [1]) {
		return;
		
	}
	
	/*
	if (!this.isAwake) {
		this.viewSize = viewSize;
		return;
		
	}
	*/
	
	let lastPercentage;
	if (lastViewSize) {
		const minVO = this.boundedViewOffset (Number.NEGATIVE_INFINITY);
		const maxVO = this.boundedViewOffset (Number.POSITIVE_INFINITY);
		
		lastPercentage = Math.max (0, Math.min (1,
			(this.currentViewOffset - minVO) / (maxVO - minVO)
			
		));
		
	}
	
	this.viewSize = viewSize;
	
	const progressBarSpace = this.progressBarSpace = Math.max (25, Math.min (viewSize [1] < 600 ? 75 * 2 : 90 * 2,
		viewSize [1] * .1
		
	));
	
	const progressBar = this.progressBar;
	progressBar.setViewSize ([
		Math.min (660, viewSize [0] * .5),
		1
		
	]);
	
	const lightbox = this.parent.parent.lightbox;
	
	progressBar.setPosition (
		(viewSize [0] - progressBar.viewSize [0]) * .5,
		viewSize [1] - progressBarSpace * (1 + (1 - lightbox.slidePhase) * .25)
		
	);
	
	if (this.isInFullScreen) {
		this.targetItem = this.clickedItem;
		this.updateLayout (1);
		
	} else {
		this.targetItem = undefined;
		this.updateLayout (0, true);
		
		if (lastViewSize) {
			const minVO = this.boundedViewOffset (Number.NEGATIVE_INFINITY);
			const maxVO = this.boundedViewOffset (Number.POSITIVE_INFINITY);
			
			this.viewOffset = this.currentViewOffset = lastPercentage * (maxVO - minVO) + minVO;
			
		} else {
			this.viewOffset = this.currentViewOffset = this.boundedViewOffset (Number.NEGATIVE_INFINITY);
			
		}
		this.updateViewOffset ();
		
	}
	
};

PortfolioCollection.prototype.getLayoutForExpansionPhase = function (fullScreenPhase) {
	const layout = new Array ();
	
	const viewSize = this.viewSize;
	
	const u = fullScreenPhase || 0;
	const u_ = 1 - u;
	
	const isMobile = viewSize [0] <= 767;
	
	
	const height = layout.height = viewSize [1];
	const paddingLeft = SiteSection.paddingLeft;
	
	const expandedPaddingTop = viewSize [0] > 767 ? 121 : 74; // 90; // 118;
	const expandedPaddingBottom = this.progressBarSpace * 2;
	
	/*
	const expandedScaleFactor = Math.max (.1, (viewSize [1] - expandedPaddingTop - expandedPaddingBottom) /
		this.maxItemHeight);
	*/
	const expandedScaleFactor = Math.max (.1, (viewSize [1] - expandedPaddingTop - expandedPaddingBottom) /
		viewSize [1]);
	const expandedItemSpacing = Math.max (50, Math.min (expandedScaleFactor * 480, isMobile ? 50 : 158));
	
	const fullScreenItemSpacing = isMobile ? 30 : 40;
	
	const itemSpacing = fullScreenItemSpacing * u + expandedItemSpacing * u_;
	
	const expandedDotOffset = isMobile ? 25 : 30;
	
	const items = this.items;
	let cursor = 0;
	
	const fullScreenPaddingTop = isMobile ? 77 : 121;
	const fullScreenPaddingBottom = itemSpacing;
	
	for (let i = 0; i < items.length; i++) {
		const item = items [i];
		const imageSize = item.imageSize;
		const itemScaleFactor = item.scaleFactor;
		
		if (cursor)
			cursor += itemSpacing;
		
		const expandedScaleFactor = Math.max (.1, (viewSize [1] - expandedPaddingTop - expandedPaddingBottom) /
			imageSize [1]);
		
		const expandedItemSize = [
			Math.floor (imageSize [0] * expandedScaleFactor * itemScaleFactor),
			Math.floor (imageSize [1] * expandedScaleFactor * itemScaleFactor)
			
		];
		
		const fullScreenScale = (viewSize [1] - fullScreenPaddingTop - fullScreenPaddingBottom) / imageSize [1];
		
		const fullScreenItemSize = [
			Math.ceil (imageSize [0] * fullScreenScale),
			Math.floor (imageSize [1] * fullScreenScale)
			
		];
		
		const itemSize = [
			expandedItemSize [0] * u_ + fullScreenItemSize [0] * u,
			expandedItemSize [1] * u_ + fullScreenItemSize [1] * u
			
		];
		
		const itemPosition = [
			cursor,
			((height - expandedItemSize [1] - expandedPaddingTop - expandedPaddingBottom) * .6 + expandedPaddingTop) * u_ +
				u * fullScreenPaddingTop
			
		];
		
		const fullScreenDotOffset = Math.max (
			expandedDotOffset,
			(fullScreenItemSize [0] - viewSize [0]) * .5 + SiteSection.paddingLeft
			
		);
		
		layout.push ({
			size: itemSize,
			position: itemPosition,
			dotOffset: expandedDotOffset * u_ + fullScreenDotOffset * u
			
		});
		
		cursor += itemSize [0];
		
	}
	
	layout.width = cursor;
	return layout;
	
};

PortfolioCollection.prototype.applyLayout = function (layout, visibilityOnly, animated) {
	if (layout) {
		this.lastLayout = layout;
		
	} else {
		layout = this.lastLayout;
		if (!layout)
			return;
		
	}
	
	const items = this.items;
	
	const viewSize = this.viewSize;
	const viewOffset = this.effectiveShift || 0;
	
	const paddingLeft = SiteSection.paddingLeft;
	
	const draggingItem = this.draggingItem;
	
	for (let i = 0; i < items.length; i++) {
		const item = items [i];
		if (item == draggingItem)
			continue;
		
		const itemLayout = layout [i];
		
		const left = itemLayout.position [0] + viewOffset + paddingLeft;
		const right = left + itemLayout.size [0];
		
		if (left > viewSize [0] || right < 0) {
			item.position = itemLayout.position;
			item.targetPosition = itemLayout.position;
			item.viewSize = itemLayout.size;
			
			if (item.isAwake !== false)
				item.sleep ();
			
		} else {
			if (!item.isAwake)
				item.awake ();
			
			item.selectionDot.style.transform = "translate3D(" + itemLayout.dotOffset + "px, 0, 0)";
			
			if (!visibilityOnly) {
				if (animated) {
					item.slideTo (itemLayout.position, .5);
					
				} else {
					item.setPosition (itemLayout.position);
					item.targetPosition = itemLayout.position;
					
				}
				item.setViewSize (itemLayout.size);
				
			}
			
		}
		
	}
	
};

PortfolioCollection.prototype.updateLayout = function (fullScreenPhase, skipUpdateViewOffset, animated) {
	this.fullScreenPhase = fullScreenPhase;
	const viewSize = this.viewSize;
	
	const layout = this.getLayoutForExpansionPhase (fullScreenPhase); 
	const layoutHeight = this.layoutHeight = layout.height;
	const layoutWidth = this.layoutWidth = layout.width;
	
	const paddingLeft = SiteSection.paddingLeft;
	
	this.maxScrollOffset = layoutWidth - layout [0].size [0] * .5 - layout [layout.length - 1].size [0] * .5;
	
	this.applyLayout (layout, false, animated);
	
	if (skipUpdateViewOffset)
		return;
	
	const isInFullScreen = !isNaN (fullScreenPhase);
	const targetItem = this.targetItem;
	
	const u = fullScreenPhase;
	const u_ = 1 - u;
	
	if (targetItem) {
		const targetItemCenterStart = this.targetItemCenterStart ;
		const targetItemCenterEnd = viewSize [0] * .5 - paddingLeft;
		
		const targetItemCenter = targetItemCenterStart * u_ + targetItemCenterEnd * u;
		
		this.viewOffset = this.currentViewOffset = targetItem.position [0] + targetItem.viewSize [0] * .5 - targetItemCenter
		
		this.updateViewOffset ();
		
	} else {
		const layoutWidth = viewSize [0] - 2 * paddingLeft;
		
		const collapseStartLeft = this.collapseStartLeft;
		const collapseStartRight = this.collapseStartRight;
		
		const collapseStartCenter = layoutWidth * .5 - collapseStartLeft;
		const collapseStartPercentage =
			(collapseStartCenter) /
			(collapseStartRight - collapseStartLeft);
		
		if (isNaN (collapseStartPercentage))
			return;
		
		let collapseEndLeft = this.collapseEndLeft;
		const collapseEndRight = this.collapseEndRight;
		
		const collapseEndWidth = collapseEndRight - collapseEndLeft;
		collapseEndLeft = collapseStartPercentage * collapseEndWidth - layoutWidth * .5;
		
		if (isInFullScreen) {
			collapseEndLeft = this.collapseEndLeft -
				(collapseStartPercentage * (collapseEndRight - this.collapseEndLeft) - layoutWidth * .5);
			
		}
		
		this.viewOffset = this.currentViewOffset =
			collapseStartPercentage * layout.width - layoutWidth * .5 + collapseEndLeft * u_;
		
		this.updateViewOffset ();
		
	}
	
};

PortfolioCollection.prototype.getCurrentPercentage = function () {
	const viewSize = this.viewSize;
	const paddingLeft = SiteSection.paddingLeft;
	const layoutWidth = this.layoutWidth;
	
	const currentViewOffset = this.currentViewOffset;
	const layoutSpace = layoutWidth - viewSize [0] + 2 * paddingLeft;
	
	const currentPercentage =
		currentViewOffset / layoutSpace;
	
	return currentPercentage;
	
};

PortfolioCollection.prototype.setTargetPercentage = function (targetPercentage) {
	this.trackCurrentViewOffset = false;
	
	const minVO = this.boundedViewOffset (Number.NEGATIVE_INFINITY);
	const maxVO = this.boundedViewOffset (Number.POSITIVE_INFINITY);
	
	this.viewOffset = targetPercentage * (maxVO - minVO) + minVO;
	this.slidingInertia = .9;
	this.addRunLoopHandler ("processSliding");
	
	
};

PortfolioCollection.prototype.changeProgressBar = function (progressBar) {
	this.setTargetPercentage (progressBar.draggedPercentage);
	
};

PortfolioCollection.prototype.enterFullScreen = function () {
	if (this.isInFullScreen)
		return;
	this.isInFullScreen = true;
	
	const targetItem = this.targetItem = this.clickedItem;
	
	this.targetItemCenterStart = targetItem.position [0] + targetItem.viewSize [0] * .5 - this.currentViewOffset;
	
	this.setIsDarkeningEnabled (true);
	
	this.startAnimation ("FullScreen", {direction: 1, rate: Portfolio.TRANSITION_SPEED});
	
	const portfolio = this.parent;
	portfolio.setIsInFullscreen (true);
	
};

PortfolioCollection.prototype.leaveFullScreen = function () {
	if (!this.isInFullScreen)
		return;
	this.isInFullScreen = false;
	
	const currentViewOffset = this.currentViewOffset;
	
	const viewSize = this.viewSize;
	const paddingLeft = SiteSection.paddingLeft;
	
	const currentPercentage = this.getCurrentPercentage ();
	
	const layout = this.getLayoutForExpansionPhase (0);
	this.startViewOffset = Math.max (0, (layout.width - viewSize [0] + 2 * paddingLeft) * currentPercentage);
	
	const collapseStartLeft = this.collapseStartLeft = -currentViewOffset;
	const collapseStartRight = this.collapseStartRight = collapseStartLeft + this.layoutWidth;
	
	const targetIndex = this.items.indexOf (this.clickedItem);
	const collapseEndLeft = this.collapseEndLeft = layout [targetIndex].position [0] - (viewSize [0] - layout [targetIndex].size [0]) * .5 + paddingLeft;
	
	const collapseEndRight = this.collapseEndRight = collapseEndLeft + layout.width;
	
	this.targetItem = undefined;
	
	this.setIsDarkeningEnabled (false);

	const items = this.items;
	for (let i = items.length; i--;) {
		const item = items [i];
		item.setResolution (PortfolioItem.RESOLUTION_LOW_RES);
		
	}
	
	this.startAnimation ("FullScreen", {direction: 0, rate: Portfolio.TRANSITION_SPEED});
	
	const portfolio = this.parent;
	portfolio.setIsInFullscreen (false);
	
};

PortfolioCollection.prototype.animateFullScreen = function () {
	const state = this.updatedState ("FullScreen");
	let t = state.phase;
	t = .5 - Math.cos (Math.PI * t) * .5;
	
	this.updateLayout (t);
	
	const progressBar = this.progressBar;
	progressBar.setAlpha (1 - t * 5);
	
};

PortfolioCollection.prototype.setIsDarkeningEnabled = function (isDarkeningEnabled) {
	if (this.isDarkeningEnabled == isDarkeningEnabled)
		return;
	
	this.isDarkeningEnabled = isDarkeningEnabled;
	// trace ("-- enable darkening --", isDarkeningEnabled);
	
	this.updateEnabledItemOverlay ();
	
	const element = this.element;
	if (isDarkeningEnabled)
		element.classList.add ("darkened");
	else
		element.classList.remove ("darkened");
	
};

PortfolioCollection.prototype.updateEnabledItemOverlay = function () {
	const items = this.items;
	const currentItem = this.clickedItem;
	
	const isDarkeningEnabled = this.isDarkeningEnabled;
	
	for (let i = items.length; i--;) {
		const item = items [i];
		item.setIsDarkened (item != currentItem && isDarkeningEnabled);
		
	}
	
};

PortfolioCollection.prototype.zIndexDepth = 1;

PortfolioCollection.prototype.enableDragItem = function (draggingItem) {
	this.draggingItem = draggingItem;
	draggingItem.element.classList.add ("dragging");
	draggingItem.element.style.zIndex = this.zIndexDepth++;
	
	this.releaseDrag ();
	this.snapToBounds ();
	
	var stage = this.getStage ();
	stage.addListener ("mousemove", this.doDragItem, this);
	stage.addListener ("mouseup", this.endDragItem, this);
	
	const itemStartMouse = this.itemStartMouse = this.lastMouse = draggingItem.currentMouse;
	this.itemStartPos = draggingItem.position.concat ();
	
	this.addRunLoopHandler ("trackDragScroll");
	
	this.renderInContext ();
	
};

PortfolioCollection.prototype.doDragItem = function (sender) {
	// trace ("do drag");
	
	const itemStartMouse = this.itemStartMouse;
	const mouse = sender ? this.touchDescriptionForObject (sender.currentEvent) : this.lastMouse;
	this.lastMouse = mouse;
	
	const delta = [
		mouse [0] - itemStartMouse [0],
		mouse [1] - itemStartMouse [1]
		
	];
	
	const draggingItem = this.draggingItem;
	const itemStartPos = this.itemStartPos;
	
	draggingItem.setPosition (
		itemStartPos [0] + delta [0],
		itemStartPos [1]
		
	);
	
	const items = this.items;
	const sourceIndex = items.indexOf (draggingItem);
	
	
	
	
	
	const xOffset = draggingItem.position [0];
	
	let targetIndex = 0;
	
	for (let i = items.length; i--;) {
		const item = items [i];
		if (item == draggingItem)
			continue;
		
		const itemX = item.targetPosition [0] + item.viewSize [0] * .5;
		
		if (itemX < xOffset + (sourceIndex < i ? draggingItem.viewSize [0] : 0)) {
			targetIndex = i + 1;
			break;
			
		}
		
	}
	
	if (sourceIndex != targetIndex) {
		items.splice (sourceIndex, 1);
		items.splice (targetIndex + (sourceIndex > targetIndex ? 0 : -1), 0, draggingItem);
		
		this.updateLayout (0, true, true);
		
	}
	
	this.renderInContext ();
	
};

PortfolioCollection.prototype.endDragItem = function (sender) {
	var stage = this.getStage ();
	stage.removeListener ("mousemove", this.doDragItem, this);
	stage.removeListener ("mouseup", this.endDragItem, this);
	
	const draggingItem = this.draggingItem;
	draggingItem.element.classList.remove ("dragging");
	
	this.draggingItem = undefined;
	this.updateLayout (0, true, true);
	
	this.removeRunLoopHandler ("trackDragScroll");
	
	
	// trace ("--- save configuration ---");
	
	const parameters = new Object ();
	const imageList = parameters.images = new Array ();
	
	const items = this.items;
	for (let i = 0; i < items.length; i++) {
		const item = items [i];
		imageList.push (item.imageModel.id);
		
	}
	
	parameters.id = this.id;
	
	const storageURL = "/api/collection-images/store";
	
	const loader = LoaderSystem.getSharedInstance ();
	const job = loader.jobForPathOfClass (
		storageURL,
		JSONLoaderJob,
		true
		
	);
	
	job.method = "POST";
	job.parameters = {
		collectionId: this.id,
		imageList: imageList.join (",")
		
	};
	
	if (storageURL) {
		loader.enqueueJob (job);
		
	} else {
		const serial = JSON.stringify (job.parameters);
		window.open ("data:application/json," + serial);
		
	}
	
};

PortfolioCollection.prototype.trackDragScroll = function () {
	const draggingItem = this.draggingItem;
	const viewSize = this.viewSize;
	
	const xOff = this.lastMouse [0];
	
	const dragSpeed = xOff < viewSize [0] * .5 ?
		-Math.max (0, 7.5 - xOff * .03) :
		Math.max (0, 7.5 - (viewSize [0] - xOff) * .03);
	
	if (dragSpeed) {
		this.viewOffset = this.boundedViewOffset (this.viewOffset + dragSpeed * 4);
		
		this.slidingInertia = .9;
		this.addRunLoopHandler ("processSliding");
		
	}
	
};

//
// PortfolioItem extends AnimatableSprite
//

const PortfolioItem = function (context) {
	AnimatableSprite.apply (this, arguments);
	
	this.renderUsingCSSTransform = true;
	// this.renderAsLayer = true;
	
};

PortfolioItem.prototype = Object.create (AnimatableSprite.prototype);

PortfolioItem.prototype.toString = function () {
	return "[PortfolioItem " + this.imageModel + "]";
	
};

PortfolioItem.prototype.takeElement = function (element) {
	this.element.parentNode.removeChild (this.element);
	this.element = element;
	
	this.setAlpha (0);
	
	const imageModel = this.imageModel = new ImageModel ();
	imageModel.parseCollectionItem (element);
	imageModel.addListener ("changeIsSelected", this.changeIsSelected, this);
	
	const imageElement = this.imageElement = element.querySelector (".portfolio-collection-item-image");
	const picture = this.picture = imageElement.querySelector ("picture");
	if (picture)
		picture.classList.add ("lazyload--started");
	
	const image = this.image = imageElement.querySelector ("img");
	image.src = imageModel.getPreviewImagePath ();
	
	const highResImage = this.highResImage = document.createElement ("img");
	highResImage.style.display = "none";
	imageElement.appendChild (highResImage);
	
	const scaleFactor = this.scaleFactor = parseFloat (
		imageElement.getAttribute ("data-scale-factor")
		
	) || 1;
	const imageSize = this.imageSize = imageModel.size;
	
	const decriptionElement = this.decriptionElement = element.querySelector (".portfolio-collection-item-decription");
	decriptionElement.style.display = "none";
	
	const selectionDot = this.selectionDot = document.createElement ("div");
	selectionDot.classList.add ("selection-dot");
	
	selectionDot.addEventListener (Environment.IS_TOUCH_DEVICE ? "touchend" : "click", this.clickSelectionDot.bind (this));
	element.appendChild (selectionDot);
	
	
	if (PortfolioSection.IS_EDITABLE) {
		const scalingDot = this.scalingDot = document.createElement ("div");
		scalingDot.classList.add ("scaling-dot");
		
		scalingDot.addEventListener ("mousedown", this.beginScale.bind (this));
		scalingDot.addEventListener ("click", function (event) {
			event.stopPropagation ();
			
		});
		
		element.appendChild (scalingDot);
		
		this.addListener ("mousedown", this.mouseDown, this);
		
	}
	
	const overlayElement = this.overlayElement = document.createElement ("div");
	overlayElement.classList.add ("image-overlay");
	overlayElement.style.display = "none";
	element.appendChild (overlayElement);
	
};

PortfolioItem.RESOLUTION_LOW_RES = "low-res";
PortfolioItem.RESOLUTION_HIGH_RES = "high-res";

PortfolioItem.prototype.setResolution = function (resolution) {
	var lastResolution = this.resolution;
	if (lastResolution == resolution)
		return;
	
	this.resolution = resolution;
	
	switch (lastResolution) {
		case PortfolioItem.RESOLUTION_LOW_RES:
			break;
		case PortfolioItem.RESOLUTION_HIGH_RES:
			this.hideHighResImage ();
			break;
		
	}
	
	switch (resolution) {
		case PortfolioItem.RESOLUTION_LOW_RES:
			break;
		case PortfolioItem.RESOLUTION_HIGH_RES:
			this.showHighResImage ();
			break;
		
	}
	
};

PortfolioItem.prototype.showHighResImage = function () {
	if (!this.didStartLoadingHighResImage) {
		this.didStartLoadingHighResImage = true;
		
		const highResImage = this.highResImage;
		highResImage.src = this.imageModel.src;
		
		if (highResImage.complete)
			this.completeHighResImage ();
		else
			highResImage.addEventListener ("load", this.completeHighResImage.bind (this));
		
	} else {
		this.completeHighResImage ();
		
	}
	
};

PortfolioItem.prototype.completeHighResImage = function () {
	const highResImage = this.highResImage;
	
	if (this.resolution == PortfolioItem.RESOLUTION_HIGH_RES) {
		this.startAnimation ("FadeHighResImage", {direction: 1, rate: Portfolio.TRANSITION_SPEED * 2});
		
	}
	
};

PortfolioItem.prototype.hideHighResImage = function () {
	this.startAnimation ("FadeHighResImage", {direction: 0, rate: Portfolio.TRANSITION_SPEED * 2});
	
};

PortfolioItem.prototype.animateFadeHighResImage = function () {
	const state = this.updatedState ("FadeHighResImage");
	let t = state.phase;
	
	const image = this.image;
	const highResImage = this.highResImage;
	
	if (t) {
		highResImage.style.opacity = t;
		highResImage.style.display = "";
		
	} else {
		highResImage.style.display = "none";
		
	}
	
	if (t == 1) {
		image.style.display = "none";
		
	} else {
		image.style.display = "";
		
	}
	
};

PortfolioItem.prototype.awake = function () {
	this.isAwake = true;
	
	const element = this.element;
	element.style.display = "";
	this.setAlpha (1);
	
	// trace (this, "awakening");
	
};

PortfolioItem.prototype.sleep = function () {
	this.isAwake = false;
	
	const element = this.element;
	element.style.display = "none";
	
	this.setAlpha (0);
	// trace (this, "going to sleep");
	
};

PortfolioItem.prototype.changeIsSelected = function (imageModel) {
	const isSelected = imageModel.isSelected;
	
	const element = this.element;
	
	if (isSelected) {
		element.classList.add ("selected");
		this.parent.parent.parent.lightbox.appendImage (imageModel);
		
	} else {
		element.classList.remove ("selected");
		
	}
	
};

PortfolioItem.prototype.clickSelectionDot = function (event) {
	event.stopPropagation ();
	
	if (this.parent.scrollingController.didDrag)
		return;
	
	const imageModel = this.imageModel;
	imageModel.setIsSelected (!imageModel.isSelected);
	
};

PortfolioItem.prototype.setViewSize = function (viewSize) {
	this.viewSize = viewSize;
	
	const element = this.element;
	element.style.width = viewSize [0] + "px";
	element.style.height = viewSize [1] + "px";
	
};

PortfolioItem.prototype.setIsDarkened = function (isDarkened) {
	if (this.isDarkened == isDarkened)
		return;
	
	this.isDarkened = isDarkened;
	
	if (isDarkened) {
		this.startAnimation ("ShowOverlay", {direction: 1, rate: Portfolio.TRANSITION_SPEED * 2});
		
	} else {
		this.startAnimation ("ShowOverlay", {direction: 0, rate: Portfolio.TRANSITION_SPEED * 2});
		
	}
	
};

PortfolioItem.prototype.animateShowOverlay = function () {
	const state = this.updatedState ("ShowOverlay");
	let t = state.phase;
	
	this.setOverlayAlpha (t);
	
};

PortfolioItem.prototype.setOverlayAlpha = function (overlayAlpha) {
	if (this.overlayAlpha == overlayAlpha)
		return;
	
	this.overlayAlpha = overlayAlpha;
	
	const overlayElement = this.overlayElement;
	const selectionDot = this.selectionDot;
	
	if (overlayAlpha) {
		if (overlayElement.style.display)
			overlayElement.style.display = "";
		
		overlayElement.style.opacity = overlayAlpha;
		selectionDot.style.opacity = 1 - overlayAlpha * .667;
		
	} else {
		overlayElement.style.display = "none";
		selectionDot.style.opacity = "";
		
	}
	
};

PortfolioItem.prototype.beginScale = function (event) {
	event.stopPropagation ();
	event.preventDefault ();
	
	const stage = this.getStage ();
	
	stage.addListener ("mousemove", this.doScale, this);
	stage.addListener ("mouseup", this.endScale, this);
	
	document.body.classList.add ("scale");
	
	this.startScale = this.scaleFactor;
	this.startMouse = stage.touchDescriptionForObject (event);
	
};

PortfolioItem.prototype.doScale = function (stage) {
	const startScale = this.startScale;
	const startMouse = this.startMouse;
	
	const event = stage.currentEvent;
	const mouse = stage.touchDescriptionForObject (event);
	
	const bounds = this.element.getBoundingClientRect ();
	const center = [
		bounds.left + bounds.width * .5,
		bounds.top + bounds.height * .5
		
	];
	
	const startDelta = [
		startMouse [0] - center [0],
		startMouse [1] - center [1]
		
	];
	const startLength = Math.sqrt (
		startDelta [0] * startDelta [0] +
			startDelta [1] * startDelta [1]
		
	);
	
	const currentDelta = [
		mouse [0] - center [0],
		mouse [1] - center [1]
		
	];
	const currentLength = Math.sqrt (
		currentDelta [0] * currentDelta [0] +
			currentDelta [1] * currentDelta [1]
		
	);
	
	let scaleFactor = Math.max (.25, Math.min (1, 
		(currentLength / startLength
			* startScale) || 0
		
	));
	
	if (this.getStage ().isShiftDown) {
		scaleFactor = Math.log (scaleFactor) / Math.log (2);
		scaleFactor = Math.round (scaleFactor * 10) / 10;
		scaleFactor = Math.pow (2, scaleFactor);
		
	}
	
	this.scaleFactor = scaleFactor;
	
	const collection = this.parent;
	
	let minVO = collection.boundedViewOffset (Number.NEGATIVE_INFINITY);
	let maxVO = collection.boundedViewOffset (Number.POSITIVE_INFINITY);
	
	const lastPercentage = Math.max (0, Math.min (1,
		(collection.currentViewOffset - minVO) / (maxVO - minVO)
		
	));
	
	collection.updateLayout (0, true);
	
	minVO = collection.boundedViewOffset (Number.NEGATIVE_INFINITY);
	maxVO = collection.boundedViewOffset (Number.POSITIVE_INFINITY);
	
	collection.viewOffset = collection.currentViewOffset = lastPercentage * (maxVO - minVO) + minVO;
	collection.updateViewOffset ();
	
	collection.renderInContext ();
	
};

PortfolioItem.prototype.endScale = function (stage) {
	stage.removeListener ("mousemove", this.doScale, this);
	stage.removeListener ("mouseup", this.endScale, this);
	
	document.body.classList.remove ("scale");
	
	const loader = LoaderSystem.getSharedInstance ();
	const job = loader.jobForPathOfClass (
		"/api/image-scale/store",
		JSONLoaderJob,
		true
		
	);
	
	job.method = "POST";
	job.parameters = {
		imageId: this.imageModel.id,
		scale: this.scaleFactor
		
	};
	
	loader.enqueueJob (job);
	
};

PortfolioItem.prototype.mouseDown = function (sender) {
	const event = sender.currentEvent;
	if (event.button)
		return;
	
	var stage = this.getStage ();
	stage.addListener ("mousemove", this.doDrag, this);
	stage.addListener ("mouseup", this.mouseUp, this);
	
	const startMouse = this.startMouse = this.parent.touchDescriptionForObject (event);
	this.currentMouse = startMouse;
	this.startPosition = this.position.concat ();
	this.mouseDelta = undefined;
	
	this.mouseDownTimeout = window.setTimeout (function () {
		this.enableDrag ();
		
	}.bind (this), 500);
	
	this.element.classList.add ("will-drag");
	
};

PortfolioItem.prototype.doDrag = function (sender) {
	
};

PortfolioItem.prototype.mouseUp = function (sender) {
	this.element.classList.remove ("will-drag");
	
	const stage = this.getStage ();
	
	stage.removeListener ("mousemove", this.doDrag, this);
	stage.removeListener ("mouseup", this.mouseUp, this);
	
	window.clearTimeout (this.mouseDownTimeout);
	this.mouseDownTimeout = undefined;
	
	// if (sender)
	//	this.parent.updateLayout ();
	
};

PortfolioItem.prototype.enableDrag = function () {
	if (this.parent.scrollingController.didDrag)
		return;
	
	// trace ("-- enable drag --");
	
	this.mouseUp ();
	
	const delta = this.mouseDelta;
	if (delta) {
		const position = this.position;
		const startPosition = this.startPosition;
		this.setPosition (
			position [0] + delta [0],
			startPosition [1] + delta [1]
			
		);
		
	}
	
	this.dispatchEvent ("enableDrag");
	
};
