//
// runtime environment
//

export const IS_IE =
	navigator.appName.indexOf ("Microsoft") != -1 ||
	navigator.userAgent.indexOf ("Trident") != -1 ||
	navigator.userAgent.indexOf ("Edge") != -1;
export const IS_IE_MOBILE = IS_IE && navigator.userAgent.indexOf ("IEMobile") >= 0;
export const IE_VERSION = IS_IE ?
	parseFloat (navigator.appVersion.split ("MSIE") [1]) || 11 : -1;


export const IS_WEBKIT = navigator.userAgent.indexOf ("WebKit") != -1;
export const WEBKIT_VERSION = IS_WEBKIT ?
	Number (/WebKit\/([0-9]*)/.exec (navigator.userAgent) [1]) : -1;

export const IS_MOZILLA = !IS_IE && !IS_WEBKIT && navigator.userAgent.indexOf ("Mozilla") != -1;
export var MOZILLA_VERSION;
try {
	MOZILLA_VERSION = IS_MOZILLA ?
		Number (/Firefox\/([0-9]*)/.exec (navigator.userAgent) [1]) : -1;
	
} catch (error) {}

export const ENABLE_CSS_TRANSFORM =
	IE_VERSION >= 10 ||
	WEBKIT_VERSION >= 530 ||
	MOZILLA_VERSION > 10;

export const ENABLE_CSS_3D_TRANSFORM =
	IE_VERSION >= 10 ||
	WEBKIT_VERSION >= 534 ||
	MOZILLA_VERSION >= 12;

export const SUPPORTS_TRANSPARENCY = !IS_IE || IE_VERSION >= 9;


export const IS_TOUCH_DEVICE = typeof window.ontouchstart != "undefined";
export const IS_IPHONE = navigator.userAgent.match (/iPhone/i) != null;
export const IS_IPAD = navigator.userAgent.match (/iPad/i) != null;

export const IS_SAFARI = navigator.userAgent.indexOf ("Safari") >= 0 &&
	navigator.userAgent.indexOf ("Chrome") == -1;
export const IS_MOBILE_SAFARI = IS_SAFARI && navigator.userAgent.indexOf ("Mobile") >= 0;

export const QUICK_CLICK_EVENT_TYPE = IS_TOUCH_DEVICE ? "touchend" : "click";

export const USER_AGENT =
	IS_IE ? "ie" :
	IS_WEBKIT ? "webkit" :
	IS_MOZILLA ? "mozilla" :
	"unknown";

export const VENDOR_PREFIX = function () {
	var vendorPrefixes = {
		ie: "ms",
		webkit: "webkit",
		mozilla: "Moz"
		
	};
	return vendorPrefixes [USER_AGENT];
	
} ();

//

export const IS_ONLINE = window.location.host.indexOf ("pattrick") != 0;

export const MUTED = !IS_ONLINE;
export const FAST_PASS = !IS_ONLINE;

export const BASE_PATH = IS_ONLINE ? "data/" : "data/";

export const USE_HIGH_RESOLUTION = screen.width * (window.devicePixelRatio || 1) >= 1280;

export const IS_IN_NEOS_EDITOR = !!window.neos;
