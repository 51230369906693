import * as Environment from "../base/Environment.js";

import {LazyLoader} from "../components/LazyLoader.js";

import {AnimatableSprite} from "../base/Display2D.js";
import {ScrollingController, SlidingSpritePrototype} from "../components/Control.js";

import {SiteSection} from "./Site.js";

//
// GallerySection extends SiteSection
//

export const GallerySection = function (context) {
	SiteSection.apply (this, arguments);
	
};

window.GallerySection = GallerySection;

GallerySection.prototype = Object.create (SiteSection.prototype);

GallerySection.prototype.takeElement = function (element) {
	SiteSection.prototype.takeElement.apply (this, arguments);
	
	var gallery = this.gallery = this.attachSprite (Gallery);
	
	gallery.takeElement (
		element.classList.contains ("gallery") ?
			element : element.querySelector (".gallery")
		
	);
	gallery.contentContainer.element.style.visibility = "hidden";
	
};

GallerySection.prototype.awake = function () {
	SiteSection.prototype.awake.apply (this, arguments);
	
	var gallery = this.gallery;
	gallery.isAutoRotationLocked = false;
	
};

GallerySection.prototype.sleep = function () {
	SiteSection.prototype.sleep.apply (this, arguments);
	
	var gallery = this.gallery;
	gallery.isAutoRotationLocked = true;
	
};

GallerySection.prototype.setViewSize = function (viewSize) {
	SiteSection.prototype.setViewSize.apply (this, arguments);
	
	var gallery = this.gallery;
	gallery.updateLayout ();
	
	window.setTimeout (function () {
		gallery.updateLayout ();
		gallery.contentContainer.element.style.visibility = "";
		
	}, 50);
	
};

//
// Gallery extends AnimatableSprite, SlidingSpritePrototype
//

export const Gallery = function (context) {
	AnimatableSprite.apply (this, arguments);
	
};

Gallery.prototype = Object.create (AnimatableSprite.prototype);
Gallery.extendPrototype (SlidingSpritePrototype);

Gallery.prototype.getListenerTargetForScrollingController = function () {
	return this.element.firstChild;
	
};

Gallery.prototype.takeElement = function (element) {
	this.element.parentNode.removeChild (this.element);
	this.element = element;
	
	if (!Environment.IS_IN_NEOS_EDITOR)
		element.classList.add ("unselectable");
	
	SlidingSpritePrototype.call (this, this.context);
	
	var fitting = this.fitting = element.getAttribute ("data-fitting");
	var adjustHeightToContent = this.adjustHeightToContent = fitting == "content";
	if (!adjustHeightToContent) {
		fitting = fitting.split (":");
		var aspectRatio = this.aspectRatio =
			parseFloat (fitting [1]) /
			parseFloat (fitting [0]);
		
	}
	
	var contentContainer = this.contentContainer = this.attachSprite ();
	
	element.removeChild (contentContainer.element);
	contentContainer.element = element.querySelector (".gallery-slide-container");
	
	this.parseChildren ();
	
	this.finishSetup ();
	
};

Gallery.prototype.parseChildren = function () {
	var element = this.element;
	var context = this.context;
	
	var slides = this.slides = new Array ();
	
	var contentContainer = this.contentContainer;
	var children = (Environment.IS_IN_NEOS_EDITOR ? element.parentNode.parentNode.parentNode : element).querySelectorAll (".gallery-slide");
	
	var numSlides = this.numSlides = children.length;
	
	var firstChildParent = children [0];
	
	firstChildParent = firstChildParent && firstChildParent.slide;
	firstChildParent = firstChildParent && firstChildParent.element.parentNode;
	
	for (var i = 0; i < numSlides; i++) {
		var child = children [i];
		
		var slide = child.slide;
		if (!child.slide) {
			slide = child.slide = new GallerySlide (context);
			slide.parentGallery = this;
			
			slide.takeElement (child);
			slide.setAlpha (0);
			
		}
		
		slide.index = i;
		
		slides.splice (i, 0, slide);
		
	}
	
	for (var i = 0; i < slides.length; i++) {
		var slide = slides [i];
		var child = slide.element;
		
		var slideParentNode = firstChildParent || child.parentNode;
		contentContainer.addChild (slide);
		slideParentNode.appendChild (slide.element);
		
	}
	
	if (!Environment.IS_IN_NEOS_EDITOR) {
		while (slides.length > 1 && slides.length < 3) {
			for (var i = 0; i < numSlides; i++) {
				var child = children [i].cloneNode (true);
				
				slide = child.slide = new GallerySlide (context);
				slide.index = slides.length;
				slide.takeElement (child);
				slide.setAlpha (0);
				
				contentContainer.addChild (slide);
				
				slides.push (slide);
				
			}
			
		}
		
		var captionContainer = this.captionContainer = element.querySelector (".gallery__caption-footer");
		
		if (captionContainer) {
			for (var i = slides.length; i--;) {
				var slide = slides [i];
				var caption = slide.caption;
				
				if (caption)
					captionContainer.appendChild (caption);
				
			}
			
		}
		
	}
	
};

Gallery.prototype.takeSlides = function (slides, navigationElement) {
	var element = this.element;
	element.classList.add ("gallery");
	
	SlidingSpritePrototype.apply (this, arguments);
	
	this.slides = slides;
	var numSlides = this.numSlides = slides.length;
	
	var contentContainer = this.contentContainer = this.attachSprite ();
	contentContainer.element.classList.add ("gallery__slide-container");
	
	for (var i = 0; i < slides.length; i++) {
		var slide = slides [i];
		slide.index = i;
		
		contentContainer.addChild (slide);
		
	}
	
	if (navigationElement) {
		this.navigationElement = navigationElement;
		this.element.appendChild (navigationElement);
		
	}
	
	this.finishSetup ();
	
};

Gallery.prototype.finishSetup = function (viewSize) {
	var element = this.element;
	
	var navigationElement = this.navigationElement = element.querySelector (".gallery__navigation");
	if (navigationElement) {
		var navigationCounter = this.navigationCounter = navigationElement.querySelector (".gallery__navigation__counter");
		
	}
	
	const progressBar = this.progressBar = element.querySelector (".gallery-progress-bar");
	
	const numSlides = this.numSlides;
	if (numSlides > 1) {
		var scrollingController = this.scrollingController;
		
		if (!Environment.IS_IN_NEOS_EDITOR) {
			scrollingController.stage = this.getStage ();
			scrollingController.getMouseListenerTarget = function (eventType) {
				return element.parentNode.parentNode;
				
			};
			
			scrollingController.defaultTouchDirectionLock = ScrollingController.TOUCH_MOVE_DIRECTION_HORIZONTAL;
			
			scrollingController.awake ();
			scrollingController.addWheelListener ();
			scrollingController.cancelHorizontalScroll = true;
			
			scrollingController.addListener ("beginDrag", this.beginDrag, this);
			scrollingController.addListener ("dragHorizontal", this.dragHorizontal, this);
			scrollingController.addListener ("cancelDragHorizontal", this.endDragHorizontal, this);
			scrollingController.addListener ("endDrag", this.endDragHorizontal, this);
			
			scrollingController.addListener ("scrollHorizontal", this.scrollHorizontal, this);
			scrollingController.addListener ("cancelScrollHorizontal", this.cancelScrollHorizontal, this);
			
		}
		
		this.addListener ("completeSliding", this.completeSliding, this);
		
		this.addListener ("advance", this.advance, this);
		
		if (navigationElement) {
			var arrowButtons = element.querySelectorAll (".gallery__navigation__arrow");
			arrowButtons [0].addEventListener ("click", function () {
				this.advanceInDirection (-1, true);
				
			}.bind (this));
			arrowButtons [0].addEventListener ("touchstart", function () {
				this.advanceInDirection (-1, true);
				
			}.bind (this));
			arrowButtons [1].addEventListener ("click", function () {
				this.advanceInDirection (1, true);
				
			}.bind (this));
			arrowButtons [1].addEventListener ("touchstart", function () {
				this.advanceInDirection (1, true);
				
			}.bind (this));
			
			function stopPropagation (event) {
				event.stopPropagation ();
				event.preventDefault ();
				
			}
			
			for (var i = arrowButtons.length; i--;) {
				var arrowButton = arrowButtons [i];
				arrowButton.addEventListener ("mousedown", stopPropagation);
				arrowButton.addEventListener ("touchstart", stopPropagation);
				
			}
			
		}
		
		if (progressBar) {
			for (let i = 0; i < numSlides; i++) {
				const indicator = document.createElement ("div");
				indicator.index = i;
				indicator.classList.add ("gallery-progress-bar-item");
				
				indicator.addEventListener ("mousedown", function (event) {
					event.stopPropagation ();
					if (!Environment.IS_TOUCH_DEVICE)
						event.preventDefault ();
					
				});
				
				indicator.addEventListener ("click", function (event) {
					this.setViewOffsetByIndex (event.currentTarget.index, true);
					
				}.bind (this));
				
				progressBar.appendChild (indicator);
				
			}
			
		}
		
		if (Environment.IS_IN_NEOS_EDITOR) {
			// this.getStage ().addListener ("mutate", this.mutateStage, this);
			
			var mutationObserver = new MutationObserver (this.mutateElement.bind (this));
			mutationObserver.observe (this.element.parentNode.parentNode.parentNode, {
				childList: true,
				subtree: true
				
			});
			
		} else {
			this.isAutoRotationLocked = true;
			
			var autoRotationFrequency = this.autoRotationFrequency = parseFloat (element.getAttribute ("data-auto-rotation-frequency")) * 60;
			var shouldAutoRotate = this.shouldAutoRotate = !!autoRotationFrequency;
			if (shouldAutoRotate)
				this.startAutoRotation ();
			
		}
		
	} else {
		if (progressBar) {
			progressBar.style.display = "none";
			
		}
		
		if (!Environment.IS_IN_NEOS_EDITOR) {
			if (navigationElement)
				navigationElement.style.display = "none";
			
		}
		
	}
	
};

Gallery.prototype.mutateElement = function (stage) {
	if (this.isMutating)
		return;

	var activeElement = document.activeElement;
	if (activeElement && activeElement.getAttribute ("contenteditable") == "true")
		return;
	
	var now = new Date ().getTime ();
	var nextTimeToObserve = this.nextTimeToObserve;
	
	if (now < nextTimeToObserve) {
		if (!this.mutationHandler) {
			this.mutationHandler = window.setTimeout (
				this.handleMutation.bind (this),
				nextTimeToObserve - now
				
			);
			
		}
		
	} else {
		this.handleMutation ();
		
	}
	
};

Gallery.prototype.handleMutation = function () {
	this.mutationHandler = null;
	
	var now = new Date ().getTime ();
	var nextTimeToObserve = this.nextTimeToObserve = now + 500;
	
	this.isMutating = true;
	
	this.parseChildren ();
	this.updateLayout (true);
	this.updateViewOffset ();
	this.advance ();
	
	window.setTimeout (function () {
		this.updateLayout (true);
		this.updateViewOffset ();
		
		this.isMutating = false;
		
	}.bind (this), 50);
	
};

Gallery.prototype.startAutoRotation = function () {
	// if (Environment.FAST_PASS)
	//	return;
	
	this.addListener ("mouseover", this.mouseOverOnAutoRotation, this);
	this.addListener ("mouseout", this.mouseOutOnAutoRotation, this);

	this.autoRotationDelay = this.autoRotationFrequency;
	this.addRunLoopHandler ("processAutoRotation");
	
};

Gallery.prototype.stopAutoRotation = function () {
	this.removeRunLoopHandler ("processAutoRotation");
	
};

Gallery.prototype.mouseOverOnAutoRotation = function (sender) {
	this.isAutoRotationLocked = true;
	
};

Gallery.prototype.mouseOutOnAutoRotation = function (sender) {
	this.isAutoRotationLocked = false;
	this.autoRotationDelay = Math.max (this.autoRotationFrequency, 200);
	
};

Gallery.prototype.processAutoRotation = function (sender) {
	if (this.isAutoRotationLocked)
		return;
	
	var autoRotationDelay = this.autoRotationDelay = Math.max (
		0, this.autoRotationDelay - this.context.animationTimer.framesDelta
		
	);
	
	if (autoRotationDelay)
		return;
	
	this.autoRotationDelay = this.autoRotationFrequency;
	var headerGallery = this.headerGallery;
	
	this.skipTracking = true;
	this.advanceInDirection (1, {
		maxSpeed: 50,
		acceleration: 4.25
		
	});
	
};

Gallery.prototype.updateLayout = function (noResize) {
	var slides = this.slides;
	var slide = slides [0];
	if (!slide)
		return;
	
	slide.element.style.display = "";
	
	var containerElement = this.contentContainer.element;
	var containerWidth = Math.round (containerElement.offsetWidth);
	
	var viewSize;
	
	if (this.adjustHeightToContent) {
		viewSize = [
			containerWidth,
			Math.round (containerElement.offsetHeight)
			
		];
		
		var maxHeight = 0;
		var slides = this.slides;
		
		for (var i = slides.length; i--;) {
			var slide = slides [i];
			var slideElement = slide.element;
			var slideSize = slideElement.getBoundingClientRect ();
			
			var displayStyle = slideElement.style.display;
			
			if (displayStyle)
				slideElement.style.display = "";
			
			slideElement.style.width = viewSize [0] + "px";
			slideElement.style.height = "auto";
			var slideHeight = slideElement.offsetHeight;
			
			if (displayStyle)
				slideElement.style.display = displayStyle;
			
			maxHeight = Math.max (maxHeight, slideHeight);
			
		}
		
		viewSize [1] = maxHeight;
		
	} else {
		viewSize = [
			containerWidth,
			Math.round (containerWidth * this.aspectRatio)
			
		];
		
	}
	
	this.setViewSize (viewSize, noResize);
	
};

Gallery.prototype.setViewSize = function (viewSize, noResize) {
	var containerElement = this.contentContainer.element;
	containerElement.style.height = viewSize [1] + "px";
	
	this.spacing = this.getStage ().size [0] <= 767 ? 30 : 60;
	
	var captionContainer = this.captionContainer;
	if (captionContainer) {
		var maxCaptionHeight = 0;
		
		if (!Environment.IS_IN_NEOS_EDITOR) {
			var slides = this.slides;
			
			for (var i = slides.length; i--;) {
				var slide = slides [i];
				var caption = slide.caption;
				
				if (caption) {
					caption.style.display = "";
					maxCaptionHeight = Math.max (maxCaptionHeight, caption.offsetHeight);
					
				}
				
			}
			
		}
		
		if (maxCaptionHeight) {
			captionContainer.style.display = "";
			captionContainer.style.paddingBottom = maxCaptionHeight + 3 + "px";
			
		} else {
			captionContainer.style.display = "none";
			
		}
		
	}
	
	var stageSize = this.getStage ().size;
	var overscan = this.overscan = Math.ceil (
		(stageSize [0] - viewSize [0]) / 2
		
	);
	
	var lastViewSize = this.viewSize;
	this.viewSize = viewSize;
	
	this.setSize (viewSize);
	/*
	if (!lastViewSize) {
		if (window.location.hash)
			this.setViewOffsetByFragment (window.location.hash);
		
	}
	*/
	
	if (lastViewSize && !noResize && !(this.getStage ().nextTimeToObserve > new Date ().getTime ())) {
		var percentage = Math.round (
			this.currentViewOffset / (lastViewSize [0] + this.spacing)
			
		);
		this.viewOffset = this.currentViewOffset =
			percentage * (viewSize [0] + this.spacing);
		
		this.removeRunLoopHandler ("processSliding");
		
	}
	
	this.updateViewOffset ();
	
	if (!lastViewSize) {
		this.dispatchEvent ("advance");
		this.dispatchEvent ("completeSliding");
		
	}
	
};

Gallery.prototype.setViewOffsetByIndex = function (index, animate, jump) {
	var slides = this.slides;
	index = index % slides.length;
	if (index < 0)
		index += slides.length;
	
	var viewOffset = this.viewOffset;
	
	var currentImageIndex = this.slideIndexForViewOffset (this.viewOffset);
	var delta = (index - currentImageIndex) % slides.length;
	if (delta < 0)
		delta += slides.length;
	
	if (delta > slides.length * .5)
		delta = delta - slides.length;
	
	if (!delta)
		return;
	
	var viewSize = this.viewSize;
	var targetViewOffset = this.viewOffset;
	
	var direction = slides.length > 2 ? delta > 0 ? 1 : -1 : currentImageIndex > index ? -1 : 1;
	targetViewOffset += (this.viewSize [0] + this.spacing) * Math.abs (delta) * direction;
	
	if (targetViewOffset == viewOffset)
		return;
	
	if (animate) {
		if (jump) {
			this.currentViewOffset = targetViewOffset - viewSize [0] * .75 * direction;
			
		}	
		
		this.viewOffset = targetViewOffset;
		
		this.slidingInertia = .85;
		this.maxScrollSpeed = 4;
		this.accelerationDescription = undefined;
		
		this.addRunLoopHandler ("processSliding");
		
	} else {
		this.viewOffset = this.currentViewOffset = targetViewOffset;
		this.removeRunLoopHandler ("processSliding");
		this.updateViewOffset ();
		
	}
	
	this.advance ();
	
};

Gallery.prototype.setViewOffsetByFragment = function (fragment, animate) {
	if (!fragment)
		return;
	if (fragment.charAt (0) == "#")
		fragment = fragment.substring (1);
	
	var slides = this.slides;
	
	for (var i = 0; i < slides.length; i++) {
		var slide = slides [i];
		if (slide.id == fragment) {
			this.setViewOffsetByIndex (i);
			this.advance ();
			
			break;
			
		}
		
	}
	
};

Gallery.DRAG_DELTA = 150;

Gallery.prototype.beginDrag = function (scrollingController) {
	SlidingSpritePrototype.beginDrag.apply (this, arguments);
	
	// scrollingController.currentEvent.preventDefault ();
	scrollingController.currentEvent.stopPropagation ();
	
	delete this.maxScrollSpeed;
	
};

Gallery.prototype.boundedViewOffset = function (viewOffset) {
	return viewOffset;
	
};

Gallery.prototype.snapToBounds = function () {
	var viewOffset = this.viewOffset;
	var index = this.slideIndexForViewOffset (viewOffset, true);
	
	var targetViewOffset = index * (this.viewSize [0] + this.spacing);
	
	if (viewOffset != targetViewOffset) {
		this.viewOffset = targetViewOffset;
		this.slidingInertia = .85;
		this.addRunLoopHandler ("processSliding");
		
	}
	
};

Gallery.prototype.completeSliding = function (sender) {
	var index = this.index = this.slideIndexForViewOffset (this.viewOffset);
	var slides = this.slides;
	
	var currentSlide = slides [index];
	if (!currentSlide.isLoading)
		currentSlide.startLoading ();
	
	if (slides.length > 1) {
		var nextItem = slides [(index + 1) % slides.length];
		if (!nextItem.isLoading)
			nextItem.startLoading ();
		
	}
	
	if (slides.length > 2) {
		var previousItem = slides [(index + slides.length - 1) % slides.length];
		if (!previousItem.isLoading)
			previousItem.startLoading ();
		
	}
	
	/*
	if (this.skipTracking) {
		this.skipTracking = false;
		
	} else {
		if (window.WebtrekkCounter)
			WebtrekkCounter.track ("click", "homepage.teaser." + currentSlide.id);
		
	}
	*/
	
};

Gallery.prototype.advance = function (sender) {
	const currentImageIndex = this.slideIndexForViewOffset (this.viewOffset);
	
	var navigationCounter = this.navigationCounter;
	if (navigationCounter) {
		var slide = this.slides [currentImageIndex];
		
		var numSlides = this.numSlides;
		navigationCounter.innerText = (currentImageIndex % numSlides) + 1 + "/" + numSlides;
		
	}
	
	const progressBar = this.progressBar;
	
	if (progressBar) {
		const indicators = progressBar.children;
		for (let i = indicators.length; i--;) {
			const indicator = indicators [i];
			
			if (i == currentImageIndex)
				indicator.classList.add ("active");
			else
				indicator.classList.remove ("active");
			
		}
		
	}
	
};

Gallery.prototype.slideIndexForViewOffset = function (viewOffset, noWrap) {
	var index = Math.round (
		viewOffset / (this.viewSize [0] + this.spacing)
		
	);
	
	if (!noWrap) {
		var slides = this.slides;
		index = index % slides.length;
		if (index < 0)
			index += slides.length;
		
	}
	
	return index;
	
};

Gallery.prototype.dragHorizontal = function (scrollingController) {
	var deltaX = scrollingController.delta.x;
	this.removeRunLoopHandler ("processSliding");
	
	var viewSize = this.viewSize;
	var dragDelta = Math.min (
		viewSize [0] / 2, Gallery.DRAG_DELTA
		
	);
	
	if (Math.abs (deltaX) >= dragDelta)
		this.advanceInDirection (deltaX < 0 ? -1 : 1);
	else
		this.viewOffset = this.currentViewOffset = this.startViewOffset + deltaX;
	
	this.updateViewOffset ();
	
};

Gallery.prototype.endDragHorizontal = function (scrollingController) {
	var didFlick = scrollingController.didFlickHorizontal;
	
	if (didFlick) {
		var targetViewOffset = this.viewOffset + scrollingController.flickDeltaX * 1.5;
		
		var viewSize = this.viewSize;
		var dragDelta = Math.min (
			viewSize [0] / 2, Gallery.DRAG_DELTA
			
		);
		
		if (Math.abs (targetViewOffset) >= dragDelta) {
			this.advanceInDirection (scrollingController.flickDeltaX < 0 ? -1 : 1);
			
		} else {
			this.snapToBounds ();
			
		}
		
	} else {
		this.snapToBounds ();
		
	}
	
};

Gallery.prototype.scrollHorizontal = function (scrollingController) {
	if (scrollingController.isDragging)
		return;
	
	if (this.isWheelLocked)
		return;
	
	const now = new Date ().getTime ();
	if (now < this.scrollTimeOut)
		return;
	
	var delta = scrollingController.delta.x;
	const targetViewOffset = this.viewOffset + delta;
	
	this.viewOffset = targetViewOffset;
	this.slidingInertia = .7;
	
	this.addRunLoopHandler ("processSliding");
	
	const viewSize = this.viewSize;
	
	const slides = this.slides;
	const currentIndex = this.slideIndexForViewOffset (targetViewOffset);
	
	const currentPos = currentIndex * (viewSize [0] + this.spacing);
	
	let currentDelta = targetViewOffset - currentPos;
	const fullWidth = slides.length * (viewSize [0] + this.spacing);
	
	currentDelta += fullWidth * .5;
	currentDelta %= fullWidth;
	if (currentDelta < 0)
		currentDelta += fullWidth;
	currentDelta -= fullWidth * .5;
	
	var dragDelta = Math.min (
		viewSize [0] / 2, Gallery.DRAG_DELTA
		
	);
	
	if (Math.abs (currentDelta) >= dragDelta) {
		this.advanceInDirection (currentDelta > 0 ? 1 : -1);
		
		this.scrollTimeOut = now + 1000;
		
	} else {
		// this.updateViewOffset ();
		
	}
	
};

Gallery.prototype.cancelScrollHorizontal = function (scrollingController) {
	if (this.scrollingController.isTouching)
		return;
	
	this.snapToBounds ();
	
};

Gallery.prototype.advanceInDirection = function (direction, easeIn) {
	this.releaseDrag ();
	
	var delta = Math.abs (this.currentViewOffset - this.viewOffset);
	
	var index = this.slideIndexForViewOffset (this.viewOffset, true) + direction;
	
	var viewSize = this.viewSize;
	this.viewOffset = index * (viewSize [0] + this.spacing);
	this.slidingInertia = .85;
	if (easeIn && delta < 10)
		this.maxScrollSpeed = 4;
	this.accelerationDescription = undefined;
	
	this.addRunLoopHandler ("processSliding");
	
	this.dispatchEvent ("advance");
	
};

Gallery.prototype.spacing = 40;

Gallery.prototype.slideIndexForViewOffset = function (viewOffset, noWrap) {
	var index = Math.round (
		viewOffset / (this.viewSize [0] + this.spacing)
		
	);
	
	if (!noWrap) {
		var slides = this.slides;
		index = index % slides.length;
		if (index < 0)
			index += slides.length;
		
	}
	
	return index;
	
};

Gallery.prototype.updateViewOffset = function () {
	var slides = this.slides;
	if (!slides.length)
		return;
	
	var viewSize = this.viewSize;
	var viewWidth = viewSize [0];
	var spacing = this.spacing;
	
	var viewOffset = this.currentViewOffset;
	var currentSlideIndex = this.currentSlideIndex = this.slideIndexForViewOffset (viewOffset);
	
	viewOffset = (viewOffset + (viewWidth + spacing) * .5) % (viewWidth + spacing);
	if (viewOffset < 0)
		viewOffset += (viewWidth + spacing);
	
	viewOffset = viewOffset - (viewWidth + spacing) * .5 + currentSlideIndex * (viewWidth + spacing);
	
	viewOffset = Math.round (viewOffset);
	
	for (var i = slides.length; i--;) {
		var slide = slides [i];
		slide.isUsed = false;
		
	}
	
	var overscan = this.overscan;
	
	var slide = slides [currentSlideIndex];
	
	var i = currentSlideIndex;
	var cursor = -Math.round (viewSize [0] * .5) - viewOffset + (currentSlideIndex * (viewWidth + spacing));
	
	while (cursor > -viewWidth * .5 + spacing - overscan) {
		i = (i - 1) % slides.length;
		if (i < 0)
			i += slides.length;
		
		var slide = slides [i];
		// slide.setViewSize (viewSize);
		
		cursor -= viewSize [0] + spacing;
		
	}
	
	var viewOffset = Math.round (viewWidth * .5);
	
	var contentContainer = this.contentContainer;
	
	if (this.numSlides == 1)
		cursor = -viewOffset;
	
	var isInNeosEditor = Environment.IS_IN_NEOS_EDITOR;
	
	var percentageSum = 0;
	
	while (cursor < Math.floor (viewWidth * .5) + overscan) {
		var slide = slides [i];
		if (slide.isUsed)
			break;
		slide.isUsed = true;
		
		slide.setViewSize (viewSize);
		slide.setPosition (
			cursor + viewOffset,
			0
			
		);
		
		// slide.markLoadingPriority (-(cursor + viewOffset) / 8192) + 4.5;
		if (!slide.isLoading)
			slide.startLoading ();
		
		slide.loadingPriority = Math.abs (cursor + viewOffset);
		
		if (!slide.isAwake) {
			slide.setAlpha (1);
			slide.awake ();
			
		}
		
		var percentage = Math.max (0, Math.min (1, (1 - Math.abs (
			slide.position [0] / viewSize [0]
			
		))));
		
		if (!isInNeosEditor) {
			var caption = slide.caption;
			if (caption) {
				if (percentage) {
					caption.style.display = "";
					caption.style.opacity = percentage;
					
					percentageSum += percentage;
					
				} else {
					caption.style.display = "none";
					
				}
				
			}
			
		}
		
		slide.setAlpha (percentage * .67 + .33);
		
		if (slide.setPercentage) {
			slide.setPercentage (percentage);
			
		}
		
		i = (i + 1) % slides.length;
		if (i < 0)
			i += slides.length;
		
		cursor += viewSize [0] + spacing;
		
	}
	
	for (var i = slides.length; i--;) {
		var slide = slides [i];
		if (!slide.isUsed) {
			// contentContainer.removeChild (slide);
			slide.setAlpha (0);
			slide.element.style.display = "none";
			
			if (slide.isAwake)
				slide.sleep ();
			
			if (!isInNeosEditor) {
				var caption = slide.caption;
				if (caption)
					caption.style.display = "none";
				
			}
			
		}
		
	}
	
	this.dispatchEvent ("updateViewOffset");
	
	this.renderInContext (this.context);
	
};

//
// GallerySlide extends AnimatableSprite
//

var GallerySlide = function (context) {
	AnimatableSprite.apply (this, arguments);
	
	this.renderUsingCSSTransform = true;
	
};

GallerySlide.prototype = Object.create (AnimatableSprite.prototype);

GallerySlide.prototype.takeElement = function (element) {
	this.element = element;
	
	if (!Environment.IS_IN_NEOS_EDITOR)
		element.classList.add ("unselectable");
	
	element.addEventListener ("click", function (event) {
		if (this.parent.parent.scrollingController.didDrag) {
			event.preventDefault ();
			return;
			
		}
		
	}.bind (this));
	
	var isImageSlide = true || element.classList.contains ("gallery__slide--image");
	if (isImageSlide) {
		var image = this.image = element.querySelector ("picture");
		
		if (image) {
			image.classList.add ("lazyload--started");
			
			/*
			var imageSize = this.imageSize = [
				Number (image.parentNode.getAttribute ("data-width")),
				Number (image.parentNode.getAttribute ("data-height"))
				
			];
			*/
			var imageSize = this.imageSize = [16, 10];
			
		}
		
	}
	
	var caption = element.querySelector (".gallery__caption");
	if (caption && caption.textContent)
		this.caption = caption;
	
	if (Environment.IS_IN_NEOS_EDITOR) {
		element.removeAttribute ("href");
		
	}
	
};

GallerySlide.prototype.setViewSize = function (viewSize) {
	this.viewSize = viewSize;
	
	var element = this.element;
	element.style.width = viewSize [0] + "px";
	element.style.height = viewSize [1] + "px";
	
	var image = this.image;
	if (!image)
		return;
	
	var imageSize = this.imageSize;
	
	var fitting = this.parent.parent.fitting;
	
	var scale;
	scale = Math.max (
		viewSize [0] / imageSize [0],
		viewSize [1] / imageSize [1]
		
	);
	
	image.style.left = (viewSize [0] - imageSize [0] * scale) *.5 + "px";
	image.style.top = (viewSize [1] - imageSize [1] * scale) * .5 + "px";
	
	image.style.width = imageSize [0] * scale + "px";
	image.style.height = imageSize [1] * scale + "px";
	
};

GallerySlide.prototype.markLoadingPriority = function (loadingPriority) {
	this.loadingPriority = loadingPriority;
	
};

GallerySlide.prototype.startLoading = function () {
	this.isLoading = true;
	
	var image = this.image;
	if (!image)
		return;
	
	image.completeHandler = function () {
		const img = image.querySelector ("img") || img;
		this.imageSize = [
			img.naturalWidth,
			img.naturalHeight
			
		];
		
		if (this.viewSize)
			this.setViewSize (this.viewSize);
		
	}.bind (this);
	
	LazyLoader.sharedInstance.loadImageAndListen (image);
	
};

GallerySlide.prototype.awake = function () {
	this.isAwake = true;
	
};

GallerySlide.prototype.sleep = function () {
	this.isAwake = false;
	
};
