import * as Environment from "../base/Environment.js";

import {RenderContext, Stage, AnimatableSprite} from "../base/Display2D.js";
import {LoaderSystem, DataLoaderJob} from "../base/Loader.js";
import {LazyLoader} from "../components/LazyLoader.js";

import {SiteSection} from "./SiteSection.js";
export {SiteSection};

import {HeaderNavigation} from "./HeaderNavigation.js";

import {NavigationSection} from "./NavigationSection.js";
import {VignetteSection} from "./VignetteSection.js";
import {GallerySection} from "./GallerySection.js";
import {PortfolioSection} from "./PortfolioSection.js";
import {OverlayVideoPlayer} from "./VideoOverlay.js";

//
// Site extends Stage
//

export const Site = function (context) {
	Stage.apply (this, arguments);
	
	if (Site.sharedInstance)
		throw new Error ("Cannot instantiate. Site is singleton.");
	
	Site.sharedInstance = this;
	
	var element = this.element;
	element.classList.remove ("sprite");
	
	this.createMediaProbes (["mobile"]);
	
	if (!Environment.IS_TOUCH_DEVICE) {
		var scrollBar = this.scrollBar = this.attachSprite (Site.Scrollbar);
		scrollBar.attachToElement (document);
		scrollBar.addListener ("drag", this.dragScrollBar, this);
		
	}
	
	var sections = this.sections = new Array ();
	this.setUpContent ();
	
	var lazyLoader = this.lazyLoader = new LazyLoader (element);
	
	context.resizeDispatcher.addListener ("scroll", this.scroll, this);
	
	this.addListener ("resize", this.resize, this);
	this.resize ();
	
	if (Environment.FAST_PASS) {
		
	}
	
	if (Environment.IS_IN_NEOS_EDITOR) {
		this.addMutationObserver ();
		
		window.addEventListener ("keydown", this.keyUpDown.bind (this));
		window.addEventListener ("keyup", this.keyUpDown.bind (this));
		
	}
	
	window.setTimeout (function () {
		this.resize ();
		
	}.bind (this), 50);
	
};

window.Site = Site;

Site.prototype = Object.create (Stage.prototype);

Site.sharePage = function () {
	window.open (
		"https://www.facebook.com/sharer/sharer.php?u=" + encodeURIComponent (location.href),
		"_blank"
		
	);
	
};

Site.prototype.resizeContext = function (context) {
	this.setSize (
		Environment.IS_TOUCH_DEVICE ?
			RenderContext.getViewportSize () :
			this.getRulerSize ()
		
	);
	
};

Site.prototype.setUpContent = function () {
	const mainElement = this.mainElement = document.querySelector ("main");
	const footerElement = this.footerElement = document.querySelector ("footer");
	
	var element = this.element;
	
	this.setUpHeaderNavigation ();
	this.setUpSectionControllers (element);
	this.setUpShareLinks ();
	this.setUpScrollDownAnimation ();
	
};

Site.prototype.setUpHeaderNavigation = function () {
	let headerNavigation = this.headerNavigation;
	
	if (!headerNavigation) {
		const navigationElement = document.querySelector (".header-navigation-menu");
		
		if (navigationElement) {
			headerNavigation = this.headerNavigation = this.attachSprite (HeaderNavigation);
			headerNavigation.takeElement (navigationElement);
			
		}
		
	}
	
};

Site.prototype.setUpSectionControllers = function (element) {
	var sectionElements = element.querySelectorAll ("[data-class-name]");
	if (!sectionElements.length)
		sectionElements = [element];
	
	var sections = this.sections;
	for (var i = sections.length; i--;) {
		var section = sections [i];
		section.isInUse = false;
		
	}
	
	for (var i = 0; i < sectionElements.length; i++) {
		var sectionElement = sectionElements [i];
		var section = sectionElement.section;
		
		if (!section) {
			sectionElement.setAttribute ("data-processed", "yes");
			
			var sectionClassName = sectionElement.getAttribute ("data-class-name");
			
			section = sectionElement.section = this.attachSprite (window [sectionClassName] || SiteSection);
			section.takeElement (sectionElement);
			
			sections.push (section);
			
		}
		
		section.isInUse = true;
		
	}
	
	for (var i = sections.length; i--;) {
		var section = sections [i];
		if (!section.isInUse)
			sections.splice (i, 1);
		
	}
	
};

Site.prototype.setUpShareLinks = function () {
	const shareLinks = document.querySelectorAll ("a.share-link");
	
	for (let i = shareLinks.length; i--;) {
		const shareLink = shareLinks [i];
		shareLink.addEventListener ("click", function (event) {
			event.preventDefault ();
			Site.sharePage ();
			
		});
		
	}
	
};

Site.prototype.setUpScrollDownAnimation = function () {
	const firstSection = this.sections [0];
	if (!firstSection || !(firstSection instanceof VignetteSection))
		return;
	
	let scrollDownAnimation = this.scrollDownAnimation;
	if (!scrollDownAnimation) {
		scrollDownAnimation = this.scrollDownAnimation = this.attachSprite (ScrollDownAnimation);
		firstSection.element.appendChild (scrollDownAnimation.element);
		
	}
	
};

Site.prototype.resize = function (sender) {
	const mainElement = this.mainElement;
	
	const selfBounds = mainElement.getBoundingClientRect ();
	const containerBounds = mainElement.firstElementChild.getBoundingClientRect ();
	
	const paddingLeft = containerBounds.left - selfBounds.left;
	SiteSection.paddingLeft = paddingLeft || 0;
	
	const viewportSize = this.size;
	const sections = this.sections;
	
	for (let i = sections.length; i--;) {
		const section = sections [i];
		section.setViewSize (viewportSize);
		
	}
	
	const headerNavigation = this.headerNavigation;
	if (headerNavigation)
		headerNavigation.updateLayout ();
	
	const viewSize = this.size;
	const scrollBar = this.scrollBar;
	if (scrollBar)
		scrollBar.setViewSize (viewSize);
	
	if (sender)
		this.renderInContext ();
	
	this.scroll ();
	
};

Site.prototype.scroll = function (sender) {
	var size = this.size;
	var sections = this.sections;
	
	for (var i = sections.length; i--;) {
		var section = sections [i];
		var sectionElement = section.element;
		
		var pageBounds = section.pageBounds = sectionElement.getBoundingClientRect ();
		
		if (pageBounds.height &&
			pageBounds.top < size [1] * 1.25 &&
			pageBounds.bottom > -size [1] * .5) {
			if (!section.didStartLoading) {
				section.startLoading ();
				
			}
			
		}
		
		if (pageBounds.height &&
			pageBounds.top < size [1] + (section.viewOffsetTop || 0) &&
			pageBounds.bottom > 0) {
			if (!section.isAwake)
				section.awake ();
			
			section.scroll (!sender);
			
		} else {
			if (section.isAwake)
				section.sleep ();
			
			continue;
			
		}
		
	}
	
	const scrollBar = this.scrollBar;
	const scrollOffset = this.scrollOffset = RenderContext.getScrollOffset ();
	
	if (scrollBar) {
		const documentHeight = document.body.offsetHeight;
		
		scrollBar.setParameters (scrollOffset [1], documentHeight);
		
	}
	
	const footerElement = this.footerElement;
	this.setUseDarkBackground (
		footerElement.getBoundingClientRect ().top < size [1] && scrollOffset [1] > 100
		
	);
	
	const scrollDownAnimation = this.scrollDownAnimation;
	if (scrollDownAnimation) {
		const firstSection = sections [0];
		const pageBounds = firstSection.pageBounds;
		
		const cropBottom = Math.max (0, pageBounds.bottom - size [1]);
		scrollDownAnimation.element.style.bottom = cropBottom + "px";
		
		if (scrollOffset [1] >= 10)
			scrollDownAnimation.sleep ();
		else
			scrollDownAnimation.awake ();
		
	}
	
	var lazyLoader = this.lazyLoader;
	lazyLoader.update ();
	
};

Site.prototype.setUseDarkBackground = function (usesDarkBackground) {
	if (this.usesDarkBackground == usesDarkBackground)
		return;
	
	this.usesDarkBackground = usesDarkBackground;
	
	document.body.style.backgroundColor = usesDarkBackground ? "#404040" : "";
	
};

Site.prototype.scrollToOffset = function (targetOffset) {
	var scrollOffset = RenderContext.getScrollOffset () [1];
	var scrollStart = this.scrollStart = scrollOffset;
	
	if (scrollStart != targetOffset || this.scrollEnd != targetOffset) {
		this.scrollEnd = targetOffset;
		this.isScrolling = true;
		
		var delta = Math.abs (scrollStart - targetOffset);
		this.startAnimation ("Scroll", {direction: 1, rate: Math.max (
			.01, .1 / 4 / Math.max (
				1, delta / 640
				
			)
			
		) * 1, phase: 0});
		
	}
	
};

Site.prototype.animateScroll = function () {
	var state = this.updatedState ("Scroll");
	var t = 1 - state.phase;
	t = 1 - t * t * t;
	t = .5 - Math.cos (t * Math.PI) / 2;
	var t_ = 1 - t;
	
	if (Math.abs (this.scrollStart * t_ + this.scrollEnd * t - this.scrollEnd) < 1)
		this.isScrolling = false;
	
	var scrollTop = Math.round (this.scrollStart * t_ + this.scrollEnd * t);
	window.scroll (0, scrollTop);
	
};

Site.prototype.dragScrollBar = function (scrollBar) {
	var contentOffset = scrollBar.contentOffset;
	
	window.scrollTo (
		0,
		contentOffset
		
	);
	
};

Site.prototype.openVideoLayerFromSourceElement = function (sourceElement) {
	var videoController = this.videoController;
	if (!videoController) {
		videoController = this.videoController = this.attachSprite (OverlayVideoPlayer);
		document.body.appendChild (videoController.element);
		
	}
	
	videoController.startVideoWithSourceElement (sourceElement);
	
};

Site.prototype.addMutationObserver = function () {
	var mutationObserver = new MutationObserver (this.mutateElement.bind (this));
	mutationObserver.observe (this.element, {
		childList: true,
		subtree: true
		
	});
	
};

Site.prototype.mutateElement = function (mutations) {
	var now = new Date ().getTime ();
	
	var nextTimeToObserve = this.nextTimeToObserve;
	
	if (now < nextTimeToObserve) {
		if (!this.mutationHandler) {
			this.mutationHandler = window.setTimeout (
				this.handleMutation.bind (this),
				nextTimeToObserve - now
				
			);
			
		}
		
	} else {
		this.handleMutation ();
		
	}
	
};

Site.prototype.handleMutation = function () {
	this.mutationHandler = null;
	
	var now = new Date ().getTime ();
	var nextTimeToObserve = this.nextTimeToObserve = now + 500;
	
	// trace ("---- handle mutation ----");
	
	this.isMutating = true;
	
	this.setUpContent ();
	
	var element = this.element;
	
	this.resize (this);
	
	this.dispatchEvent ("mutate");
	
	this.isMutating = false;
	
};

Site.prototype.keyUpDown = function (event) {
	this.isShiftDown = event.shiftKey;
	
};

//
// Site.Scrollbar extends AnimatableSprite
//

Site.Scrollbar = function (context) {
	AnimatableSprite.apply (this, arguments);
	
	const element = this.element;
	element.classList.add ("site-scroll-bar");
	
	const knob = this.knob = document.createElement ("div");
	knob.classList.add ("site-scroll-bar-knob");
	element.appendChild (knob);
	
	this.addListener ("mousedown", this.beginDrag, this);
	
	this.addListener ("mouseover", this.mouseOver, this);
	this.addListener ("mouseout", this.mouseOut, this);
	
};

Site.Scrollbar.prototype = Object.create (AnimatableSprite.prototype);

Site.Scrollbar.prototype.renderSelfInContext = function () {
	
};

Site.Scrollbar.prototype.attachToElement = function (attachedElement) {
	var lastAttachedElement = this.attachedElement;
	if (lastAttachedElement) {
		// lastAttachedElement.removeEventListener ("scroll", this.scroll);
		
	}
	
	this.attachedElement = attachedElement;
	if (attachedElement) {
		// attachedElement.addEventListener ("scroll", this.scroll);
		
	}
	
	// this.markActivity ();
	
};

Site.Scrollbar.prototype.setViewSize = function (viewSize) {
	this.viewSize = viewSize;
	
	var attachedElement = this.attachedElement;
	
	// trace ("off heiht", attachedElement.offsetHeight);
	
	this.marginTop = 6;
	this.marginBottom = 6;
	
};

Site.Scrollbar.prototype.setParameters = function (scrollOffset, documentHeight, silent) {
	this.scrollOffset = scrollOffset;
	this.documentHeight = documentHeight;
	
	this.updateLayout (silent);
	
};

Site.Scrollbar.prototype.updateLayout = function (silent) {
	var viewSize = this.viewSize;
	var scrollOffset = this.scrollOffset;
	var documentHeight = this.documentHeight;
	
	var element = this.knob;
	
	if (viewSize [1] >= documentHeight) {
		element.style.display = "none";
		
	} else {
		element.style.display = "";
		
		var percentage = viewSize [1] / documentHeight;
		
		var selfHeight = viewSize [1] - this.marginTop - this.marginBottom;
		
		var top = scrollOffset / (documentHeight - viewSize [1]) * (selfHeight - selfHeight * percentage) + this.marginTop;
		var bottom = top + selfHeight * percentage;
		
		top = Math.max (this.marginTop, Math.min (selfHeight, top));
		bottom = Math.max (0, Math.min (selfHeight + this.marginTop, bottom));
		
		element.style.top = Math.round (top) + "px";
		element.style.height = Math.round (bottom - top) + "px";
		
	}
	
	if (!silent)
		this.markActivity ();
	
};

Site.Scrollbar.prototype.beginDrag = function (sender) {
	var event = sender.currentEvent;
	event.preventDefault ();
	
	var stage = this.getStage ();
	
	stage.addListener ("mousemove", this.doDrag, this);
	stage.addListener ("mouseup", this.endDrag, this);
	
	var startMouse = this.startMouse = this.touchDescriptionForObject (event);
	var startKnob = this.startKnob = parseFloat (this.knob.style.top) || 0;
	
	this.isDragging = true;
	this.markActivity ();
	
};

Site.Scrollbar.prototype.doDrag = function (stage) {
	var event = stage.currentEvent;
	event.preventDefault ();
	
	var mouse = this.touchDescriptionForObject (stage.currentEvent);
	var startMouse = this.startMouse;
	
	var delta = [
		mouse [0] - startMouse [0],
		mouse [1] - startMouse [1]
		
	];
	
	var knobHeight = parseFloat (this.knob.style.height);
	
	var selfHeight = this.viewSize [1] - this.marginTop - this.marginBottom;
	var knobTop = Math.max (this.marginTop,
		Math.min (
			selfHeight - knobHeight + this.marginTop,
			this.startKnob + delta [1]
			
		)
		
	);
	
	this.knob.style.top = knobTop + "px";
	
	var contentOffset = this.contentOffset = Math.round (
		(knobTop - this.marginTop) / (selfHeight - knobHeight) * (this.documentHeight - this.viewSize [1])
		
	);
	
	if (isNaN (contentOffset))
		debugger;
	
	// this.attachedElement.scrollTop = contentOffset;
	
	this.dispatchEvent ("drag");
	
};

Site.Scrollbar.prototype.endDrag = function (stage) {
	var event = stage.currentEvent;
	event.preventDefault ();
	
	stage.removeListener ("mousemove", this.doDrag, this);
	stage.removeListener ("mouseup", this.endDrag, this);
	
	this.isDragging = false;
	this.markActivity ();
	
};

Site.Scrollbar.prototype.mouseOver = function (sender) {
	this.isMouseOver = true;
	
	this.markActivity ();
	
};

Site.Scrollbar.prototype.mouseOut = function (sender) {
	this.isMouseOver = false;
	
};

Site.Scrollbar.prototype.markActivity = function () {
	this.fadeIn (4);
	
	if (this.timeoutHandle)
		window.clearTimeout (this.timeoutHandle);
	
	this.timeoutHandle = window.setTimeout (this.expireActivity.bind (this), 1000);
	
};

Site.Scrollbar.prototype.expireActivity = function () {
	// if (FAST_PASS)
	//	return;
	
	if (!this.isDragging && !this.isMouseOver) // && this.scrollOffset > 10)
		this.fadeOut ();
	
};

//
// ScrollDownAnimation extends AnimatableSprite
//

var ScrollDownAnimation = function (context) {
	AnimatableSprite.apply (this, arguments);
	
	const element = this.element;
	element.classList.add ("scroll-down-indicator");

	const dot = this.dot = document.createElement ("div");
	element.appendChild (dot);
	
	dot.style.display = "none";
	
	element.addEventListener ("click", function (event) {
		const stage = this.getStage ();
		const firstSection = stage.sections [0];
		
		const bodyTop = document.body.getBoundingClientRect ().top;
		const sectionBottom = firstSection.element.getBoundingClientRect ().bottom;
		
		stage.scrollToOffset (
			sectionBottom - bodyTop
			
		);
		
	}.bind (this));
	
};

ScrollDownAnimation.PULSE_DURATION = 5000;

ScrollDownAnimation.prototype = Object.create (AnimatableSprite.prototype);

ScrollDownAnimation.prototype.renderSelfInContext = function () {
	
};

ScrollDownAnimation.prototype.awake = function () {
	if (this.shouldAnimate)
		return;
	
	this.shouldAnimate = true;
	
	let now = new Date ().getTime ();
	if (isNaN (this.pulseStart))
		now += 4250;
	else
		now += 1250;
	
	this.pulseStart = now;
	
	this.addRunLoopHandler ("animatePulse");
	
};

ScrollDownAnimation.prototype.sleep = function () {
	if (!this.shouldAnimate)
		return;
	
	this.shouldAnimate = false;
	
};

ScrollDownAnimation.prototype.animatePulse = function () {
	if (!this.parent.isAwake)
		return;
	
	const now = new Date ().getTime ();
	const dot = this.dot;
	
	if (now < this.pulseStart) {
		dot.style.display = "none";
		return;
		
	}
	
	const pulseDuration = ScrollDownAnimation.PULSE_DURATION;
	
	let t = Math.max (0, Math.min (1,
		(now - this.pulseStart) / pulseDuration
		
	));
	
	if (t >= 1) {
		if (!this.shouldAnimate) {
			dot.style.display = "none";
			this.removeRunLoopHandler ("animatePulsingArrow");
			
			return;
			
		} else {
			this.pulseStart = now;
			
		}
		
	}
	
	
	t = Math.max (0, Math.min (1,
		1 - t * 4
		
	));
	
	dot.style.opacity = Math.min (1, Math.min ((1 - t) * 10, t * 3));
	
	let amp = .5 - Math.cos (t * Math.PI) * .5;
	amp = .5 - Math.cos (amp * Math.PI) * .5
	amp = .5 - Math.cos (amp * Math.PI) * .5
	
	dot.style.transform = "translateY(" + -(1-amp) * 50 + "px)";
	
	// trace ("dot.style.transform",dot.style.transform);
	
	dot.style.display = "block";
	
};
