import * as Environment from "./Environment.js";

//
// runtime environment
//

window.trace = function (message) {
	var debugOut = document.getElementById ("debugOut");
	if (!debugOut)
		return;
	
	var MAX_NUM_DEBUG_ENTRIES = 20;
	
	var entry = document.createElement ("pre");
	var serial = "";
	for (var i = 0; i < arguments.length; i++)
		serial += (i ? " " : "") + arguments [i];
	entry.appendChild (document.createTextNode (serial));
	
	debugOut.appendChild (entry);
	
	while (debugOut.childNodes.length > MAX_NUM_DEBUG_ENTRIES)
		debugOut.removeChild (debugOut.firstChild);
	
};

if (!window.console)
	window.console = {log: function (message) {}}; //trace};

//
// DOM Utils
//

var augmentDOMEventDispatcher = function (domEventDispatcher) {
	if (!domEventDispatcher.addEventListener) {
		domEventDispatcher.addEventListener = function (type, callback, useCapture) {
			this.attachEvent ("on" + type, callback);
			
		};
		domEventDispatcher.removeEventListener = function (type, callback, useCapture) {
			this.detachEvent ("on" + type, callback);
			
		};
		
	}
	
};

window.augmentDOMEventDispatcher = augmentDOMEventDispatcher;

if (!window.addEventListener)
	augmentDOMEventDispatcher (window);

var cancelEvent = function (event) {
	if (event.preventDefault)
		event.preventDefault ();
	else
		event.returnFalse = true;
	
};

window.cancelEvent = cancelEvent;

var stopEventPropagation = function (event) {
	if (event.stopPropagation)
		event.stopPropagation ();
	else
		event.cancelBubble = true;
	
};

window.stopEventPropagation = stopEventPropagation;

var VENDOR_PREFIX = function () {
	var vendorPrefixes = {
		ie: "ms",
		webkit: "webkit",
		mozilla: "Moz"
		
	};
	return vendorPrefixes [Environment.USER_AGENT];
	
} ();

var assignVendorCSSProperty = function (style, key, value) {
	if (style [key] != value) {
		style [key] = value;
		
		if (VENDOR_PREFIX) {
			var upperCaseKey = key.charAt (0).toUpperCase () + key.substring (1);
			style [VENDOR_PREFIX + upperCaseKey] = value;
			
		}
		
	}
	
}

window.assignVendorCSSProperty = assignVendorCSSProperty;

//
// Utils
//

export const parseTuple = function (alignment) {
	if (alignment) {
		alignment = alignment.split (";");
		return [
			parseFloat (alignment [0]),
			parseFloat (alignment [1])
			
		];
		
	} else {
		return undefined;
		
	}
	
}

//
// Object extensions
//

if (!Object.create) {
	Object.create = function (prototype) {
		var constructor = function () {};
		constructor.prototype = prototype;
		return new constructor ();
		
	};
	
}

//
// Function extensions
//

Function.prototype.getSharedInstance = function () {
	if (!this.sharedInstance)
		this.sharedInstance = new this ();
	
	return this.sharedInstance;
	
};

Function.prototype.extendConstructor = function (constructor) {
	var prototype = constructor.prototype;
	for (var propertyName in this)
		if (this.hasOwnProperty (propertyName))
			prototype [propertyName] = this [propertyName];
	
};

Function.prototype.extendPrototype = function (constructor) {
	constructor.extendConstructor (this);
	
};

if (!Function.prototype.bind) {
	// https://developer.mozilla.org/de/docs/Web/JavaScript/Reference/Global_Objects/Function/bind
	
	Function.prototype.bind = function (oThis) {
		if (typeof this !== 'function') {
			// closest thing possible to the ECMAScript 5 internal IsCallable function
			throw new TypeError (
				"Function.prototype.bind - what is trying to be bound is not callable"
				
			);
			
		}
		
		var aArgs = Array.prototype.slice.call (arguments, 1),
		fToBind  = this,
		fNOP = function () {},
		fBound = function () {
			return fToBind.apply (
				this instanceof fNOP ?
					this : oThis,
				aArgs.concat (Array.prototype.slice.call (arguments))
				
			);
			
		};
		
		fNOP.prototype = this.prototype;
		fBound.prototype = new fNOP ();
		
		return fBound;
		
	};
	
}

//
// Array extensions
//

if (!Array.prototype.indexOf) {
	Array.prototype.indexOf = function (sought, fromIndex) {
		var i = fromIndex || 0;
		i = i >= 0 ? i : Math.max (0, this.length + i);
		
		for (; i < this.length; i++)
			if (this [i] === sought)
				return i;
		
		return -1;
		
	};
	
}

Array.prototype.toMap = function () {
	var map = new Object ();
	for (var i = this.length; i--;)
		map [this [i]] = true;
	
	return map;
	
};

//
// String extensions
//

if (!String.prototype.trim) {
	String.prototype.trim = function () {
		return this.replace (/^\s+|\s+$/g, '');
		
	};
	
}

//
// class QueryMap
//

var QueryMap = function (query) {
	if (!query)
		query = window.location.search;
	
	if (query) {
		if (query.indexOf ("?") == 0)
			query = query.substring (1);
		
		var queryParts = query.split ("&");
		
		for (var i = 0; i < queryParts.length; i++) {
			var part = queryParts [i].split ("=");
			this [part [0]] = decodeURIComponent (part [1]);
			
		}
		
	}
	
};

QueryMap.encodeQueryObject = function (queryObject) {
	var query;
	for (var key in queryObject) {
		var value = queryObject [key];
		if (value == undefined)
			continue;
		
		if (query)
			query += "&";
		else
			query = "";
		
		query += encodeURIComponent (key) + "=" + encodeURIComponent (value);
		
	}
	return query;
	
};

//
// Dispatcher extends Object
//

export const Dispatcher = function () {
	this.events = new Object ();
	
};

Dispatcher.prototype.addListener = function (eventType, callback, listener) {
	if (!callback && Environment.FAST_PASS)
		debugger;
	
	var listenerDescriptions = this.events [eventType];
	if (!listenerDescriptions) {
		listenerDescriptions = new Array ();
		this.events [eventType] = listenerDescriptions;
		
	}
	
	var listenerIndex = this.indexOfListener (listenerDescriptions, callback, listener);
	if (listenerIndex < 0) {
		listenerDescriptions.push ({
			callback: callback,
			listener: listener
			
		});
		
	}
	
};

Dispatcher.prototype.removeListener = function (eventType, callback, listener) {
	var listenerDescriptions = this.events [eventType];
	if (!listenerDescriptions)
		return;
	
	var index = this.indexOfListener (listenerDescriptions, callback, listener);
	if (index >= 0) {
		listenerDescriptions [index].didDispatch = true;
		listenerDescriptions.splice (index, 1);
		
		if (!listenerDescriptions.length)
			delete this.events [eventType];
		
	}
	
};

Dispatcher.prototype.indexOfListener = function (listenerDescriptions, callback, listener) {
	for (var i = listenerDescriptions.length; i--;) {
		var listenerDescription = listenerDescriptions [i];
		if (listenerDescription.callback === callback &&
			listenerDescription.listener === listener)
			return i;
		
	}
	return -1;
	
};

Dispatcher.prototype.dispatchEvent = function (eventType) {
	var listenerDescriptions = this.events [eventType];
	if (!listenerDescriptions)
		return;
	
	listenerDescriptions = listenerDescriptions.concat ();
	
	for (var i = listenerDescriptions.length; i--;) {
		var listenerDescription = listenerDescriptions [i];
		listenerDescription.didDispatch = false;
		
	}
	
	for (i = 0; i < listenerDescriptions.length; i++) {
		listenerDescription = listenerDescriptions [i];
		
		if (listenerDescription.didDispatch)
			continue;
		
			listenerDescription.callback.call (
				listenerDescription.listener,
				this
				
			);
		try {
			
		} catch (error) {
			console.log (
				"## error while dispatching event " + eventType + " from",
				this, "to", listenerDescription
				
			);
			throw error;
			
		}
		listenerDescription.didDispatch = true;
		
	}
	
};
