import * as Environment from "../base/Environment.js";
import {Dispatcher} from "../base/Base.js";
import * as MouseWheel from "../libs/mousewheel.js";

import {Animatable} from "../base/Display.js";
import {AnimatableSprite} from "../base/Display2D.js";

//
// ScrollingController extends Dispatcher
//

export const ScrollingController = function (context) {
	Dispatcher.apply (this, arguments);
	Animatable.apply (this, arguments);
	
	this.context = context;
	
}

ScrollingController.prototype = Object.create (Dispatcher.prototype);
Animatable.extendConstructor (ScrollingController);

ScrollingController.prototype.getMouseListenerTarget = function (eventType) {
	return window;
	
};

ScrollingController.prototype.disableMouseWheel = true;

ScrollingController.prototype.awake = function () {
	if (this.isAwake)
		return;
	this.isAwake = true;
	
	var that = this;
	var listenerTarget = this.getMouseListenerTarget ();
	
	if (!listenerTarget.addEventListener)
		augmentDOMEventDispatcher (listenerTarget);
	
	if (!this.processMouseWheelEventHandler)
		this.processMouseWheelEventHandler = function (event) {
			that.processMouseWheel (event || window.event);
			
		};
	
	if (!this.processTouchStartEventHandler)
		this.processTouchStartEventHandler = function (event) {
			that.processTouchStart (event || window.event);
			
		};
	
	if (Environment.IS_TOUCH_DEVICE)
		listenerTarget.addEventListener ("touchstart", this.processTouchStartEventHandler, false);
	else
		listenerTarget.addEventListener ("mousedown", this.processTouchStartEventHandler, false);
	
	if (!this.processTouchMoveEventHandler)
		this.processTouchMoveEventHandler = function (event) {
			that.processTouchMove.apply (that, arguments);
			
		};
	
	if (!this.processTouchEndEventHandler)
		this.processTouchEndEventHandler = function (event) {
			that.processTouchEnd.apply (that, arguments);
			
		};
	
	var trackingTarget = this.trackingTarget = Environment.IS_IE ? document : window;
	if (!trackingTarget.addEventListener)
		augmentDOMEventDispatcher (trackingTarget);
	
}

ScrollingController.prototype.sleep = function () {
	if (!this.isAwake)
		return;
	this.isAwake = false;
	
	var listenerTarget = this.getMouseListenerTarget ();
	
	if (Environment.IS_TOUCH_DEVICE)
		listenerTarget.removeEventListener ("touchstart", this.processTouchStartEventHandler, false);
	else
		listenerTarget.removeEventListener ("mousedown", this.processTouchStartEventHandler, false);
	
};

ScrollingController.prototype.addWheelListener = function () {
	if (this.hasWheelListener)
		return;
	this.hasWheelListener = true;
	
	var listenerTarget = this.getMouseListenerTarget () || window;
	if (!Environment.IS_TOUCH_DEVICE)
		addWheelListener (listenerTarget, this.processMouseWheelEventHandler, false);
	
};

ScrollingController.prototype.removeWheelListener = function () {
	if (!this.hasWheelListener)
		return;
	this.hasWheelListener = false;
	
	var listenerTarget = this.getMouseListenerTarget () || window;
	if (!Environment.IS_TOUCH_DEVICE)
		removeWheelListener (listenerTarget, this.processMouseWheelEventHandler, false);
	
};

ScrollingController.prototype.removeSuppressClickHandler = function () {
	this.didSuppressClick = false;
	
	var listenerTarget = this.getMouseListenerTarget ();
	listenerTarget.removeEventListener ("click", this.suppressClickHandler, true);
	
};

ScrollingController.TOUCH_MOVE_DIRECTION_HORIZONTAL = 0;
ScrollingController.TOUCH_MOVE_DIRECTION_VERTICAL = 1;

ScrollingController.prototype.defaultTouchDirectionLock = undefined; // ScrollingController.TOUCH_MOVE_DIRECTION_HORIZONTAL;

ScrollingController.prototype.processTouchStart = function (event, sender) {
	this.showHand (true);
	
	if (event.button)
		return;
	
	var touch = ScrollingController.touchDescriptionForEvent (event);
	if (touch.y < 0)
		return;
	
	delete this.touchDirectionLock;
	
	var isTouchEvent = event.type.indexOf ("touch") == 0;
	if (isTouchEvent) {
		var eventTarget = event.target;
		
		if (eventTarget && event.target.nodeName.toLowerCase () == "a") {
			// trace ("event.target", eventTarget.outerHTLM);
			
		} else {
//			cancelEvent (event);
			if (document.activeElement)
				document.activeElement.blur ();
			
		}
		
	} else {
		this.touchDirectionLock = this.defaultTouchDirectionLock;
		
	}
	
	this.isTouching = true;
		
	this.startTouch = touch;
	this.lastTouches = [touch];
	
	this.didFlickVertical = false;
	this.didFlickHorizontal = false;
	
	this.didDrag = false;
	this.didDragX = false;
	this.didDragY = false;
	
	if (!sender)
		this.setUpDragListeners (isTouchEvent, event);
	
	if (this.didSuppressClick)
		this.removeSuppressClickHandler ();
	
	this.currentEvent = event;
	this.dispatchEvent ("beginDrag");
	
};

ScrollingController.prototype.setUpDragListeners = function (isTouchEvent, event) {
	if (isTouchEvent) {
		window.addEventListener ("touchmove", this.processTouchMoveEventHandler, false);
		window.addEventListener ("touchend", this.processTouchEndEventHandler, false);
		window.addEventListener ("touchcancel", this.processTouchEndEventHandler, false);
		
	} else {
		if (this.skipEventCancellation) {
			this.skipEventCancellation = false;
			
		} else {
			if (event)
				cancelEvent (event);
			if (document.activeElement)
				document.activeElement.blur ();
			
			this.trackingTarget.addEventListener ("mousemove", this.processTouchMoveEventHandler, false);
			this.trackingTarget.addEventListener ("mouseup", this.processTouchEndEventHandler, false);
			
		}
		
	}
	
};

ScrollingController.prototype.processTouchMove = function (event, sender) {
	if (!document.body.classList.contains ("grab"))
		return;
	
	var isTouchEvent = event.type.indexOf ("touch") == 0;
	if (isTouchEvent && !this.isTouching)
		return;
	
	this.lastDragEvent = this.currentEvent = event;
	
	var startTouch = this.startTouch;
	var touch = ScrollingController.touchDescriptionForEvent (event);
	
	if (sender) {
		var frame = Site.sharedInstance.contentContainer.shopColumn;
		var frameBounds = frame.getBoundingClientRect ();
		
		touch.x += frameBounds.left;
		
	}
	
	var touchDirectionLock = this.touchDirectionLock;
	
	var delta = this.delta = {x: startTouch.x - touch.x, y: startTouch.y - touch.y};
	var deltaLength = delta.x * delta.x + delta.y * delta.y;
	if (deltaLength > 36)
		this.didDrag = true;
	
	if (touchDirectionLock == undefined) {
		if (deltaLength > 64) {
			var angle = (Math.atan2 (delta.y, delta.x) + Math.PI / 2) % Math.PI;
			if (angle < 0)
				angle += Math.PI;
			
			touchDirectionLock = this.touchDirectionLock =
				angle > Math.PI / 4 && angle < Math.PI / 4 * 3 ?
					ScrollingController.TOUCH_MOVE_DIRECTION_HORIZONTAL :
					ScrollingController.TOUCH_MOVE_DIRECTION_VERTICAL;
			
			if (touchDirectionLock == ScrollingController.TOUCH_MOVE_DIRECTION_VERTICAL)
				this.dispatchEvent ("cancelDragHorizontal");
			else
				this.dispatchEvent ("cancelDragVertical");
			
		}
		
	}
	
	if (!this.didDragX && Math.abs (delta.x) >= 2)
		this.didDragX = true;
	
	if (!this.didDragY && Math.abs (delta.y) >= 2)
		this.didDragY = true;
	
	var lastTouches = this.lastTouches;
	startTouch = lastTouches [lastTouches.length - 1];
	delta = {x: startTouch.x - touch.x, y: startTouch.y - touch.y};
	
	if (touchDirectionLock != ScrollingController.TOUCH_MOVE_DIRECTION_HORIZONTAL && this.didDragY && delta.y)
		this.dispatchEvent ("dragVertical");
	
	if (touchDirectionLock != ScrollingController.TOUCH_MOVE_DIRECTION_VERTICAL && this.didDragX && delta.x)
		this.dispatchEvent ("dragHorizontal");
	
	if (isTouchEvent && touchDirectionLock == ScrollingController.TOUCH_MOVE_DIRECTION_HORIZONTAL) {
		document.body.style.overflow = "hidden";
		document.body.style.touchAction = "none";
		
		cancelEvent (event);
		
	}
	
	lastTouches.push (touch);
	while (lastTouches.length > 8)
		lastTouches.shift ();
	
	if (this.isTouching && !this.didSuppressClick &&
		(Math.abs (touch.x - this.startTouch.x) > 4 || Math.abs (touch.y - this.startTouch.y) > 4)) {
		this.didSuppressClick = true;
		
		var suppressClickHandler = this.suppressClickHandler;
		if (!suppressClickHandler) {
			var that = this;
			suppressClickHandler = this.suppressClickHandler = function (event) {
				event = event || window.event;
				stopEventPropagation (event);
				
				cancelEvent (event);
				
				that.removeSuppressClickHandler ();
				
			};
			
		}
		
		var listenerTarget = this.getMouseListenerTarget ();
		listenerTarget.addEventListener ("click", suppressClickHandler, true);
		
	}
	
};

ScrollingController.prototype.processTouchEnd = function (event) {
	this.showHand (false);
	
	if (document.body.style.touchAction) {
		document.body.style.overflow = "";
		document.body.style.touchAction = "";
		
	}
	
	var isTouchEvent = event.type.indexOf ("touch") == 0;
	if (isTouchEvent && !this.isTouching) {
		this.didDrag = false;
		this.didDragX = false;
		this.didDragY = false;
		
		return;
		
	}
	
	if (this.didDrag)
		stopEventPropagation (event);
	
	if (event.type.indexOf ("move") < 0) {
		var touchDirectionLock = this.touchDirectionLock;
		
		var lastTouches = this.lastTouches.concat ();
		
		this.didFlickVertical = false;
		while (lastTouches.length > 1 && touchDirectionLock != ScrollingController.TOUCH_MOVE_DIRECTION_HORIZONTAL) {
			var touchA = lastTouches [lastTouches.length - 2];
			var touchB = lastTouches [lastTouches.length - 1];
			
			var timeDelta = touchB.time - touchA.time;
			var delta = (touchA.y - touchB.y) / timeDelta * ScrollingController.FLICK_ACCELERATION_SCALE;
			var fullTimeDelta = new Date ().getTime () - touchB.time;
			
			if (Math.abs (delta) > 4 && timeDelta > 2.5 && fullTimeDelta < 250) {
				this.didFlickVertical = true;
				this.flickDeltaY = delta;
				
				break;
				
			}
			lastTouches.splice (lastTouches.length - 2, 1);
			
		}
		
		this.didFlickHorizontal = false;
		lastTouches = this.lastTouches;
		
		while (lastTouches.length > 1 && touchDirectionLock != ScrollingController.TOUCH_MOVE_DIRECTION_VERTICAL) {
			var touchA = lastTouches [lastTouches.length - 2];
			var touchB = lastTouches [lastTouches.length - 1];
			
			var timeDelta = touchB.time - touchA.time;
			var delta = (touchA.x - touchB.x) / timeDelta * ScrollingController.FLICK_ACCELERATION_SCALE;
			var fullTimeDelta = new Date ().getTime () - touchB.time;
			
			if (Math.abs (delta) > 100 && timeDelta > 2.5 && fullTimeDelta < 250) {
				this.didFlickHorizontal = true;
				this.flickDeltaX = delta;
				
				break;
				
			}
			lastTouches.splice (lastTouches.length - 2, 1);
			
		}
		
	}
	
	this.isTouching = false;
	this.removeTouchListeners (isTouchEvent);
	
	this.currentEvent = event;
	this.dispatchEvent ("endDrag");
	
	window.setTimeout (function () {
		this.didDrag = false;
		this.didDragX = false;
		this.didDragY = false;
		
	}.bind (this), 50);	
	
};

ScrollingController.prototype.removeTouchListeners = function (isTouchEvent) {
	if (isTouchEvent) {
		window.removeEventListener ("touchmove", this.processTouchMoveEventHandler, false);
		window.removeEventListener ("touchend", this.processTouchEndEventHandler, false);
		window.removeEventListener ("touchcancel", this.processTouchEndEventHandler, false);
		
	} else {
		var trackingTarget = this.trackingTarget;
		if (trackingTarget) {
			trackingTarget.removeEventListener ("mousemove", this.processTouchMoveEventHandler, false);
			trackingTarget.removeEventListener ("mouseup", this.processTouchEndEventHandler, false);
			
		}
		
	}
	
	this.removeSuppressClickHandler ();
	
};

ScrollingController.FLICK_ACCELERATION_SCALE = 200;

ScrollingController.touchDescriptionForEvent = function (event) {
	var isTouchEvent = event.type.indexOf ("touch") == 0;
	
	var touch = {
		time: new Date ().getTime ()
		
	};
	if (isTouchEvent) {
		var touchItem = event.touches.item (0);
		touch.x = touchItem.pageX;
		touch.y = touchItem.pageY;
		
		var pageBounds = document.body.getBoundingClientRect ();
		touch.x -= pageBounds.left;
		touch.y -= pageBounds.top;
		
	} else {
		if (isNaN (event.pageX)) {
			touch.x = event.clientX;
			touch.y = event.clientY;
			
		} else {
			touch.x = event.pageX;
			touch.y = event.pageY;
			
		}
		
	};
	return touch;
	
};

ScrollingController.prototype.processMouseWheel = function (event) {
	event = this.currentEvent = Environment.IS_IE ? event : event.originalEvent || event;
	if (this.cancelHorizontalScroll && this.cancelVerticalScroll)
		event.preventDefault ();
	
	if (!this.isTouching) {
		var deltaX = event.deltaX;
		var deltaY = event.deltaY;
		
		this.delta = {x: deltaX * 2, y: deltaY * 2};
		
		if (Math.abs (deltaY * 2) > Math.abs (deltaX)) {
			if (this.cancelVerticalScroll) {
				event.preventDefault ();
				
				this.mouseWheelTrackingDelayY = 35;
				if (Math.abs (deltaY) > 4) {
					this.mouseWheelTrackingDelayX = 35;
					this.addRunLoopHandler ("trackMouseWheelX");
					
				}
				
				this.addRunLoopHandler ("trackMouseWheelY");
				this.dispatchEvent ("scrollVertical");
				
			}
			
		}
		
		this.mouseWheelTrackingDelayY = 35;
		if (Math.abs (deltaY) > 4) {
			this.mouseWheelTrackingDelayX = 0;
			
		} else if (Math.abs (deltaY * 2) <= Math.abs (deltaX)) {
			if (this.cancelHorizontalScroll) {
				event.preventDefault ();
				
				this.mouseWheelTrackingDelayX = 35;
				if (Math.abs (deltaX) > 4) {
					this.mouseWheelTrackingDelayY = 35;
					this.addRunLoopHandler ("trackMouseWheelY");
					
				}
				
				this.addRunLoopHandler ("trackMouseWheelX");
				this.dispatchEvent ("scrollHorizontal");
				
			}
			
		}
		
	}
	
};

ScrollingController.prototype.trackMouseWheelX = function () {
	if (this.mouseWheelTrackingDelayX--)
		return;
	
	this.removeRunLoopHandler ("trackMouseWheelX");
	this.dispatchEvent ("cancelScrollHorizontal");
	
};

ScrollingController.prototype.trackMouseWheelY = function () {
	if (this.mouseWheelTrackingDelayY--)
		return;
	
	this.removeRunLoopHandler ("trackMouseWheelY");
	this.dispatchEvent ("cancelScrollVertical");
	
};

ScrollingController.prototype.showHand = function (doShow) {
	var body = document.body;
	if (doShow)
		body.classList.add ("grab");
	else
		body.classList.remove ("grab");
	
};

ScrollingController.prototype.transferControlTo = function (scrollingController) {
	scrollingController.touchDirectionLock = this.touchDirectionLock;
	
	this.isTouching = false;
	scrollingController.isTouching = true;
	
	scrollingController.startTouch = this.startTouch;
	scrollingController.lastTouches = this.lastTouches;
	
	scrollingController.didDrag = this.didDrag;
	scrollingController.didDragX = this.didDragX;
	scrollingController.didDragY = this.didDragY;
	
	var event = scrollingController.currentEvent = this.currentEvent;
	scrollingController.dispatchEvent ("beginDrag");
	
	scrollingController.setUpDragListeners (event.type.indexOf ("touch") == 0, event);
	
	scrollingController.delta = this.delta;
	if (scrollingController.didDragX)
		scrollingController.dispatchEvent ("dragHorizontal");
	
	if (scrollingController.didDragY)
		scrollingController.dispatchEvent ("dragVertical");
	
	this.removeTouchListeners (false);
	this.removeTouchListeners (true);

};

//
// SlidingSprite extends AnimatableSprite
//

var SlidingSprite = function (context) {
	AnimatableSprite.apply (this, arguments);
	
	var scrollingController = this.scrollingController = ScrollingController.sharedInstance;
	if (!scrollingController)
		scrollingController = this.scrollingController = ScrollingController.sharedInstance =
			new ScrollingController (context);
	
	// scrollingController.awake ();
	
};

SlidingSprite.prototype = Object.create (AnimatableSprite.prototype);

SlidingSprite.prototype.viewOffset = 0;
SlidingSprite.prototype.currentViewOffset = 0;

SlidingSprite.prototype.updateViewOffset = function () {
	
};

SlidingSprite.prototype.boundedViewOffset = function (viewOffset) {
	return Math.max (-250, Math.min (250, viewOffset));
	
};

SlidingSprite.prototype.processSliding = function () {
	var currentViewOffset = this.currentViewOffset;
	var slidingInertia = this.slidingInertia;
	
	var delta =
		this.viewOffset * (1 - slidingInertia) +
		currentViewOffset * (slidingInertia - 1);
	
	var maxScrollSpeed = this.maxScrollSpeed;
	if (!isNaN (maxScrollSpeed)) {
		if (Math.abs (delta) > maxScrollSpeed)
			delta = maxScrollSpeed * (delta > 0 ? 1 : -1);
		
		this.maxScrollSpeed = Math.min (
			80,
			(maxScrollSpeed + 4) / 1.03
			
		);
		
	}
	
	this.currentViewOffset = currentViewOffset + delta; 
	
	var targetViewOffset = this.boundedViewOffset (this.viewOffset);
	
	var isBumping;
	if (targetViewOffset != this.viewOffset &&
		this.currentViewOffset != this.boundedViewOffset (this.currentViewOffset)) {
		
		var bumpingInertia = .5;
		this.slidingInertia = .85;
		
		this.viewOffset = targetViewOffset * (1 - bumpingInertia) + this.viewOffset * bumpingInertia;
		
		if (Math.abs (this.viewOffset - targetViewOffset) < .25)
			this.viewOffset = targetViewOffset;
		else
			isBumping = true;
		
	}
	this.isBumping = isBumping;
	
	if (!isBumping) {
		if (this.lockDragging && Math.abs (this.currentViewOffset - targetViewOffset) < 5)
			this.lockDragging = false;
		
		if (Math.abs (this.currentViewOffset - targetViewOffset) < .25)
			this.currentViewOffset = this.viewOffset = targetViewOffset;
		
		if (this.currentViewOffset == targetViewOffset)
			this.removeRunLoopHandler ("processSliding");
		
	}
	this.updateViewOffset ();
	
};

SlidingSprite.prototype.snapToBounds = function () {
	var targetViewOffset = this.boundedViewOffset (this.viewOffset);
	if (this.viewOffset != targetViewOffset) {
		this.slidingInertia = .85;
		this.viewOffset = targetViewOffset;
		this.addRunLoopHandler ("processSliding");
		
	}
	
};

SlidingSprite.prototype.beginDrag = function () {
	this.removeRunLoopHandler ("processSliding");
	this.startViewOffset = this.currentViewOffset;
	
};

SlidingSprite.prototype.cancelDrag = function (scrollingController) {
	this.snapToBounds ();
	
};

SlidingSprite.prototype.cancelScroll = function (scrollingController) {
	if (!scrollingController.isTouching)
		this.snapToBounds ();
	
};

//
// SlidingSpritePrototype
//

export const SlidingSpritePrototype = function (context) {
	var scrollingController = this.scrollingController =
		new ScrollingController (context);
	
	var that = this;
	if (this.getListenerTargetForScrollingController)
		scrollingController.getMouseListenerTarget = function (eventType) {
			return that.getListenerTargetForScrollingController (eventType);
			
		};
	
};

SlidingSpritePrototype.viewOffset = 0;
SlidingSpritePrototype.currentViewOffset = 0;

SlidingSpritePrototype.updateViewOffset = function () {
	
};

SlidingSpritePrototype.boundedViewOffset = function (viewOffset) {
	return Math.max (-250, Math.min (250, viewOffset));
	
};

SlidingSpritePrototype.processSliding = function () {
	var viewOffsetMap = this.viewOffsetMap;
	
	var currentViewOffset = viewOffsetMap ?
		viewOffsetMap ["default"] : this.currentViewOffset;
	var slidingInertia = this.slidingInertia;
	if (isNaN (slidingInertia))
		slidingInertia = 1;
	
	var delta =
		this.viewOffset * (1 - slidingInertia) +
		currentViewOffset * (slidingInertia - 1);
	
	var maxScrollSpeed = this.maxScrollSpeed;
	if (!isNaN (maxScrollSpeed)) {
		if (Math.abs (delta) > maxScrollSpeed)
			delta = maxScrollSpeed * (delta > 0 ? 1 : -1);
		
		var accelerationDescription = this.accelerationDescription || {
			maxSpeed: 25 * 3,
			acceleration: 4
			
		};
		this.maxScrollSpeed = Math.min (
			accelerationDescription.maxSpeed,
			(maxScrollSpeed + accelerationDescription.acceleration) / 1.03
			
		);
		
	}
	
	currentViewOffset = currentViewOffset + delta;
	
	/*
	if (this.context.animationTimer.framesDelta > 1.75) {
		delta =
			this.viewOffset * (1 - slidingInertia) +
			currentViewOffset * (slidingInertia - 1);
		
		currentViewOffset = currentViewOffset + delta;
		
	}
	*/
	
	if (viewOffsetMap)
		viewOffsetMap ["default"] = currentViewOffset;
	else
		this.currentViewOffset = currentViewOffset;
	
	var targetViewOffset = this.boundedViewOffset (this.viewOffset);
	
	var isBumping;
	if (targetViewOffset != this.viewOffset &&
		currentViewOffset != this.boundedViewOffset (currentViewOffset)) {
		
		var bumpingInertia = .5;
		this.slidingInertia = .85;
		
		this.viewOffset = targetViewOffset * (1 - bumpingInertia) + this.viewOffset * bumpingInertia;
		
		if (Math.abs (this.viewOffset - targetViewOffset) < .25) {
			this.viewOffset = targetViewOffset;
			
		} else {
			isBumping = true;
			this.bumpLockDirection = this.viewOffset > this.boundedViewOffset (this.viewOffset) ? 1 : -1;
			
		}
		
	}
	this.isBumping = isBumping;
	
	if (!isBumping) {
		if (this.lockDragging && Math.abs (currentViewOffset - targetViewOffset) < 5)
			this.lockDragging = false;
		
		if (Math.abs (currentViewOffset - targetViewOffset) < .25) {
			currentViewOffset = this.viewOffset = targetViewOffset;
			if (viewOffsetMap)
				viewOffsetMap ["default"] = currentViewOffset;
			else
				this.currentViewOffset = currentViewOffset;
			
		}
		
		if (currentViewOffset == targetViewOffset) {
			this.removeRunLoopHandler ("processSliding");
			this.dispatchEvent ("completeSliding");
			
		}
		
	}
	
	this.updateViewOffset ();
	
};

SlidingSpritePrototype.snapToBounds = function () {
	var viewOffsetMap = this.viewOffsetMap;
	var currentViewOffset = viewOffsetMap ?
		viewOffsetMap ["default"] : this.currentViewOffset;
	
	var targetViewOffset = this.boundedViewOffset (this.viewOffset);
	if (this.viewOffset != targetViewOffset ||
		currentViewOffset != targetViewOffset) {
		this.slidingInertia = .85;
		this.viewOffset = targetViewOffset;
		this.addRunLoopHandler ("processSliding");
		
	}
	
};

SlidingSpritePrototype.beginDrag = function (sender) {
	var event = sender.currentEvent;
	// event.stopPropagation ();
	
	this.removeRunLoopHandler ("processSliding");
	
	var viewOffsetMap = this.viewOffsetMap;
	var currentViewOffset = viewOffsetMap ?
		viewOffsetMap ["default"] : this.currentViewOffset;

	this.startViewOffset = this.viewOffset = currentViewOffset;
	
	this.bumpLockDirection = 0;
	
};

SlidingSpritePrototype.cancelDrag = function (scrollingController) {
	this.snapToBounds ();
	
};

SlidingSpritePrototype.cancelScroll = function (scrollingController) {
	this.bumpLockDirection = 0;
	
	if (!scrollingController.isTouching)
		this.snapToBounds ();
	
};

SlidingSpritePrototype.releaseDrag = function () {
	var scrollingController = this.scrollingController;
	
	scrollingController.removeTouchListeners (true);
	scrollingController.removeTouchListeners (false);
	
	scrollingController.isTouching = false;
	
	scrollingController.showHand (false);
	
	/*
	window.setTimeout (function () {
		if (!scrollingController.isTouching) {
			scrollingController.didDrag = false;
			scrollingController.didDragX = false;
			scrollingController.didDragY = false;
			
		}
		
	}, 50);
	*/
	
};
