import * as Environment from "../base/Environment.js";

import {Dispatcher} from "../base/Base.js";
import {RenderContext} from "../base/Display.js";

import {Site} from "../site/Site.js";

//
// LazyLoader extends Dispatcher
//

export const LazyLoader = function (element) {
	Dispatcher.apply (this, arguments);
	
	if (LazyLoader.sharedInstance) {
		// throw new Error ("Cannot instantiate. LazyLoader is singleton.");
		
	} else {
		LazyLoader.sharedInstance = this;
		
	}
	
	this.element = element = element || document;
	
	if (Environment.IS_IN_NEOS_EDITOR)
		Site.sharedInstance.addListener ("mutate", this.handleMutation, this);
	
	var images = this.images = Array.prototype.slice.call (
		element.querySelectorAll (Environment.IS_IE ?
			"picture.lazyload" :
			"picture.lazyload:not(.lazyload--started)"
			
		)
		
	);
	
	for (var i = images.length; i--;) {
		var image = images [i];
		LazyLoader.initializeImage (image);
		
	}
	
};

LazyLoader.initializeImage = function (image) {
	if (!Environment.IS_IE && image.classList.contains ("lazyload--started"))
		return;
	
	image.classList.add ("lazyload--started");
	
	var aspect =
		parseFloat (image.getAttribute ("height")) /
		parseFloat (image.getAttribute ("width"));
	
	/*
	if (aspect > 1)
		image.classList.add ("img-fluid--vertical");
	*/
	
	if (aspect) {
		image.style.height = 0;
		image.style.paddingBottom = aspect * 100 + "%";
		
	}
	
	image.style.visibility = "hidden";
	
};

LazyLoader.SUPPORTS_SRC_SET = "sizes" in new Image ();

LazyLoader.prototype = Object.create (Dispatcher.prototype);

LazyLoader.update = function () {
	this.sharedInstance.update ();
	
};

LazyLoader.prototype.update = function () {
	var images = this.images;
	if (!(images && images.length))
		return;
	
	var viewportSize = RenderContext.getViewportSize ();
	
	var images = this.images;
	for (var i = images.length; i--;) {
		var image = images [i];
		
		try {
			var imageBounds = image.getBoundingClientRect ();
			
			// if (!imageBounds.height)
			//	continue;
			
			if (imageBounds.top < viewportSize [1] * 1.5 /* &&
				imageBounds.bottom >= 0 */) {
				images.splice (i, 1);
				
				this.loadImageAndListen (image);
				
			}
			
		} catch (exception) {
			// IE fix
			
		}
		
	}
	
};

LazyLoader.prototype.loadImageAndListen = function (image) {
	image.lastElementChild.addEventListener ("load", image.loadListener = this.completeImage.bind (this));
	
	this.loadImage (image);
	
	if (image.lastElementChild.complete)
		this.completeImage ({currentTarget: image.lastElementChild});
	
};

LazyLoader.loadImage = LazyLoader.prototype.loadImage = function (image) {
	if (image.src || image.srcset)
		return;
	
	function copyAttribute (attributeName) {
		var value = child.getAttribute ("data-" + attributeName);
		if (value)
			child.setAttribute (attributeName, value);
		
	}
	
	var children = image.children;
	for (var i = children.length; i--;) {
		var child = children [i];
		
		copyAttribute ("src");
		copyAttribute ("srcset");
		copyAttribute ("sizes");
		/*
		if (child.src)
			trace ("src", child.src);
		*/
	}
	
};

LazyLoader.prototype.completeImage = function (event) {
	var image = event.currentTarget.parentNode;
	
	image.lastElementChild.removeEventListener ("load", image.loadListener);
	
	image.style.height = "";
	image.style.paddingBottom = "";
	image.style.visibility = "";
	
	image.classList.add ("lazyload--complete");
	
	image.completeHandler && image.completeHandler ();
	
	this.dispatchEvent ("progress");
	
	if (!this.images.length)
		this.dispatchEvent ("complete");
	
};

// Neos functions

LazyLoader.prototype.handleMutation = function () {
	// trace ("---- handle ----");
	
	var element = this.element;
	var images = element.querySelectorAll ("picture.lazyload:not(.lazyload--started)");
	
	for (var i = images.length; i--;) {
		var image = images [i];
		
		image.classList.add ("lazyload--started")
		image.classList.add ("lazyload--complete");
		
		// trace ("found image", image);
		
		this.loadImage (image);
		
	}
	
};
