import * as Environment from "../base/Environment.js";

import {RenderContext, AnimatableSprite} from "../base/Display2D.js";
import {ScrollingController, SlidingSpritePrototype} from "../components/Control.js";

import {SiteSection} from "./SiteSection.js";

//
// NavigationSection extends SiteSection
//

export const NavigationSection = function (context) {
	SiteSection.apply (this, arguments);
	
};

window.NavigationSection = NavigationSection;

NavigationSection.prototype = Object.create (SiteSection.prototype);

NavigationSection.prototype.takeElement = function (element) {
	SiteSection.prototype.takeElement.apply (this, arguments);
	
	const bigNavigation = this.bigNavigation = this.attachSprite (BigNavigation);
	bigNavigation.takeElement (element);
	
};

NavigationSection.prototype.setViewSize = function (viewSize) {
	SiteSection.prototype.setViewSize.apply (this, arguments);
	
	const bigNavigation = this.bigNavigation;
	bigNavigation.setViewSize (viewSize);
	
};

NavigationSection.prototype.awake = function () {
	SiteSection.prototype.awake.apply (this, arguments);
	
	const bigNavigation = this.bigNavigation;
	bigNavigation.awake ();
	
};

NavigationSection.prototype.sleep = function () {
	SiteSection.prototype.sleep.apply (this, arguments);
	
	const bigNavigation = this.bigNavigation;
	bigNavigation.sleep ();
	
};

//
// BigNavigation extends AnimatableSprite, SlidingSpritePrototype
//

const BigNavigation = function (context) {
	AnimatableSprite.apply (this, arguments);
	
};

BigNavigation.prototype = Object.create (AnimatableSprite.prototype);
BigNavigation.extendPrototype (SlidingSpritePrototype);

BigNavigation.prototype.takeElement = function (element) {
	this.element.parentNode.removeChild (this.element);
	this.element = element;
	
	const scrollContainer = this.scrollContainer = element.firstElementChild;
	
	const items = this.items = scrollContainer.querySelectorAll ("a");
	for (let i = 0; i < items.length; i++) {
		const item = items [i];
		scrollContainer.appendChild (item.cloneNode (true));
		
	}
	
	const allItems = scrollContainer.querySelectorAll ("a");
	for (let i = 0; i < allItems.length; i++) {
		const item = allItems [i];
		item.addEventListener ("click", function (event) {
			if (this.scrollingController.didDrag)
				event.preventDefault ();
			
		}.bind (this));
		
	}
	
	this.setUpScrolling ();
	this.updateViewOffset ();
	
};

BigNavigation.prototype.awake = function () {
	this.isAwake = true;
	
	this.addRunLoopHandler ("processAutoScroll");
	
	if (this.needsUpdateViewOffset)
		this.updateViewOffset ();
	
};

BigNavigation.prototype.sleep = function () {
	this.isAwake = false;
	
	this.removeRunLoopHandler ("processAutoScroll");
	
};

BigNavigation.prototype.scrollSpeed = 0;

BigNavigation.prototype.processAutoScroll = function () {
	let scrollSpeed = this.scrollSpeed;
	
	if (this.viewOffset != this.currentViewOffset)
		return;
	
	if (!this.isDragging) {
		scrollSpeed = this.scrollSpeed = Math.min (.5, scrollSpeed + .005);
		
	}
	
	const viewOffset = this.currentViewOffset + (scrollSpeed) * this.context.animationTimer.framesDelta;
	
	this.viewOffset = this.currentViewOffset = viewOffset;
	this.updateViewOffset ();
	
};

BigNavigation.prototype.getListenerTargetForScrollingController = function () {
	return this.element;
	
};

BigNavigation.prototype.setUpScrolling = function () { 
	SlidingSpritePrototype.call (this, this.context);
	
	const element = this.element;
	const scrollingController = this.scrollingController;
	
	scrollingController.getMouseListenerTarget = function () {
		return element;
		
	};
	
	scrollingController.defaultTouchDirectionLock = ScrollingController.TOUCH_MOVE_DIRECTION_HORIZONTAL

	scrollingController.awake ();
	scrollingController.addWheelListener ();
	scrollingController.cancelHorizontalScroll = true;
	
	scrollingController.addListener ("beginDrag", this.beginDrag, this);
	scrollingController.addListener ("dragHorizontal", this.dragHorizontal, this);
	scrollingController.addListener ("cancelDragHorizontal", this.endDragHorizontal, this);
	scrollingController.addListener ("endDrag", this.endDragHorizontal, this);
	
	scrollingController.addListener ("scrollHorizontal", this.scrollHorizontal, this);
	scrollingController.addListener ("cancelScrollHorizontal", this.cancelScrollHorizontal, this);
	
	if (!this.IS_TOUCH_DEVICE) {
		this.addListener ("mouseover", this.mouseOver, this);
		this.addListener ("mouseout", this.mouseOut, this);
		
	}
	
};

BigNavigation.prototype.mouseOver = function (event) {
	this.mouseAcc = 0;
	
	this.isMouseOver = true;
	this.getStage ().addListener ("mousemove", this.mouseMove, this);
	
	this.currentMouse = this.touchDescriptionForObject (event);
	
};

BigNavigation.prototype.mouseOut = function (event) {
	this.mouseSpeed = 0;
	
	this.setHoveredItem ();
	
	this.isMouseOver = false;
	this.getStage ().removeListener ("mousemove", this.mouseMove, this);
	
};

BigNavigation.prototype.mouseSpeed = 0;
BigNavigation.prototype.mouseAcc = 0;

BigNavigation.prototype.mouseMove = function (stage) {
	const viewSize = this.viewSize;
	
	const mouse = this.currentMouse = this.touchDescriptionForObject (stage.currentEvent);
	const xOff = mouse [0];
	
	const reactionArea = Math.min (viewSize [0] < 640 ? 20 : 40, viewSize [0] * .1);
	
	const mouseSpeed = this.mouseSpeed = xOff < viewSize [0] * .5 ?
		-Math.max (
			0,
			7.5 - xOff / reactionArea
			
		) : Math.max (
			0,
			7.5 - (viewSize [0] - xOff) / reactionArea
			
		);
	
	this.addRunLoopHandler ("processMouseSpeed");
	
};

BigNavigation.prototype.processMouseSpeed = function () {
	if (this.isDragging)
		return;
	
	const mouseSpeed = this.mouseSpeed;
	let mouseAcc = this.mouseAcc;
	
	if (mouseSpeed) {
		mouseAcc = this.mouseAcc = (mouseAcc * 19 + mouseSpeed) / 20 + this.scrollSpeed;
		this.viewOffset = this.viewOffset + mouseAcc * 3;
		
		this.scrollSpeed = 0;
		
		this.slidingInertia = .9;
		this.addRunLoopHandler ("processSliding");
		
	} else {
		this.mouseAcc = 0;
		this.removeRunLoopHandler ("processMouseSpeed");
		
	}
	
};

BigNavigation.prototype.beginDrag = function (scrollingController) {
	SlidingSpritePrototype.beginDrag.apply (this, arguments);
	
	this.scrollSpeed = 0;
	this.mouseAcc = 0;
	this.isDragging = true;
	
};

BigNavigation.prototype.boundedViewOffset = function (viewOffset) {
	return viewOffset;
	
};

BigNavigation.prototype.dragHorizontal = function (scrollingController) {
	var deltaX = scrollingController.delta.x;
	this.removeRunLoopHandler ("processSliding");
	
	this.viewOffset = this.currentViewOffset = this.startViewOffset + deltaX;
	this.updateViewOffset ();
	
};

BigNavigation.prototype.endDragHorizontal = function (scrollingController) {
	this.isDragging = false;
	
	const didFlick = scrollingController.didFlickHorizontal;
	if (didFlick) {
		const targetViewOffset = this.viewOffset = this.viewOffset + scrollingController.flickDeltaX * 1.4;
		
		this.slidingInertia = .9;
		this.addRunLoopHandler ("processSliding");
		
	} else {
		this.snapToBounds ();
		
	}
	
};

BigNavigation.prototype.scrollHorizontal = function (scrollingController) {
	this.scrollSpeed = 0;
	
	if (scrollingController.isDragging)
		return;
	
	if (this.isWheelLocked)
		return;
	
	if (new Date ().getTime () < this.scrollTimeOut)
		return;
	
	var delta = scrollingController.delta.x;
	const targetViewOffset = this.viewOffset + delta * 1.5;
	
	if (!this.isBumping) {
		if (!(this.bumpLockDirection * scrollingController.delta.x > 0)) {
			this.bumpLockDirection = 0;
			
			this.viewOffset = targetViewOffset;
			this.slidingInertia = .7;
			
			this.addRunLoopHandler ("processSliding");
			
			this.updateViewOffset ();
			
		}
		
	}
	
};

BigNavigation.prototype.cancelScrollHorizontal = function (scrollingController) {
	
};

BigNavigation.prototype.updateViewOffset = function () {
	if (!this.isAwake) {
		this.needsUpdateViewOffset = true;
		return;
		
	}
	
	const maxScrollOffset = this.maxScrollOffset;
	if (isNaN (maxScrollOffset))
		return;
	
	this.needsUpdateViewOffset = false;
	
	let viewOffset = this.currentViewOffset % maxScrollOffset;
	if (viewOffset < 0)
		viewOffset += maxScrollOffset;
	
	const scrollContainer = this.scrollContainer;
	scrollContainer.style.transform = "translateX(" + -(viewOffset) + "px)";
	
	const padding = this.getStage ().size [0] < 767 ? 95 : 200;
	
	if (this.isMouseOver) {
		const mouse = this.currentMouse;
		const children = scrollContainer.children;
		
		for (var i = children.length; i--;) {
			const child = children [i];
			const childBounds = child.getBoundingClientRect ();
			
			if (childBounds.right <= 0) {
				i = -1;
				break;
				
			}
			
			if (mouse [0] >= childBounds.left && mouse [0] < childBounds.right - padding) {
				this.setHoveredItem (child);
				break;
				
			}
			
		}
		
		if (i < 0)
			this.setHoveredItem ();
		
	}
	
};

BigNavigation.prototype.setHoveredItem = function (hoveredItem) {
	const lastHoveredItem = this.hoveredItem;
	if (lastHoveredItem == hoveredItem)
		return;
	
	if (lastHoveredItem)
		lastHoveredItem.classList.remove ("hovered");
	
	this.hoveredItem = hoveredItem;
	
	if (hoveredItem)
		hoveredItem.classList.add ("hovered");
	
};

BigNavigation.prototype.setViewSize = function (viewSize) {
	this.viewSize = viewSize;
	
	const element = this.element;
	
	const selfBounds = element.getBoundingClientRect ();
	const containerBounds = element.firstElementChild.getBoundingClientRect ();
	
	const scrollContainer = this.scrollContainer;
	this.maxScrollOffset = scrollContainer.offsetWidth * .5;
	
};
