import {Stage} from "./base/Display2D.js";
import {Site} from "./site/Site.js";

//
// setup
//

var controllerElement = document.querySelector ("main");
if (controllerElement) {
	var controller = Stage.fromElement.call (
		Site, controllerElement
		
	);
	
}
