import * as Environment from "../base/Environment.js";

import {AnimatableSprite} from "../base/Display2D.js";
import {ScrollingController, SlidingSpritePrototype} from "../components/Control.js";

//
// HeaderNavigation extends AnimatableSprite, SlidingSpritePrototype
//

export const HeaderNavigation = function (context) {
	AnimatableSprite.apply (this, arguments);
	
};

HeaderNavigation.USE_TOUCH_CONTROL = Environment.IS_TOUCH_DEVICE;

HeaderNavigation.prototype = Object.create (AnimatableSprite.prototype);
HeaderNavigation.extendPrototype (SlidingSpritePrototype);

HeaderNavigation.prototype.takeElement = function (element) {
	this.element.parentNode.removeChild (this.element);
	
	this.element = element;
	
	const expansionContainer = this.expansionContainer = element.firstElementChild;
	const scrollContainer = this.scrollContainer = expansionContainer.firstElementChild;
	
	const items = this.items = scrollContainer.querySelectorAll ("a");
	const activeItem = this.activeItem = scrollContainer.querySelector (".active");
	
	for (let i = 6; i--;) {
		for (let i = 0; i < items.length; i++) {
			const item = items [i];
			scrollContainer.appendChild (item.cloneNode (true));
			
		}
		
	}
	
	const allItems = this.allItems = scrollContainer.querySelectorAll ("a");
	for (let i = 0; i < allItems.length; i++) {
		const item = allItems [i];
		item.addEventListener ("click", function (event) {
			if (this.scrollingController.didDrag)
				event.preventDefault ();
			
		}.bind (this));
		
	}
	
	const headerElement = this.headerElement = document.querySelector ("header");
	const headerCategoryElement = this.headerCategoryElement = headerElement.querySelector (".header-category");
	
	if (headerCategoryElement) {
		if (HeaderNavigation.USE_TOUCH_CONTROL) {
			headerCategoryElement.addEventListener ("click", function (event) {
				event.preventDefault ();
				this.awake ();
				
			}.bind (this));
			
		} else {
			headerCategoryElement.addEventListener ("mouseover", function (event) {
				this.isMouseOverCategory = true;
				this.awake ();
				
			}.bind (this));
			headerCategoryElement.addEventListener ("mouseout", function (event) {
				this.isMouseOverCategory = false;
				
			}.bind (this));
			
			expansionContainer.addEventListener ("mouseover", function (event) {
				this.isMouseOverNavigation = true;
				this.awake ();
				
			}.bind (this));
			expansionContainer.addEventListener ("mouseout", function (event) {
				this.isMouseOverNavigation = false;
				
			}.bind (this));
			
		}
		
	}
	
	this.setUpScrolling ();
	
	if (Environment.FAST_PASS) {
		/*
		window.setTimeout (function () {
			this.awake ();
			
		}.bind (this), 350);
		
		window.setTimeout (function () {
			this.sleep ();
			
		}.bind (this), 1350);
		*/
		
	}
	
};

HeaderNavigation.prototype.awake = function () {
	if (new Date ().getTime () < this.awakeDebounce)
		return;
	
	if (this.isAwake)
		return;
	this.isAwake = true;
	
	// this.addRunLoopHandler ("processAutoScroll");
	
	this.headerElement.classList.add ("navigation-open");
	
	const element = this.element;
	
	const scrollContainer = this.scrollContainer;
	const scrollContainerBounds = scrollContainer.getBoundingClientRect ();
	
	const activeItem = this.activeItem;
	const itemBounds = activeItem.getBoundingClientRect ();
	const selfBounds = element.getBoundingClientRect ();
	
	let targetViewOffset = itemBounds.left - scrollContainerBounds.left + itemBounds.width - selfBounds.width - 28;
	const maxScrollOffset = this.maxScrollOffset;
	
	this.currentViewOffset = this.startViewOffset = targetViewOffset;
	this.startWidth = itemBounds.width;
	
	let viewOffset = targetViewOffset;
	let nextElementSibling = activeItem;
	
	const items = this.items;
	let activeItemIndex;
	
	for (let i = items.length; i--;) {
		if (items [i] == activeItem) {
			activeItemIndex = i;
			break;
			
		}
			
	}
	
	this.activeItemIndex = activeItemIndex;
	
	const isCompact = this.isCompact = this.element.offsetWidth < this.maxScrollOffset - 25;
	const numItemsToSkip = isCompact ?
		1 : (items.length * 2 - activeItemIndex - 1) % items.length;
	
	for (let i = numItemsToSkip; i--;) {
		nextElementSibling = nextElementSibling.nextElementSibling;
		viewOffset = viewOffset + nextElementSibling.offsetWidth;
		
	}
	this.viewOffset = viewOffset;
	
	this.maxScrollSpeed = 0;
	this.accelerationDescription = {
		maxSpeed: 15,
		acceleration: 2.5
		
	};
	this.slidingInertia = .85;
	this.addRunLoopHandler ("processSliding");
	
	element.style.visibility = "";
	
	this.updateViewOffset ();
	
	this.linkToActiveItem = true;
	
	this.startWidth = itemBounds.width;
	this.startAnimation ("Expansion", {direction: 1, rate: .035});
	
	if (HeaderNavigation.USE_TOUCH_CONTROL) {
		if (!this.mouseDownOnStage)
			this.mouseDownOnStage = HeaderNavigation.mouseDownOnStage.bind (this);
		
		window.addEventListener ("mousedown", this.mouseDownOnStage);
		
	}
	
};

HeaderNavigation.mouseDownOnStage = function (event) {
	const element = this.element;
	let target = event.target;
	
	while (target) {
		target = target.parentNode;
		if (target == element)
			return;
		
	}
	
	window.removeEventListener ("mousedown", this.mouseDownOnStage);
	
	this.sleep ();
	
	
};

HeaderNavigation.prototype.sleep = function () {
	if (!this.isAwake)
		return;
	
	this.isAwake = false;
	
	this.removeRunLoopHandler ("processAutoScroll");
	
	this.headerElement.classList.remove ("navigation-open");
	
	const element = this.element;
	
	const scrollContainer = this.scrollContainer;
	const scrollContainerBounds = scrollContainer.getBoundingClientRect ();
	
	const activeItem = this.activeItem;
	const itemBounds = activeItem.getBoundingClientRect ();
	const selfBounds = element.getBoundingClientRect ();
	
	let targetViewOffset = itemBounds.left - scrollContainerBounds.left + itemBounds.width - selfBounds.width - 28;
	
	const maxScrollOffset = this.maxScrollOffset;
	let currentViewOffset = (this.currentViewOffset + selfBounds.width) % maxScrollOffset;
	
	if (currentViewOffset < 0)
		currentViewOffset -= maxScrollOffset;
	
	currentViewOffset -= selfBounds.width;
	this.currentViewOffset = currentViewOffset;
	
	const isCompact = this.isCompact;
	
	do {
		const delta = targetViewOffset - currentViewOffset;
		
		if (isCompact) {
			if (Math.abs (delta) > maxScrollOffset * .5) {
				targetViewOffset += (delta > 0 ? -1 : 1) * maxScrollOffset;
				
			} else {
				break;
				
			}
			
		} else {
			if (delta > 0) {
				targetViewOffset -= maxScrollOffset;
				
			} else {
				break;
				
			}
			
		}
		
	} while (true);
	
	
	this.viewOffset = targetViewOffset;
	
	this.slidingInertia = .625;
	this.accelerationDescription = undefined;
	this.addRunLoopHandler ("processSliding");
	
	this.startAnimation ("Expansion", {direction: 0, rate: .075});
	
	this.awakeDebounce = new Date ().getTime () + 250;
	
};

HeaderNavigation.prototype.animateExpansion = function () {
	const state = this.updatedState ("Expansion");
	this.updateExpansionLayout ();
	
};

HeaderNavigation.prototype.updateExpansionLayout = function () {
	const state = this.states ["Expansion"];
	let t = state.phase;
	t = .5 - Math.cos (Math.PI * t) * .5;
	
	let t_ = 1 - t;
	
	const startWidth = this.startWidth;
	const selfWidth = this.element.offsetWidth;
	
	let targetWidth = Math.min (selfWidth, this.maxScrollOffset - 25);
	let width = Math.round (startWidth * t_ + targetWidth * t);
	
	if (!this.isCompact && this.linkToActiveItem) {
		// if (this.currentViewOffset == this.targetViewOffset)
		//	this.updateViewOffset ();
		
		/*
		const activeItem = this.allItems [0 + this.items.length * 2];
		
		const activeItemBounds = activeItem.getBoundingClientRect ();
		const elementBounds = this.element.getBoundingClientRect ();
		*/
		
		/*
		width = this.activeItemIndex == 0 ?
			elementBounds.right - activeItemBounds.left :
			(elementBounds.right - activeItemBounds.left) * t + width * t_;
		*/
		
		const lockedWidth = this.currentViewOffset - this.startViewOffset + this.startWidth - 25;
		width = this.activeItemIndex == 0 ?
			lockedWidth :
			lockedWidth * t_ + width * t;

	}
	
	const left = selfWidth - width;
	
	const expansionContainer = this.expansionContainer;
	const scrollContainer = this.scrollContainer;
	
	expansionContainer.style.width = width + "px";
	expansionContainer.style.left = left + "px";
	scrollContainer.style.left = -left + "px";
	
	this.element.style.visibility = t == 0 ? "hidden" : "";
	
};

HeaderNavigation.prototype.scrollSpeed = 0;

HeaderNavigation.prototype.processAutoScroll = function () {
	return;
	
	let scrollSpeed = this.scrollSpeed;
	
	if (this.viewOffset != this.currentViewOffset)
		return;
	
	if (!this.isDragging) {
		scrollSpeed = this.scrollSpeed = Math.min (.25, scrollSpeed + .0025);
		
	}
	
	const viewOffset = this.currentViewOffset + (scrollSpeed) * this.context.animationTimer.framesDelta;
	
	this.viewOffset = this.currentViewOffset = viewOffset;
	this.updateViewOffset ();
	
};

HeaderNavigation.prototype.getListenerTargetForScrollingController = function () {
	return this.element;
	
};

HeaderNavigation.prototype.setUpScrolling = function () { 
	SlidingSpritePrototype.call (this, this.context);
	
	const element = this.element;
	const scrollingController = this.scrollingController;
	
	scrollingController.getMouseListenerTarget = function () {
		return element;
		
	};
	
	scrollingController.defaultTouchDirectionLock = ScrollingController.TOUCH_MOVE_DIRECTION_HORIZONTAL

	scrollingController.awake ();
	scrollingController.addWheelListener ();
	scrollingController.cancelHorizontalScroll = true;
	
	scrollingController.addListener ("beginDrag", this.beginDrag, this);
	scrollingController.addListener ("dragHorizontal", this.dragHorizontal, this);
	scrollingController.addListener ("cancelDragHorizontal", this.endDragHorizontal, this);
	scrollingController.addListener ("endDrag", this.endDragHorizontal, this);
	
	scrollingController.addListener ("scrollHorizontal", this.scrollHorizontal, this);
	scrollingController.addListener ("cancelScrollHorizontal", this.cancelScrollHorizontal, this);
	
	if (!HeaderNavigation.USE_TOUCH_CONTROL) {
		this.addListener ("mouseover", this.mouseOver, this);
		this.addListener ("mouseout", this.mouseOut, this);
		
	}
	
};

HeaderNavigation.prototype.mouseOver = function (event) {
	this.mouseAcc = 0;
	
	this.isMouseOver = true;
	this.getStage ().addListener ("mousemove", this.mouseMove, this);
	
	this.currentMouse = this.touchDescriptionForObject (event);
	
};

HeaderNavigation.prototype.mouseOut = function (event) {
	this.mouseSpeed = 0;
	
	this.setHoveredItem ();
	
	this.isMouseOver = false;
	this.getStage ().removeListener ("mousemove", this.mouseMove, this);
	
	if (!(this.isMouseOverCategory || this.isMouseOverNavigation))
		this.sleep ();
	
};

HeaderNavigation.prototype.mouseSpeed = 0;
HeaderNavigation.prototype.mouseAcc = 0;

HeaderNavigation.prototype.mouseMove = function (stage) {
	const viewSize = this.viewSize;
	
	const mouse = this.currentMouse = this.touchDescriptionForObject (stage.currentEvent);
	
	/*
	const xOff = mouse [0];
	
	const reactionArea = Math.min (viewSize [0] < 640 ? 20 : 40, viewSize [0] * .1);
	
	const mouseSpeed = this.mouseSpeed = xOff < viewSize [0] * .5 ?
		-Math.max (
			0,
			7.5 - xOff / reactionArea
			
		) : Math.max (
			0,
			7.5 - (viewSize [0] - xOff) / reactionArea
			
		);
	
		this.addRunLoopHandler ("processMouseSpeed");
		
	*/
	
	this.updateViewOffset ();
	
};

HeaderNavigation.prototype.processMouseSpeed = function () {
	if (this.isDragging)
		return;
	
	const mouseSpeed = this.mouseSpeed;
	let mouseAcc = this.mouseAcc;
	
	if (mouseSpeed) {
		mouseAcc = this.mouseAcc = (mouseAcc * 19 + mouseSpeed) / 20 + this.scrollSpeed;
		this.viewOffset = this.viewOffset + mouseAcc * 3;
		
		this.scrollSpeed = 0;
		
		this.slidingInertia = .9;
		this.addRunLoopHandler ("processSliding");
		
	} else {
		this.mouseAcc = 0;
		this.removeRunLoopHandler ("processMouseSpeed");
		
	}
	
};

HeaderNavigation.prototype.beginDrag = function (scrollingController) {
	SlidingSpritePrototype.beginDrag.apply (this, arguments);
	
	this.scrollSpeed = 0;
	this.mouseAcc = 0;
	this.isDragging = true;
	
	this.linkToActiveItem = false;
	
};

HeaderNavigation.prototype.boundedViewOffset = function (viewOffset) {
	return viewOffset;
	
};

HeaderNavigation.prototype.dragHorizontal = function (scrollingController) {
	var deltaX = scrollingController.delta.x;
	this.removeRunLoopHandler ("processSliding");
	
	this.viewOffset = this.currentViewOffset = this.startViewOffset + deltaX;
	this.updateViewOffset ();
	
};

HeaderNavigation.prototype.endDragHorizontal = function (scrollingController) {
	this.isDragging = false;
	
	const didFlick = scrollingController.didFlickHorizontal;
	if (didFlick) {
		const targetViewOffset = this.viewOffset = this.viewOffset + scrollingController.flickDeltaX * 1.4;
		
		this.slidingInertia = .9;
		this.addRunLoopHandler ("processSliding");
		
	} else {
		this.snapToBounds ();
		
	}
	
};

HeaderNavigation.prototype.scrollHorizontal = function (scrollingController) {
	this.scrollSpeed = 0;
	this.linkToActiveItem = false;
	
	if (scrollingController.isDragging)
		return;
	
	if (this.isWheelLocked)
		return;
	
	if (new Date ().getTime () < this.scrollTimeOut)
		return;
	
	var delta = scrollingController.delta.x;
	const targetViewOffset = this.viewOffset + delta * 1.5;
	
	if (!this.isBumping) {
		if (!(this.bumpLockDirection * scrollingController.delta.x > 0)) {
			this.bumpLockDirection = 0;
			
			this.viewOffset = targetViewOffset;
			this.slidingInertia = .7;
			
			this.addRunLoopHandler ("processSliding");
			
			this.updateViewOffset ();
			
		}
		
	}
	
};

HeaderNavigation.prototype.cancelScrollHorizontal = function (scrollingController) {
	
};

HeaderNavigation.prototype.updateLayout = function () {
	const element = this.element;
	const viewSize = [
		element.offsetWidth,
		element.offsetHeight
		
	];
	
	this.setViewSize (viewSize);
	
};

HeaderNavigation.prototype.updateViewOffset = function () {
	/*
	if (!this.isAwake) {
		this.needsUpdateViewOffset = true;
		return;
		
	}
	*/
	
	const maxScrollOffset = this.maxScrollOffset;
	if (isNaN (maxScrollOffset))
		return;
	
	this.needsUpdateViewOffset = false;
	
	let viewOffset = this.currentViewOffset % maxScrollOffset;
	if (viewOffset < 0)
		viewOffset += maxScrollOffset;
	
	const scrollContainer = this.scrollContainer;
	scrollContainer.style.transform = "translateX(" + -(viewOffset) + "px)";
	
	const selfBounds = this.element.getBoundingClientRect ();
	
	const padding = 25;
	
	if (this.isMouseOver) {
		const mouse = this.currentMouse;
		const children = scrollContainer.children;
		
		for (var i = children.length; i--;) {
			const child = children [i];
			const childBounds = child.getBoundingClientRect ();
			
			if (childBounds.right - selfBounds.left <= 0) {
				i = -1;
				break;
				
			}
			
			if (mouse [0] >= childBounds.left - selfBounds.left && mouse [0] < childBounds.right - selfBounds.left - padding) {
				this.setHoveredItem (child);
				break;
				
			}
			
		}
		
		if (i < 0)
			this.setHoveredItem ();
		
	}
	
	if (this.linkToActiveItem) {
		if (Math.abs (this.viewOffset - this.currentViewOffset) < 2) {
			this.linkToActiveItem = false;
			
		} else {
			this.updateExpansionLayout ();
			
		}
		
	}
	
};

HeaderNavigation.prototype.setHoveredItem = function (hoveredItem) {
	const lastHoveredItem = this.hoveredItem;
	if (lastHoveredItem == hoveredItem)
		return;
	
	if (lastHoveredItem)
		lastHoveredItem.classList.remove ("hovered");
	
	this.hoveredItem = hoveredItem;
	
	if (hoveredItem)
		hoveredItem.classList.add ("hovered");
	
};

HeaderNavigation.prototype.setViewSize = function (viewSize) {
	this.viewSize = viewSize;
	
	const element = this.element;
	
	const selfBounds = element.getBoundingClientRect ();
	const containerBounds = element.firstElementChild.getBoundingClientRect ();
	
	const scrollContainer = this.scrollContainer;
	this.maxScrollOffset = scrollContainer.offsetWidth / 7;
	
};
